import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI, useStore, useUser } from "../../../../Utils/utils";
import { useParams } from "react-router-dom";
import { order_page_message } from "../../../../Constants/Messages"

const PaymentHistory = () => {

    var { id } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const { store, setStore } = useStore()
    const [loading, setLoading] = useState(false)
    const [paymentHistory, setPaymentHistory] = useState()

    useEffect(() => {

        if (id) {
            var pay_load = { "order_id": id }
            // console.log("pay_load", pay_load)
            if (pay_load) {
                callAPI("/admin/payment/history",
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'token': user?.auth_token
                        }, body: JSON.stringify(pay_load)
                    }, navigate, setLoading, setStore)
                    .then(data => {
                        // console.log("api response " + JSON.stringify(data))
                        if (data.success == true) {
                            setPaymentHistory(data?.record)
                        } else {
                            // console.log("data?.errors", data?.errors)
                        }
                    })

                    var SavedMessage = localStorage.getItem('Message')
                    if (SavedMessage == null || SavedMessage == "null" || SavedMessage == "Recipients" && SavedMessage != "Order created successfully") {
                        localStorage.setItem('Message', "order")
                    }
            }
        }
    }, [id])

    return (
        <>
            {paymentHistory?.length != 0 ?
                <>
                    {paymentHistory?.map((item, index) => {
                        const logs = item.logs;
                        return (
                            <div key={index}>
                                {logs?.map((log, logIndex) => {
                                    const lineNumber = logIndex + 1;
                                    const date = new Date(log.time);
                                    const formattedDate = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
                                    const formattedTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
                                    const event = log.payment_status;

                                    return (
                                        <div className="col-lg-12 mb-3" key={logIndex}>
                                            <div className="card card-default">
                                                <div className="card-body" >
                                                    {lineNumber} : Payment {event} on {formattedDate} {formattedTime}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </>
                :
                <div className="col-lg-12 mb-3">
                    <div className="card card-default">
                        <div className="card-body">
                        {order_page_message?.payment_not_found}
                        </div>
                    </div>
                </div >
            }
        </>
    )
}

export default PaymentHistory