import React from "react";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import AddProduct from '../../Components/Product/AddProduct/AddProduct';
import { useStore } from "../../Utils/utils";
import { select_store_message } from "../../Constants/Messages"

const AddProductPage = () => {
    const { store, setStore } = useStore()

    var storeId = store?.id

    let select_store = storeId == null || storeId == undefined || storeId == 0 || store?.name == "Admin" ? true : false

    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <div className="d-flex flex-column">
                    <BreadCrumb />
                </div>
            </div>
            {/* create product useing add product */}
            {select_store ?
                <div className="card">
                    <div className="card-body select_store">
                        <p className="text-center">{select_store_message?.select_store}</p>
                    </div>
                </div>
                :
                <AddProduct />
            }
        </Layout>
    )
}
export default AddProductPage;