import React from "react";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import CustomerCreateFrom from "../../Components/Customer/AddCustomer/CustomerCreateFrom";
import { useStore } from "../../Utils/utils";
import { select_store_message } from "../../Constants/Messages";

const AddCustomerPage = () => {
    const { store, setStore } = useStore()

    let select_store = store?.id == null || store?.id == undefined || store?.id == 0 || store?.name == "Global Admin" ? true : false

    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts ">
                <div className="d-flex flex-column">
                    <BreadCrumb />
                </div>
            </div>

            {select_store ?
                <div className="card">
                    <div className="card-body  select_store">
                        <p className="text-center">{select_store_message?.select_store}</p>
                    </div>
                </div>
                :
                // Create new Customer useing CustomerCreateFrom
                <CustomerCreateFrom />
            }
        </Layout>
    )
}
export default AddCustomerPage;