import React, { useState, useEffect } from "react";
import { callAPI, useUser, usePage, useStore } from "../../../Utils/utils";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../Components/Global/Pagination/Pagination";
import Loader from "../../../Components/Global/Loader/Loader";
import { order_page_labels } from "../../../Constants/Labels";
import { order_page_message } from "../../../Constants/Messages"

export default function Orders() {
    var { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const { user, setUser } = useUser();
    const { page, setPage } = usePage()
    const [mode, setMode] = useState('all')
    const [orders, setOrders] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState()
    const [resultInfo, setResultInfo] = useState({
        total_rows: "",
        fetched_rows: "",
        total_pages: "",
        page_size: 0,
        current_page: "",
        success: true,
        errors: ""
    })

    const viewOrder = (orderid) => {
        navigate(`/admin/customer/edit/${id}/order/edit/${orderid}`)
    }

    const onPageSelect = (currentPage) => {
        setCurrentPage(currentPage)
    }

    //set current tab
    const onClickCurrentTab = (selectedTab) => {
        setMode(selectedTab)
        setSearchValue('')
    }

    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    //load order list
    useEffect(() => {
        var pay_load = {
            "customer_id": id,
            "status": order_page_labels?.orders?.status[mode],
            "page_size": 10,
            "current_page": currentPage
        }
        getOrdersList(pay_load)
    }, [mode, currentPage])

    //get orders list during on change the input box
    const onChangeSearchValue = (event) => {
        setSearchValue(event.target.value)

        var pay_load = {
            "customer_id": id,
            "status": order_page_labels?.orders?.status[mode],
            "page_size": 10,
            "current_page": 1
        }

        if (event.target.value)
            pay_load.filters = {
                "conditions": [
                    { "field": "o.order_id", "operator": "equals", "value": event.target.value, "dataType": "integer" }
                ]
            }

        getOrdersList(pay_load)
    }

    const getOrdersList = (pay_load) => {
        // console.log("pay_load",pay_load)
        callAPI('/admin/orders/by-customer',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(
                    pay_load
                )
            }, navigate, setLoading)
            .then(data => {
                if (data.success) {
                    // console.log("search result in order list - ", data.records)
                    setOrders(data.records)
                    setResultInfo({
                        total_rows: data.total_rows,
                        fetched_rows: data.fetched_rows,
                        total_pages: data.total_pages,
                        page_size: data.page_size,
                        current_page: data.current_page,
                        success: data.success,
                        errors: data.errors
                    })
                }
                else {
                    // console.log("getting error from search result")
                }
            })
            .catch((error) => {
                // console.log("unable to fetch data - ", error)
            })
    }

    return (
        <React.Fragment>

            <div className="card card-default mt-2">
                <div className="card-body">
                    <div className="d-flex justify-content-end mb-2">
                        <button className="btn btn-primary" onClick={() => navigate(`/admin/order/new`)}> Add Order</button>
                    </div>
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <div className="filterTab mb-2">
                                <div className="navAndSearchWrapper">

                                    <ul className="nav nav-tabs nav-style-border w-75">
                                        <li className="nav-item mr-3" onClick={() => onClickCurrentTab("all")}>
                                            <span className={`nav-link ${mode == 'all' ? 'active' : ''}`}>{order_page_labels?.orders?.tabs_labels?.["all"]}</span>
                                        </li>
                                        <li className="nav-item mr-3" onClick={() => onClickCurrentTab("pending")}>
                                            <span className={`nav-link ${mode == 'pending' ? 'active' : ''}`}>{order_page_labels?.orders?.tabs_labels?.["pending"]}</span>
                                        </li>
                                        <li className="nav-item mr-3" onClick={() => onClickCurrentTab("confirmed")}>
                                            <span className={`nav-link ${mode == 'confirmed' ? 'active' : ''}`}>{order_page_labels?.orders?.tabs_labels?.["confirmed"]}</span>
                                        </li>
                                        <li className="nav-item mr-3" onClick={() => onClickCurrentTab("in-process")}>
                                            <span className={`nav-link ${mode == 'in-process' ? 'active' : ''}`}>{order_page_labels?.orders?.tabs_labels?.["in-process"]}</span>
                                        </li>
                                        <li className="nav-item mr-3" onClick={() => onClickCurrentTab("partially_shipped")}>
                                            <span className={`nav-link ${mode == 'partially_shipped' ? 'active' : ''}`} onClick={() => onClickCurrentTab("partially_shipped")}>{order_page_labels?.orders?.tabs_labels?.["partially_shipped"]}</span>
                                        </li>
                                        <li className="nav-item mr-3" onClick={() => onClickCurrentTab("shipped")}>
                                            <span className={`nav-link ${mode == "shipped" ? 'active' : ''}`}>{order_page_labels?.orders?.tabs_labels?.["shipped"]}</span>
                                        </li>
                                    </ul>

                                    <div className="search-form ">
                                        <div className="input-group">
                                            <input type="text" className="form-control"
                                                placeholder="Search by Order Id" value={searchValue} onChange={onChangeSearchValue} />
                                            <span className="search_icon">
                                                <i className="mdi mdi-magnify"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loading &&
                                <Loader className={"table_loader"} />
                            }

                            {/* List Orders Based on Store */}
                            <div className={`${loading ? 'loderHide' : 'loderShow'}`}>
                                {/*orders-{JSON.stringify(orders)} */}
                                {orders == '' || orders == null || orders == undefined || orders.length == 0 ?

                                    <div className="card">
                                        <div className="card-body defaultText">
                                            <p className="text-center">{order_page_message[mode]}</p>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {mode &&
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.order_id}</th>
                                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.order_date}</th>
                                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.customer}</th>
                                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.items}</th>
                                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.amount}</th>
                                                        <th className="col-lg-3">{order_page_labels?.orders?.table_head?.status}</th>
                                                        <th className="col-lg-1">{order_page_labels?.orders?.table_head?.action}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders?.map((order, index) => {
                                                        var orderDate = order?.order_date?.substring(0, 10)
                                                        return (
                                                            < tr key={index} >
                                                                <td className="hand" onClick={() => viewOrder(order.order_id)}>{order.order_id}</td>
                                                                <td>{orderDate}</td>
                                                                <td>{order?.first_name == "NULL" || order?.first_name == null || order?.first_name == "null" || order?.first_name == 0 || order?.first_name == "0" || !order?.first_name ? "" : order?.first_name} {order?.last_name == "NULL" || order?.last_name == null || order?.last_name == "null" || order?.last_name == 0 || order?.last_name == "0" || !order?.last_name ? "" : order?.last_name}</td>
                                                                <td>{order.items}</td>
                                                                <td>{order.total}</td>
                                                                <td>{order_page_labels?.orders?.orders_status[order?.status]}</td>
                                                                <td >
                                                                    {/*here get orderId on onclick method  */}
                                                                    <span className="mdi mdi-account-edit icon" onClick={() => viewOrder(order.order_id)} ></span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                }
                            </div>
                            <Pagination totalRows={resultInfo.total_rows} fetchedRows={resultInfo.fetched_rows} totalPages={resultInfo.total_pages} pageSize={resultInfo.page_size} currentPage={resultInfo.current_page} onPageSelect={onPageSelect} label='orders' loader={loading} />
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}