import React, { useEffect } from "react";
import Layout from "../../Components/Global/Layout";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentFailed = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Get the current URL
        var searchParams = new URLSearchParams(location.search);
        // Get the value of the 'orderNumber' parameter
        var orderNumber = searchParams.get('orderNumber');
        var order_id = localStorage.getItem('order_id')
        if (orderNumber) {
            navigate(`/admin/order/edit/${orderNumber}`);
            localStorage.setItem('Message', "Payment Failed!");
        }
        else if (order_id) {
            navigate(`/admin/order/edit/${order_id}`);
            localStorage.setItem('Message', "Payment Failed!");
        }
    }, [location?.search, navigate]);


    return (
        <Layout>
        </Layout>
    );
};

export default PaymentFailed;
