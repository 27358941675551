import React, { useState, useEffect } from "react";
import { callAPI, useStore, useUser } from "../../../Utils/utils";
import { useNavigate, useParams, } from "react-router-dom";
import { sales_and_fees_page_message } from "../../../Constants/Messages"
import { sales_and_fees_page_labels } from "../../../Constants/Labels";
import BreadCrumb from '../../../Components/Global/BreadCrumb/BreadCrumb';
import Layout from "../../Global/Layout";
import Loader from "../../Global/Loader/Loader";
import "./SalesandFeesByOrders.css"

const SalesandFeesByOrders = () => {

    var { id, date } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const { store, setStore } = useStore();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState();
    const [storeName, setStoreName] = useState([]);

    useEffect(() => {
        if (id && date) {
            var pay_load = {
                "store_id": id,
                "time_window": {
                    "window_type": "custom",
                    "from_date": date,
                    "to_date": date
                }
            }
            callAPI("/admin/fees/by-orders",
                {
                    method: 'Post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    }, body: JSON.stringify(pay_load)

                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data.success == true) {
                        setOrders(data?.records)
                    } else {
                        // console.log('data.error', data.error)
                    }
                }
                )
        }
    }, [])

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])


    return (
        <Layout>
            {loading &&
                <Loader className={"page_loader"} />
            }
            {!loading &&
                <>
                    <div className="breadcrumb-wrapper breadcrumb-contacts">
                        <div className="d-flex flex-column">
                            <BreadCrumb />
                        </div>
                    </div>

                    <div className="card card-default mt-5">
                        <div className="card-body " >
                            <div className="table-responsive" >
                                {orders?.length == 0 ?
                                    <p className="transction_not_found">
                                        {sales_and_fees_page_message?.no_transaction}
                                    </p> :
                                    <table className="table" >
                                        <thead>
                                            <tr>
                                                <th className="col-lg-2">{sales_and_fees_page_labels?.order_by_date_table_labels?.order_id}</th>
                                                <th className="col-lg-2">{sales_and_fees_page_labels?.order_by_date_table_labels?.product}</th>
                                                <th className="col-lg-2">{sales_and_fees_page_labels?.order_by_date_table_labels?.price}</th>
                                                <th className="col-lg-2">{sales_and_fees_page_labels?.order_by_date_table_labels?.quantity}</th>
                                                <th className="col-lg-2">{sales_and_fees_page_labels?.order_by_date_table_labels?.total_price}</th>
                                                <th className="col-lg-2">{sales_and_fees_page_labels?.order_by_date_table_labels?.fees}</th>
                                                <th className="col-lg-2">{sales_and_fees_page_labels?.order_by_date_table_labels?.net}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders?.map((lineItem, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td>{lineItem?.order_id}</td>
                                                        <td>{lineItem?.name}</td>
                                                        <td>{lineItem?.price_per_item}</td>
                                                        <td>{lineItem?.quantity}</td>
                                                        <td>{lineItem?.line_item_total_price}</td>
                                                        <td>{lineItem?.fees_amount}</td>
                                                        <td>{lineItem?.net}</td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </Layout>
    )
}
export default SalesandFeesByOrders;