import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { callAPI, useStore, useUser } from "../../../Utils/utils";
import { errorMessages } from "../../../Constants/Errors";
import { customer_page_labels } from "../../../Constants/Labels";
import { scrollToTop } from "../../Global/Scroll"
import ErrorMessage from "../../Global/ErrorMessage"
import AddressTable from "../AddressTable/AddressTable";
import Alert from "../../Alert/Alert";
import FileUpload from "../../FileUpload/FileUpload";
import Orders from './Orders';
import Message from "../../Message/Message";

export default function EditCustomer({ onUpdateCustomer }) {

	var { id } = useParams();
	const navigate = useNavigate();
	const { user, setUser } = useUser();
	const { store, setStore } = useStore();
	const [errors, setErrors] = useState();
	const [orders, setOrders] = useState(false);
	const [loading, setLoading] = useState(false);
	const [customer, setCustomer] = useState(true);
	const [storeName, setStoreName] = useState([]);
	const [recipient, setRecipient] = useState(false);
	const [countryList, SetCountryList] = useState();
	const [showUploadPage, setUploadPage] = useState(false)
	const [formValues, setformValues] = useState({});
	const [alert, setAlert] = useState()
	const [modifiedFormValues, setModifiedFormValues] = useState();
	const [showErrorMessage, setShowErrorMessage] = useState(false);

	var storeId = store?.id; // Assign the value of "store.id" to the variable "storeId"

	useEffect(() => {
		if (storeName?.length == 0 || storeName == undefined) {
			setStoreName(() => [store?.name]); // Set the initial value of "storeName" with the value of "store.name"
		} else {
			setStoreName((existingStoreId) => [...existingStoreId, store?.name]); // Append "store.name" to the existing values of "storeName"
		}
	}, [store?.id]);

	useEffect(() => {
		if (storeName[1] != undefined) {
			if (storeName[0] != storeName[1]) {
				navigate("/admin"); // Navigate to "/admin" page
				setStoreName([]); // Reset the value of "storeName"
			}
		}
	}, [storeName]);

	const onChangeField = (event) => {
		setformValues({ ...formValues, [event.target.name]: event.target.value }); // Update the value of "formValues" with the new field value
		setModifiedFormValues({ ...modifiedFormValues, [event.target.name]: event.target.value }); // Update the value of "modifiedFormValues" with the new field value
		setShowErrorMessage(false)
		setErrors([])
	};

	const saveCustomer = () => {

		// Scroll to the top of the page
		scrollToTop()

		// Prepare the payload for updating the customer
		var pay_load = {
			"customer_id": id,
			"first_name": modifiedFormValues?.first_name,
			"last_name": modifiedFormValues?.last_name,
			"email": modifiedFormValues?.email?.toLowerCase(),
			"phone": modifiedFormValues?.phone,
			"status": modifiedFormValues?.status || 'active',
			"store_id": storeId
		};

		// Check if any address values are present in formValues
		// Check if any address values are present in formValues
		if (modifiedFormValues?.line_1 || modifiedFormValues?.line_1 == "" ||
			modifiedFormValues?.line_2 || modifiedFormValues?.line_2 == "" ||
			modifiedFormValues?.city || modifiedFormValues?.city == "" ||
			modifiedFormValues?.state || modifiedFormValues?.state == "" ||
			modifiedFormValues?.zip || modifiedFormValues?.zip == "" ||
			modifiedFormValues?.country || modifiedFormValues?.country == ""
		) {
			pay_load.address = {
				"address_id": formValues?.address_id,
				"line_1": modifiedFormValues?.line_1,
				"line_2": modifiedFormValues?.line_2,
				"city": modifiedFormValues?.city,
				"state": modifiedFormValues?.state,
				"zip": modifiedFormValues?.zip,
				"country": modifiedFormValues?.country || "USA",
			};
		}

		// console.log("pay_load", pay_load)
		// Call the API to update the customer
		callAPI("/admin/customers/update", {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'token': user?.auth_token
			},
			body: JSON.stringify(pay_load) // Convert payload to JSON string
		}, navigate, setLoading)
			.then(data => {
				if (data?.success === true) {
					onUpdateCustomer("success"); // Call the onUpdateCustomer function
				} else {
					setErrors(data?.errors); // Set the errors received from the API response
					setShowErrorMessage(true); // Show error message
					onUpdateCustomer("error");
					// console.log('res', data?.errors);
				}
			});
	}


	// Function to toggle the customer tab active/inactive
	const clickCustomer = () => {
		if (!customer) { // If customer tab is currently inactive
			setCustomer(true); // Set customer tab as active
			setRecipient(false); // Set recipient tab as inactive
			setOrders(false); // Set orders tab as inactive
		}
	};

	// Function to toggle the recipients tab active/inactive
	const clickRecipients = () => {
		if (!recipient) { // If recipients tab is currently inactive
			setRecipient(true); // Set recipients tab as active
			setCustomer(false); // Set customer tab as inactive
			setOrders(false); // Set orders tab as inactive
		}
	};

	// Function to toggle the orders tab active/inactive
	const clickOrders = () => {
		if (!orders) { // If orders tab is currently inactive
			setRecipient(false); // Set recipients tab as inactive
			setCustomer(false); // Set customer tab as inactive
			setOrders(true); // Set orders tab as active
		}
	};

	// page onload get data from database based on customerId
	useEffect(() => {
		if (id) {
			callAPI(`/admin/customers/${id}`,
				{
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'token': user?.auth_token
					},
				}, navigate, setLoading)
				.then(data => {
					// console.log("api response " + JSON.stringify(data))
					if (data.success) {
						setformValues(data.record);
					} else {
						// console.log('Error occurred. Please try again.')
					}
				}
				)
		}
	}, [])

	useEffect(() => {
		callAPI("/admin/address/list",
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'token': user?.auth_token
				},

			}, navigate, setLoading)

			.then(data => {
				// console.log("api response " + JSON.stringify(data))
				if (data.success === true) {
					SetCountryList(data?.records);
				} else {
					// console.log('data.error', data.error)

				}
			}
			)
	}, [id, store?.id])

	var selected_tab = localStorage?.getItem("Message")

	useEffect(() => {
		if (selected_tab == "Recipients") {
			setRecipient(true);
			setCustomer(false);
			setOrders(false);
		}
		else if (selected_tab == "Order" || selected_tab == "order") {
			setRecipient(false);
			setCustomer(false);
			setOrders(true);
		}
		else {
			setRecipient(false);
			setCustomer(true);
			setOrders(false);
		}
	}, [selected_tab])

	const status = Object.keys(customer_page_labels?.customer_add_and_edit_page_status)?.map((name) => {
		const value = customer_page_labels?.customer_add_and_edit_page_status[name];
		return `${name},${value}`;
	});

	const onShowUpload = () => {
		setUploadPage(true)
	}

	const onHideUpload = (alertMessage) => {
		setUploadPage(false)
		setAlert(alertMessage)
	}
	return (
		<React.Fragment>
			<button className="btn btn-primary saveButton" onClick={saveCustomer}>{customer_page_labels?.customer_edit_page_labels?.save_btn_label}</button>

			{alert?.text == "File uploaded successfully" ?
				<div className="text-center">
					<Alert text={alert.text} type={alert.type} setAlert={setAlert} />
				</div>
				: ''}

			<div className="row">
				<div className="col-12">
					<div className="card card-default">
						<div>
							<ul className="nav nav-tabs px-3 px-xl-5 nav-style-border" >
								<li className="nav-item">
									<span className={`nav-link ${customer ? 'active' : ''}`} onClick={clickCustomer}>{customer_page_labels?.customer_edit_page_labels?.general}</span>
								</li>
								<li className="nav-item" >
									<span className={`nav-link ${recipient ? 'active' : ''}`} onClick={clickRecipients}>{customer_page_labels?.customer_edit_page_labels?.recipients}</span>
								</li>
								<li className="nav-item" >
									<span className={`nav-link ${orders ? 'active' : ''}`} onClick={clickOrders}>{customer_page_labels?.customer_edit_page_labels?.orders}</span>
								</li>
							</ul>
						</div>
					</div>

					<div style={{ display: customer ? '' : 'none' }}>
						<div className="row mt-2">
							<div className="col-lg-8">
								<div className="card card-default">
									<div className="card-body">
										<div className="row">
											<div className="row">
												<div className="col-lg-6">
													<div className="form-group mb-4">
														<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels?.first_name}</label>
														<input type="text" className="form-control" name='first_name' value={formValues['first_name'] == "NULL" ? "" : formValues['first_name'] || ''} maxLength="30" onChange={onChangeField} />
														<div className="errorMessage">
															<Message text={errorMessages[ErrorMessage({ fieldName: 'first_name', errors })] || ErrorMessage({ fieldName: 'first_name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group mb-4">
														<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels?.last_name}</label>
														<input type="text" className="form-control" name='last_name' value={formValues['last_name'] == "NULL" ? "" : formValues['last_name'] || ''} maxLength="30" onChange={onChangeField} />
														<div className="errorMessage">
															<Message text={errorMessages[ErrorMessage({ fieldName: 'last_name', errors })] || ErrorMessage({ fieldName: 'last_name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group mb-4">
														<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels?.email}</label>
														<input type="email" className="form-control" name='email' value={formValues['email'] == "NULL" ? "" : formValues['email'] || ''} maxLength="50" onChange={onChangeField} />
														<div className="errorMessage">
															<Message text={errorMessages[ErrorMessage({ fieldName: 'email', errors })] || ErrorMessage({ fieldName: 'email', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group mb-4">
														<label className="form-label">{customer_page_labels?.customer_add_and_edit_page_labels?.phone}</label>
														<input type="text" className="form-control" name='phone' value={formValues['phone'] == "NULL" ? "" : formValues['phone'] || ''} maxLength="20" onChange={onChangeField} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card card-default mt-3">
									<div className="card-header card-header-border-bottom">
										<h2>{customer_page_labels?.customer_add_and_edit_page_labels?.address}</h2>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-lg-6">
												<div className="form-group mb-4">
													<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels?.line_1}</label>
													<input type="text" className="form-control" name='line_1' value={formValues['line_1'] == "NULL" ? "" : formValues['line_1'] || ''} maxLength="200" onChange={onChangeField} />
													<div className="errorMessage">
														<Message text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb-4">
													<label className="form-label">{customer_page_labels?.customer_add_and_edit_page_labels?.line_2}</label>
													<input type="text" className="form-control" name='line_2' value={formValues['line_2'] == "NULL" ? "" : formValues['line_2']} maxLength="200" onChange={onChangeField} />
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb-4">
													<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels?.city}</label>
													<input type="text" className="form-control" name='city' value={formValues['city'] == "NULL" ? "" : formValues['city'] || ''} maxLength="30" onChange={onChangeField} />
													<div className="errorMessage">
														<Message text={errorMessages[ErrorMessage({ fieldName: 'city', errors })] || ErrorMessage({ fieldName: 'city', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb-4">
													<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels?.state}</label>
													<input type="text" className="form-control" name='state' value={formValues['state'] == "NULL" ? "" : formValues['state'] || ''} maxLength="30" onChange={onChangeField} />
													<div className="errorMessage">
														<Message text={errorMessages[ErrorMessage({ fieldName: 'state', errors })] || ErrorMessage({ fieldName: 'state', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb-4">
													<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels?.zip}</label>
													<input type="text" className="form-control" name='zip' value={formValues['zip'] == "NULL" ? "" : formValues['zip'] || ''} maxLength="20" onChange={onChangeField} />
													<div className="errorMessage">
														<Message text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb-4">
													<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels?.country}</label>
													<select className="form-control overflow-auto" name='country' value={formValues['country']} onChange={onChangeField}>
														<option hidden>{customer_page_labels?.customer_add_and_edit_page_labels?.select_country}</option>
														{countryList?.map((country, index) => {
															return <option value={country.country_code} key={index}> {country.name}</option>
														})}
													</select>
													<div className="errorMessage">
														<Message text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="card card-default">
									<div className="card-body ">
										<div className="form-group">
											<label className="form-label">{customer_page_labels?.customer_add_and_edit_page_labels?.status}</label>
											<select className="form-control" name="status" onChange={onChangeField} value={formValues['status'] || ''} >
												{status &&
													status?.map((optionString) => {
														const [name, value] = optionString.split(',');
														return <option value={name} key={name}>{value}</option>;
													})
												}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="text-right ">
							<button className="btn btn-primary" onClick={saveCustomer}>{customer_page_labels?.customer_edit_page_labels?.save_btn_label}</button>
						</div>
					</div>

					{recipient &&
						<div>
							{!showUploadPage && <AddressTable onShowUpload={onShowUpload} />}
							{showUploadPage && <FileUpload onHideUpload={onHideUpload} id={id} />}
						</div>
					}

					{orders &&
						<Orders />
					}
				</div>
			</div>
		</React.Fragment>
	)
} 