import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { login_page_labels } from "../../../Constants/Labels"
import { callAPI, useUser, useStore } from "../../../../src/Utils/utils";
import { inactive_login, password_success, password_email } from "../../../Constants/Messages";
import ErrorMessage from "../ErrorMessage";
import SecondaryLoader from "../../Global/SecondaryLoader/SecondaryLoader"
import Message from "../../Message/Message";
import Alert from "../../Alert/Alert";
import './Login.css';

const LoginFrom = () => {

    const navigate = useNavigate()
    const { user, setUser } = useUser()
    const [alert, setAlert] = useState()
    const { store, setStore } = useStore()
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [formValues, setformValues] = useState({})
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onChangeField = (event) => {
        const { name, value } = event.target;
        // Remove spaces from the password value
        const cleanedPassword = value.replace(/\s+/g, '');
        setformValues({ ...formValues, [name]: cleanedPassword });
        setShowErrorMessage(false);
        setAlert("");
    };



    //on enter search the store list
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            login()
        }
    }

    const login = () => {

        var pay_load = {
            "email": formValues?.email?.toLowerCase() || "",
            "password": formValues?.password || ""
        }
        // console.log("pay_load",pay_load)
        callAPI('/admin/users/login',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success === true) {
                    //set user data in context
                    setUser({ id: data?.user_id, first_name: data.first_name, last_name: data.last_name, auth_token: data.token, role: data.role_cd, store_id: data?.store_id })
                    //set user data in local stroage
                    localStorage.setItem('user', JSON.stringify({ id: data?.user_id, first_name: data.first_name, last_name: data.last_name, auth_token: data.token, role: data.role_cd, store_id: data?.store_id }))
                    //setGetUser(data)
                    if (data.role_cd == 'ST-ADM') {
                        setStore({ id: data?.store_id, name: data?.store_name })
                        localStorage.setItem('store', JSON.stringify({ id: data?.store_id, name: data?.store_name }))
                    }
                    navigate('/admin')
                }
                else {
                    setErrors(data?.errors)
                    setShowErrorMessage(true)
                    // console.log('data', data.errors)
                }
            }
            )
    }

    useEffect(() => {
        setAlert({ text: inactive_login[ErrorMessage({ fieldName: "store", errors })], type: "error" })
    }, [errors])

    useEffect(() => {
        const message = localStorage.getItem("Message");

        if (message == password_success) {
            setAlert({ text: password_success, type: "success" })
        }

        if (message == password_email) {
            setAlert({ text: password_email, type: "success" })
        }
    }, [])

    const navigateToForgotPasswordPage = () => {
        navigate("/forgotpassword")
        localStorage.setItem("Message", null)
    }

    const hide_eye_icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
    </svg>

    const eye_icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
    </svg>

    return (
        <>
            {alert?.text ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} style={{ right: '100%', top: "8%", width: "100%" }} />
                </div>
                : ''}
            <div className="showLogin">
                <div className="signinContainer">
                    <div className="form-container">
                        <div className="image-holder"></div>
                        <div className="form card-default">
                            <div className="formWrapper">
                                <div className="ec-brand">
                                    <span title="Gifting Good">
                                        <p className="brandTitle">
                                            <img src="/assets/img/Logo.jpg" alt="Logo" />
                                        </p>
                                    </span>
                                </div>
                                <div className="card-header card-header-border-bottom">
                                    <h2 className="m-auto">{login_page_labels?.title}</h2>
                                </div>
                                <div className="card-body">
                                    <div className=" mt-2 ">
                                        <span className="form-label mandatory_field mb-1">{login_page_labels?.email}</span>
                                        <input type="email" className="form-control col-lg-4 email_input" name='email' onChange={onChangeField} value={formValues['email'] || ''} onKeyDown={handleKeyDown} />
                                        <div className="errorMessage">
                                            <Message text={errorMessages[ErrorMessage({ fieldName: 'email', errors })] || ErrorMessage({ fieldName: 'email', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                        </div>

                                        <span className="form-label mandatory_field mb-1">{login_page_labels?.password}</span>
                                        <div className=" input-container">
                                            <input type={showPassword ? 'text' : 'password'} className="form-control" name='password' onChange={onChangeField} value={formValues['password'] || ''} onKeyDown={handleKeyDown} />
                                            <span className="password-toggle" onClick={togglePasswordVisibility}>
                                                {showPassword ? <span className="eye_icon">{eye_icon}</span> : <span className="hide_eye_icon">{hide_eye_icon}</span>}
                                            </span>
                                        </div>
                                        <div className="errorMessage">
                                            <Message text={errorMessages[ErrorMessage({ fieldName: 'password', errors })] || ErrorMessage({ fieldName: 'password', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                        </div>
                                        <div className="buttonAndText m-2 mt-5">
                                            <button className=" form-group .form-control col-lg-3 btn btn-primary" onClick={() => login()}> {login_page_labels?.login_btn} </button>
                                            {loading && <SecondaryLoader className={"login_loader"} />}
                                            <p className="forgotPassword" onClick={navigateToForgotPasswordPage}>{login_page_labels?.forgot_password}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hideLogin">
                <div className="loginContainer">
                    <div className="card card-default col-lg-4 m-5 shadow">
                        <div className="card card-default shadow  ">
                            <div className="card-body non_desktop_mode">
                                <span> This application is supported only on the desktop version </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginFrom;

