import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../Utils/utils";
import { category_page_labels } from "../../../Constants/Labels";
import { categories_page_message, select_store_message } from "../../../Constants/Messages";
import './CategoryList.css';

export default function CategoryList({ mode, searchResult, loader }) {

    const navigate = useNavigate();
    const { store, setStore } = useStore()
    var storeId = store?.id
    
    // onclick to navigate categoryedit page based on id
    const editProduct = (id) => {
        navigate(`/admin/category/edit/${id}`)
    }
    return (
        <React.Fragment>
            <div className={`${loader ? 'loderHide' : 'loderShow'}`}>
                {storeId === null || storeId === undefined || storeId === 'Admin' ?
                    <p className="text-center">{select_store_message?.select_store}</p>
                    :
                    <div>
                        {searchResult === '' || searchResult === null || searchResult === undefined || searchResult.length === 0 ?
                            <div className="card">
                                <div className="card-body defaultText">
                                    <p className="text-center">{categories_page_message[mode]}</p>
                                </div>
                            </div>
                            :
                            <div className="tableOutline">
                                {mode &&
                                    searchResult !== '' &&
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="col-lg-6">{category_page_labels?.category_page_table_head_labels?.title}</th>
                                                <th className="col-lg-5">{category_page_labels?.category_page_table_head_labels?.status}</th>
                                                <th className="col-lg-1">{category_page_labels?.category_page_table_head_labels?.action}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {searchResult?.map((Category, index) => {
                                                const status = Category.status === 'active' ? Category.status === 'inactive' ? '' : 'Active' : 'Inactive'
                                                return (
                                                    < tr key={index} >
                                                        <td className="nameCol" role='button' onClick={() => editProduct(Category.category_id)}>{Category.name} </td>
                                                        <td>{status}</td>
                                                        <td >
                                                            <span className="mdi mdi-account-edit icon" onClick={() => editProduct(Category.category_id)} ></span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                }
                            </div>}
                    </div>
                }
            </div>
        </React.Fragment >
    )
}