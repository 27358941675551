import React, { useState, useEffect } from "react";
import { callAPI, useToken, useStore, useUser } from "../../Utils/utils";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { errorMessages } from "../../Constants/Errors";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import EditOrder from '../../Components/OrdersForMultipleAddress/EditOrder/EditOrder'
import Alert from "../../Components/Alert/Alert";
import Loader from "../../Components/Global/Loader/Loader";
import Message from "../../Components/Message/Message";

const EditOrdersPage = () => {


    var { id } = useParams();
    const [alert, setAlert] = useState()
    const navigate = useNavigate();
    const location = useLocation()
    const { user, setUser } = useUser();
    const { store, setStore } = useStore()
    const [refreshCount, setRefreshCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState()
    const [formValues, setFormValues] = useState()
    const current_page = location?.pathname?.substring(1).split("/");

    useEffect(() => {
        var SavedMessage = localStorage.getItem('Message')

        if (SavedMessage == "Payment Failed!") {
            setAlert({ text: SavedMessage, type: "error" })
        }

        else if (SavedMessage == "Payment successfull!") {
            setAlert({ text: SavedMessage, type: "success" })
        }

        else if (SavedMessage != null && SavedMessage != "Order" && SavedMessage != "order") {
            setAlert({ text: SavedMessage, type: "success" })
        }
        else if (SavedMessage == null || SavedMessage == "null" || SavedMessage == "Recipients" && SavedMessage != "Order created successfully") {
            localStorage.setItem('Message', "order")
        }
    }, [])

    useEffect(() => {

        if (id) {
            if (current_page[1] == 'customer') {
                var URL = `/admin/customers/${id}`
            }
            else {
                URL = `/admin/orders/${id}`
            }
            callAPI(URL,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    }
                }, navigate, setLoading, setStore)
                .then(data => {
                    if (data.success == true) {
                        setFormValues(data?.record)
                    } else {
                        setErrors(data?.errors)
                        // console.log('errors', data?.errors)
                    }
                })
        }
    }, [refreshCount, id])

    // on update user data increase the refreshCount 
    const onSaveOrder = (Message) => {

        if (Message == "success") {
            setAlert({ text: "Order saved successfully", type: "success" })
            setRefreshCount(refreshCount + 1)
        }
        else if (Message == "error") {
            setAlert({ text: "There are items that require your attention", type: "error" })
        }
        else if(Message == "deleteError"){
            setAlert({ text: "This Order couldn't be deleted", type: "error" })
        }
    }

    var bread_crumb_value

    if (current_page[1] == 'customer') {
        if (formValues?.first_name == "NULL" || formValues?.first_name == null || formValues?.first_name == "null" || formValues?.first_name == 0 || formValues?.first_name == "0" || !formValues?.first_name && formValues?.last_name == "NULL" || formValues?.last_name == null || formValues?.last_name == "null" || formValues?.last_name == 0 || formValues?.last_name == "0" || !formValues?.last_name) {
            bread_crumb_value = ""
        }
        else if (formValues?.first_name != "NULL" || formValues?.first_name != null || formValues?.first_name != "null" || formValues?.first_name != 0 || formValues?.first_name != "0" || formValues?.first_name && formValues?.last_name == "NULL" || formValues?.last_name == null || formValues?.last_name == "null" || formValues?.last_name == 0 || formValues?.last_name == "0" || !formValues?.last_name) {
            bread_crumb_value = formValues?.first_name
        }
        else if (formValues?.first_name == "NULL" || formValues?.first_name == null || formValues?.first_name == "null" || formValues?.first_name == 0 || formValues?.first_name == "0" || !formValues?.first_name && formValues?.last_name != "NULL" || formValues?.last_name != null || formValues?.last_name != "null" || formValues?.last_name != 0 || formValues?.last_name != "0" || formValues?.last_name) {
            bread_crumb_value = formValues?.last_name
        }
        else {
            bread_crumb_value = formValues?.['first_name'] + " " + formValues?.['last_name']
        }
    }
    else {
        bread_crumb_value = formValues?.order_id
    }


    // Function to get error message by field name
    function getErrorMessage(fieldName) {
        const error = errors?.find(item => item.field_name === fieldName);
        return error ? error.message : "Field not found";
    }

    const allowedAlerts = [
        "Order saved successfully",
        "Order created successfully",
        "Payment successfull!",
        "Payment Failed!",
        "There are items that require your attention"
    ];

    return (
        <Layout>

            {allowedAlerts?.includes(alert?.text) && (
                <div className="text-center">
                    <Alert text={alert?.text} type={alert?.type} setAlert={setAlert} />
                </div>
            )}

            {loading &&
                <Loader className={"page_loader"} />
            }

            {!loading &&
                <>
                    <div className="breadcrumb-wrapper breadcrumb-contacts">
                        <div className="d-flex flex-column">
                            <BreadCrumb value={bread_crumb_value} />
                        </div>
                    </div>
                    {errors ?
                        <div className="card">
                            <div className="card-body defaultText">
                                <div className="text-center">
                                    <Message text={errorMessages[getErrorMessage("order")]} type={"normal"} showMessage={true} />
                                </div>
                            </div>
                        </div>
                        :
                        <EditOrder onSaveOrder={onSaveOrder} />
                    }
                </>
            }

        </Layout>


    )
}
export default EditOrdersPage;

