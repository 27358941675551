import React from "react";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import AddUser from '../../Components/Users/AddUser/AddUser';

const AddUserPage = () => {
    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <div className="d-flex flex-column">
                    <BreadCrumb />
                </div>
            </div>
            {/* Create User */}
            <AddUser />
        </Layout>
    )
}
export default AddUserPage;

