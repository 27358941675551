export const PageConfig = {
    "thead": {
        "s_no": "S.no",
        "name": "Name",
        "status": "Status",
        "action": "Action"
    },
    "tbody": {
        "tr": {
            "s_no": "1",
            "name": "Home",
            "status": "Active"
        }
    }
}
