import React from 'react';

function ErrorMessage({ fieldName, errors }) {
  // console.log("fieldName",fieldName)
  // console.log("errors",errors)
  const error = errors?.find(item => item?.field_name === fieldName);
  return error?.message
}

export default ErrorMessage;
