import React  from "react";
import Layout from "../../Components/Global/Layout";
import GlobalAdminDashboard from "../../Components/Dashboard/GlobalAdminDashboard/GlobalAdminDashboard";
import StoreAdminDashboard from "../../Components/Dashboard/StoreAdminDashboard/StoreAdminDashboard";
import { useStore } from "../../Utils/utils";
import "./DashboardPage.css";

const DashboardPage = () => {
   
    const { store, setStore } = useStore();
    var store_id = store?.id
    
    return (
        <Layout>
            {
                store_id == undefined || store_id == null || store_id == 0 ? <GlobalAdminDashboard /> : <StoreAdminDashboard />
            }
        </Layout >
    )
}
export default DashboardPage;