import React, { useState, useEffect } from "react";
import { callAPI, useUser } from "../../../Utils/utils";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line'
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { dashboard_page_labels } from "../../../Constants/Labels";
import ErrorMessage from "../../Global/ErrorMessage"
import BreadCrumb from '../../../Components/Global/BreadCrumb/BreadCrumb';
import Message from "../../Message/Message";
import Loader from "../../Global/Loader/Loader";
import moment from "moment";
import Logo from "../../Logo/Logo"
import "./GlobalAdminDashboard.css";

const GlobalAdminDashboard = () => {

    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const [fees, setFees] = useState({});
    const [errors, setErrors] = useState();
    const [sales, setSales] = useState({});
    const [store, setStore] = useState({});
    const [orders, setOrders] = useState({});
    const [payLoad, setPayLoad] = useState();
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState({});
    const [formValues, setformValues] = useState({});
    const [topFiveStores, setTopFiveStores] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [averageOrderValues, setaverageOrderValues] = useState({});
    
    useEffect(() => {
        if (formValues?.time_window != "custom") {
            setPayLoad({
                "time_window": {
                    "window_type": formValues?.time_window == undefined ? "this-week" : formValues?.time_window
                }
            }
            )
        }

    }, [formValues])

    // on page load get data from data base and store in state
    useEffect(() => {
        callAPI("/admin/dashboard/global-orders-by-date",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    const updatedResponse = {
                        ...data,
                        records: data?.records?.map(obj => ({
                            Date: obj?.date,
                            Orders: obj?.count
                        }))
                    };
                    setOrders(updatedResponse)
                } else {
                    setErrors(data.errors)
                    // console.log('data.errors', data.errors)
                }
            }
            )
    }, [payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/global-sales-by-date",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    const updatedResponse = {
                        ...data,
                        records: data?.records?.map(obj => ({
                            Date: obj?.date,
                            Sales: obj?.sales
                        }))
                    };
                    setSales(updatedResponse)
                } else {
                    // console.log('data.error', data.error)
                }
            }
            )
    }, [payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/global-average-order-value",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)
            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    const updatedResponse = {
                        ...data,
                        records: data?.records?.map(obj => ({
                            Date: obj?.date,
                            AverageOrderValue: obj?.average_order_value
                        }))
                    };
                    setaverageOrderValues(updatedResponse)
                } else {
                    // console.log('data.error', data.error)
                }
            }
            )
    }, [payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/global-customer-count",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    const updatedResponse = {
                        ...data,
                        records: data?.records?.map(obj => ({
                            Date: obj?.date,
                            Customers: obj?.customers
                        }))
                    };
                    setCustomers(updatedResponse)
                } else {
                    // console.log('data.error', data.error)
                }
            }
            )
    }, [payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/global-fee-amount",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data?.success == true) {
                    const updatedResponse = {
                        ...data,
                        records: data?.records?.map(obj => ({
                            Date: obj?.date,
                            Fees: obj?.fees_value
                        }))
                    };
                    setFees(updatedResponse)
                } else {
                    // console.log('data.error', data.error)
                }
            }
            )
    }, [payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/global-store-count",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    setStore(data)
                } else {
                    // console.log('data.error', data.error)
                }
            }
            )
    }, [payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/top-five-stores-by-sales",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    setTopFiveStores(data?.records)
                } else {
                    // console.log('data.error', data.error)
                }
            }
            )
    }, [payLoad])

    var orders__by_date = orders?.records
    // Convert the "count" values to numbers
    orders__by_date?.forEach(orders__by_date => {
        orders__by_date.count = Number(orders__by_date?.count);
    });

    // console.log("orders__by_date", orders__by_date)

    var sales_by_date = sales?.records
    // Convert the "sales" values to numbers
    sales_by_date?.forEach(sales_by_date => {
        sales_by_date.Sales = Number(sales_by_date?.Sales);
    });

    var averageOrderValue_by_date = averageOrderValues?.records
    // Convert the "sales" values to numbers
    averageOrderValue_by_date?.forEach(averageOrderValue_by_date => {
        averageOrderValue_by_date.AverageOrderValue = Number(averageOrderValue_by_date?.AverageOrderValue);
    });

    var fees_by_date = fees?.records
    // Convert the "sales" values to numbers
    fees_by_date?.forEach(fees_by_date => {
        fees_by_date.Fees = Number(fees_by_date?.Fees);
    });

    var customers_by_date = customers?.records
    // Convert the "sales" values to numbers
    customers_by_date?.forEach(customers_by_date => {
        customers_by_date.customers = Number(customers_by_date?.customers);
    });

    // store onchage value in state
    const onChangeField = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value })
        setShowErrorMessage(false)
        setErrors([])
    }

    const onSelectedDate = () => {
        setShowErrorMessage(true)
        const time_window = {
            "window_type": "custom",
            "from_date": formValues["from_date"] ? moment(formValues["from_date"]).format("YYYY-MM-DD") : "",
            "to_date": formValues["to_date"] ? moment(formValues["to_date"]).format("YYYY-MM-DD") : ""
        };

        setPayLoad({
            "time_window": time_window
        });
    }

    var total_stores = store?.total_stores ? store?.total_stores : 0
    var total_orders = orders?.order_count ? orders?.order_count : 0
    var total_sales = sales?.total_sales ? sales?.total_sales : 0
    var average_order_values = averageOrderValues?.total_average_order_value ? averageOrderValues?.total_average_order_value : 0
    var total_customers = customers?.total_customers ? customers?.total_customers : 0
    var total_fees = fees?.total_fees ? fees?.total_fees : 0

    var sales_by_date_convertedData = []
    if (sales_by_date?.length != 0 && sales_by_date?.length != undefined) {
        sales_by_date_convertedData = [
            {
                id: 'Sales',
                data: sales_by_date?.map(item => ({
                    x: item?.Date,
                    y: item?.Sales
                }))
            }
        ];
    }


    var average_order_values_convertedData = []
    if (averageOrderValue_by_date?.length != 0 && averageOrderValue_by_date?.length != undefined) {
        average_order_values_convertedData = [
            {
                id: 'average_order_value',
                data: averageOrderValue_by_date?.map(item => ({
                    x: item?.Date,
                    y: item?.AverageOrderValue
                }))
            }
        ];
    }

    const formatXAxisTick = (value) => {
        return value.replace('-', '');
    };

    return (
        <>

            {loading &&
                <Loader className={"dashboard_page_loader"} />
            }

            <div className="breadcrumb-wrapper breadcrumb-contacts ">
                <div className="d-flex flex-column">
                    <BreadCrumb />
                </div>
                <div className="date_and_dropdown_wrapper">
                    {formValues?.time_window == "custom" &&
                        <div className=" custome_address_wrapper">
                            <div className="custome_address">
                                <div className="from_date">
                                    <label className="form-label mandatory_field">{dashboard_page_labels?.global_and_store_custom?.from_date}</label>
                                    <input type="date" placeholder="from" className="form-control" name='from_date' max={formValues['to_date']} value={formValues['from_date'] || ''} onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'from_date', errors })] || ErrorMessage({ fieldName: 'from_date', errors })} type={"errorMsg"} style={{ marginTop: "17px" }} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                                <div className="to_date">
                                    <label className="form-label mandatory_field">{dashboard_page_labels?.global_and_store_custom?.to_date}</label>
                                    <input type="date" className="form-control" name='to_date' placeholder="To date" min={formValues['from_date']} value={formValues['to_date'] || ''} onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'to_date', errors })] || ErrorMessage({ fieldName: 'to_date', errors })} type={"errorMsg"} style={{ marginTop: "17px" }} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                                <div className="apply_btn">
                                    <button className="btn btn-primary" onClick={onSelectedDate}>{dashboard_page_labels?.global_and_store_custom?.btn_label}</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="drop_down">
                        <select className="form-control" name='time_window' onChange={onChangeField} value={formValues['time_window'] || "this-week"} >
                            {dashboard_page_labels?.global_and_store_options?.map((option, index) => (
                                <option key={index} value={option?.value}>{option?.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>


            {!loading &&

                <div className="chartBlock">
                    <div className="chartWrapper_stores">
                        <div className="card">
                            <p> {dashboard_page_labels?.global?.total_store} : {total_stores > 0 ? total_stores - 1 : total_stores}</p>
                        </div>
                    </div>

                    <div className="chartWrapper">
                        <div className="card">
                            <div className="individualChartBlock p-3">
                                <span className="chartTitle">{dashboard_page_labels?.global?.orders} : {total_orders}</span>

                                {orders__by_date?.length != 0 && orders__by_date?.length != undefined &&
                                    <div style={{ width: "100%", height: "400px" }}>
                                        <ResponsiveBar
                                            data={orders__by_date}
                                            keys={['Orders']}
                                            indexBy="Date"
                                            enableGridY={false} // Disable vertical grid lines
                                            enableGridX={false} // Disable horizontal grid lines
                                            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                                            colors="#005460"
                                            axisLeft={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                tickFormat: (value) => {
                                                    if (value < 0) {
                                                        return `${Math.abs(value)}+`;
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40,
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                            }}
                                            labelTextColor="#FFFFFF"
                                            axisBottom={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                // legend: 'Date',
                                                // itemWidth: 100,
                                                tickFormat: formatXAxisTick,

                                                legendPosition: 'middle',
                                                legendOffset: 40,
                                                format: () => '', // Hide the tick labels
                                                tickValues: 5, // Adjust the number of ticks displayed
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                                barSize: 5

                                            }}
                                        />
                                    </div>

                                }
                            </div>
                        </div>

                        <div className="card">
                            <div className="individualChartBlock p-3 "  >
                                <span className="chartTitle">{dashboard_page_labels?.global?.sales} : {total_sales}</span>
                                {sales_by_date_convertedData?.length != 0 && sales_by_date_convertedData?.length != undefined &&
                                    <div style={{ width: "100%", height: "400px" }}>
                                        <ResponsiveLine
                                            data={sales_by_date_convertedData}
                                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                            enableGridY={false} // Disable vertical grid lines
                                            enableGridX={false} // Disable horizontal grid lines
                                            xScale={{ type: 'point' }}
                                            yScale={{
                                                type: 'linear',
                                                min: 'auto',
                                                max: 'auto',
                                                stacked: true,
                                                reverse: false
                                            }}
                                            yFormat=" >-.2f"
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                enable: true,
                                                tickSize: 0,
                                                tickPadding: 0,
                                                tickRotation: 0,
                                                legend: '',
                                                legendOffset: 36,
                                                legendPosition: 'middle',
                                                format: () => '', // Empty string to hide tick text
                                            }}
                                            axisLeft={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                tickFormat: (value) => {
                                                    if (value < 0) {
                                                        return `${Math.abs(value)}+`;
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40,
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                            }}
                                            colors="#005460"
                                            pointSize={10}
                                            pointColor={{ theme: 'background' }}
                                            pointBorderWidth={2}
                                            pointBorderColor={{ from: 'serieColor' }}
                                            pointLabelYOffset={-12}
                                            useMesh={true}
                                            legends={[
                                                {
                                                    anchor: 'top-right',
                                                    direction: 'column',
                                                    top: 0,
                                                    justify: false,
                                                    translateX: 100,
                                                    translateY: 0,
                                                    itemsSpacing: 0,
                                                    itemDirection: 'left-to-right',
                                                    itemWidth: 80,
                                                    itemHeight: 20,
                                                    itemOpacity: 0.75,
                                                    symbolSize: 12,
                                                    symbolShape: 'circle',
                                                    symbolBorderColor: "#005460",
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ],
                                                }
                                            ]}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="chartWrapper">
                        <div className="card">
                            <div className="individualChartBlock p-3">
                                <span className="chartTitle">{dashboard_page_labels?.global?.average_order_value} : {average_order_values}</span>
                                {average_order_values_convertedData?.length != 0 && average_order_values_convertedData?.length != undefined &&
                                    <div style={{ width: "100%", height: "400px" }}>

                                        <ResponsiveLine
                                            data={average_order_values_convertedData}
                                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                            xScale={{ type: 'point' }}
                                            enableGridY={false} // Disable vertical grid lines
                                            enableGridX={false} // Disable horizontal grid lines
                                            yScale={{
                                                type: 'linear',
                                                min: 'auto',
                                                max: 'auto',
                                                stacked: true,
                                                reverse: false
                                            }}
                                            yFormat=" >-.2f"
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                enable: true,
                                                tickSize: 0,
                                                tickPadding: 0,
                                                tickRotation: 0,
                                                legend: '',
                                                legendOffset: 36,
                                                legendPosition: 'middle',
                                                format: () => '', // Empty string to hide tick text
                                            }}
                                            axisLeft={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                tickFormat: (value) => {
                                                    if (value < 0) {
                                                        return `${Math.abs(value)}+`;
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40,
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                            }}
                                            colors="#005460"
                                            pointSize={10}
                                            pointColor={{ theme: 'background' }}
                                            pointBorderWidth={2}
                                            pointBorderColor={{ from: 'serieColor' }}
                                            pointLabelYOffset={-12}
                                            useMesh={true}
                                            legends={[
                                                {
                                                    anchor: 'top-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 98,
                                                    translateY: -40,
                                                    itemsSpacing: 0,
                                                    itemDirection: 'left-to-right',
                                                    itemWidth: 80,
                                                    itemHeight: 20,
                                                    itemOpacity: 0.75,
                                                    symbolSize: 12,
                                                    symbolShape: 'circle',
                                                    symbolBorderColor: "#005460",
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ],
                                                }
                                            ]}
                                        />


                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card">
                            <div className="individualChartBlock p-3">
                                <span className="chartTitle">{dashboard_page_labels?.global?.customers} : {total_customers} </span>
                                {customers_by_date?.length != 0 && customers_by_date?.length != undefined &&
                                    <div style={{ width: "100%", height: "400px" }}>
                                        <ResponsiveBar
                                            data={customers_by_date}
                                            keys={['Customers']}
                                            indexBy="Date"
                                            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                                            colors="#005460"
                                            labelTextColor="#FFFFFF"
                                            enableGridY={false} // Disable vertical grid lines
                                            enableGridX={false} // Disable horizontal grid lines
                                            axisLeft={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                tickFormat: (value) => {
                                                    if (value < 0) {
                                                        return `${Math.abs(value)}+`;
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40,
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                            }}
                                            axisBottom={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                // legend: 'Date',
                                                // itemWidth: 100,

                                                legendPosition: 'middle',
                                                legendOffset: 40,
                                                format: () => '', // Hide the tick labels
                                                tickValues: 5, // Adjust the number of ticks displayed
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                                barSize: 5

                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="chartWrapper">
                        <div className="card">
                            <div className="individualChartBlock p-3">
                                <span className="chartTitle">{dashboard_page_labels?.global?.fees} : {total_fees}</span>
                                {fees_by_date?.length != 0 && fees_by_date?.length != undefined &&
                                    <div style={{ width: "100%", height: "400px" }}>
                                        <ResponsiveBar
                                            data={fees_by_date}
                                            keys={['Fees']}
                                            indexBy="Date"
                                            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                                            colors="#005460"
                                            labelTextColor="#FFFFFF"
                                            enableGridY={false} // Disable vertical grid lines
                                            enableGridX={false} // Disable horizontal grid lines
                                            axisLeft={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                tickFormat: (value) => {
                                                    if (value < 0) {
                                                        return `${Math.abs(value)}+`;
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40,
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                            }}
                                            axisBottom={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                // legend: 'Date',
                                                // itemWidth: 100,

                                                legendPosition: 'middle',
                                                legendOffset: 40,
                                                format: () => '', // Hide the tick labels
                                                tickValues: 5, // Adjust the number of ticks displayed
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                                barSize: 5

                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card">
                            <div className="individualChartBlock p-5">
                                <div className="topfiveStoreListWrapper">
                                    <span>{dashboard_page_labels?.global?.stores_table?.title}</span>
                                    <div className="tableWrapper">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{dashboard_page_labels?.global?.stores_table?.store}</th>
                                                    <th>{dashboard_page_labels?.global?.stores_table?.sales}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {topFiveStores?.map((store, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="store_logo_and_store_name storeLogo">
                                                                {store?.path ? <img src={store?.path} className="storeLogo mr-3" /> :
                                                                    <Logo name={store?.store_name} className={"top_store_name_as_logo"} />}
                                                                {store?.store_name}
                                                            </td>
                                                            <td>{store?.total_sales}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default GlobalAdminDashboard;