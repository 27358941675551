import React, { useState, useEffect } from "react";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import EditFromCustomerById from "../../Components/Customer/EditCustomer/EditFromCustomerById";
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { callAPI, useUser } from "../../Utils/utils";
import { item_required_error_message } from "../../Constants/Messages";
import { errorMessages } from "../../Constants/Errors";
import Alert from "../../Components/Alert/Alert";
import Loader from "../../Components/Global/Loader/Loader";
import Message from "../../Components/Message/Message";

const EditCustomerPage = () => {
	var { id } = useParams();
	const navigate = useNavigate();
	const [alert, setAlert] = useState()
	const { user, setUser } = useUser();
	const [errors, setErrors] = useState()
	const [loading, setLoading] = useState(false)
	const [formValues, setformValues] = useState({})
	const [refreshCount, setRefreshCount] = useState(0)


	useEffect(() => {
		var SavedMessage = localStorage.getItem('Message')
		if (SavedMessage == "Order") {
			localStorage.setItem("Message", null)
		}
		else if (SavedMessage != null) {
			setAlert({ text: SavedMessage, type: "success" })
		}
	}, [])

	useEffect(() => {
		callAPI(`/admin/customers/${id}`,
			{
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'token': user?.auth_token
				},
			}, navigate, setLoading)
			.then(data => {
				// console.log("api response " + JSON.stringify(data))
				if (data.success === true) {

					setformValues(data?.record);
				} else {
					setErrors(data?.errors)
					// console.log('errors', data?.errors)
				}
			})
	}, [refreshCount])

	const onUpdateCustomer = (Message) => {
		if (Message == "success") {
			setRefreshCount(refreshCount + 1)
			setAlert({ text: "Customer saved successfully", type: "success" })
		}
		else if (Message == "error") {
			setAlert({ text: item_required_error_message, type: "error" })
		}
	}



	// Function to get error message by field name
	function getErrorMessage(fieldName) {
		const error = errors?.find(item => item.field_name === fieldName);
		return error ? error.message : "Field not found";
	}

	var customerName = "";

	if (formValues) {
		if (formValues.first_name !== undefined && formValues.first_name !== "NULL" && formValues.first_name !== null && formValues.first_name !== "null" && formValues.first_name !== 0 && formValues.first_name !== "0") {
			customerName = formValues.first_name;
		}
		if (formValues.last_name !== undefined && formValues.last_name !== "NULL" && formValues.last_name !== null && formValues.last_name !== "null" && formValues.last_name !== 0 && formValues.last_name !== "0") {
			customerName += (customerName ? " " : "") + formValues.last_name;
		}
	}

	// If both first_name and last_name were undefined or met the exclusion conditions, customerName will remain an empty string.

	// If you want to trim the resulting customerName, you can use .trim():
	customerName = customerName.trim();


	console.log("customerName", customerName)
	return (
		<Layout>
			{loading &&
				<Loader className={"page_loader"} />
			}
			{loading == false &&
				<>
					<div className="breadcrumb-wrapper breadcrumb-contacts">
						<div className="d-flex flex-column">
							<BreadCrumb value={customerName} />
						</div>
					</div>
					{alert?.text == 'Customer saved successfully' || alert?.text == item_required_error_message || alert?.text == "Customer created successfully" ?
						<div className="text-center">
							<Alert text={alert.text} type={alert.type} setAlert={setAlert} />
						</div>
						: ''}

					{errors ?
						<div className="card">
							<div className="card-body defaultText">
								<p className="text-center">
									<Message text={errorMessages[getErrorMessage("customer")]} type={"normal"} showMessage={true} />
								</p>
							</div>
						</div>
						:
						<EditFromCustomerById onUpdateCustomer={onUpdateCustomer} />
					}
				</>}
		</Layout>
	)
}
export default EditCustomerPage;