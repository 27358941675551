import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom";
import "./PageTitle.css";

const PageTitle = ({ heading = null }) => {

    const [pageTitle, setPageTitle] = useState()
    // get page location from useLocation()
    const location = useLocation()
    // split pathname in location and store Current_path variable
    var Current_path = [location?.pathname?.split("/")]
    // get page title in current path
    var page_title = Current_path[0]

    useEffect(() => {
        
        if (heading == null) {
            if (page_title[1] == "admin" && page_title[2] == null || page_title[2] == undefined) {
                setPageTitle("dashboard")
            }
            else if (page_title[2] != undefined) {
                if (page_title[2] == "myprofile") {
                    setPageTitle("my profile")
                }
                else if (page_title[2] == "storeprofile") {
                    setPageTitle("Store Profile")
                }
                else if (page_title[2] == "salesandfees") {
                    setPageTitle("Sales And Fees")
                }
                else if (page_title[2] && page_title[3] == "new" || page_title[3] == "add") {
                    setPageTitle("add" + " " + page_title[2])
                }
                else {
                    setPageTitle(page_title[2])
                }
            }

        }
        else {
            setPageTitle(heading)
        }
    }, [])


    return (
        <React.Fragment>
            <p className="pageTitle" >{pageTitle}</p>
        </React.Fragment>
    )
}

export default PageTitle;