import React, { useState } from "react";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserContext } from "./Contexts/UserContext";
import { StoreContext } from "./Contexts/StoreContext";
import { PageContext } from "./Contexts/PageContext";
import PasswordGeneration from "./Components/Global/Password Generation/PasswordGeneration";
import LoggedInLayout from "./Layouts/LoggedInLayout";
import LoggedOutLayout from "./Layouts/LoggedOutLayout";
import PageNotFound from "./Components/Global/PageNotFound.jsx/PageNotFound";
import InternalServerErrorPage from "./Pages/InternalServerErrorPage/InternalServerErrorPage"
import './index.css';
import "./css/MarketPlace.css";

const App = () => {

  const [user, setUser] = useState()
  const [store, setStore] = useState()
  const [page, setPage] = useState()


  const setAuthUser = (user) => {
    // console.log("setToken in index.js - " + token)
    setUser(user)
  }

  const assignStore = (store) => {
    // console.log("setstore in index.js - " + store)
    setStore(store)
  }

  const assignPage = (page) => {
    // console.log("setPage in index.js - " + page)
    setPage(page)
  }

  
  return (

    <BrowserRouter>
      <UserContext.Provider value={{ user: user, setUser: setAuthUser }} >
        <StoreContext.Provider value={{ store: store, setStore: assignStore }} >
          <PageContext.Provider value={{ page: page, setPage: assignPage }} >
            <Routes>
              <Route path="/" element={<LoggedOutLayout name='LoginFrom' />} />
              <Route path='/forgotpassword' element={<LoggedOutLayout name='ForgotPassword' />} />
              <Route path='/admin' element={<LoggedInLayout name='DashboardPage' />} />
              <Route path='/admin/orders' element={<LoggedInLayout name='OrdersPageForMultipleAddress' />} />
              <Route path='/admin/order/new' element={<LoggedInLayout name='AddOrderPageForMultipleAddress' />} />
              <Route path='/admin/order/edit/:id' element={<LoggedInLayout name='EditOrdersPage' />} />
              <Route path='/order-confirmation' element={<LoggedInLayout name='OrderConfirmationPage' />} />
              <Route path='/payment-failed' element={<LoggedInLayout name='PaymentFailed' />} />
              <Route path='/admin/users' element={<LoggedInLayout name='UsersPage' />} />
              <Route path='/admin/user/new' element={<LoggedInLayout name='UserAddPage' />} />
              <Route path='/admin/user/edit/:id' element={<LoggedInLayout name='UserEditPage' />} />
              <Route path='/admin/stores' element={<LoggedInLayout name='StoresPage' />} />
              <Route path='/admin/storeprofile' element={<LoggedInLayout name='StoreProfilePage' />} />
              <Route path='/admin/storeprofile/edit' element={<LoggedInLayout name='EditStoreProfilePage' />} />
              <Route path='/admin/store/new' element={<LoggedInLayout name='StoreAddPage' />} />
              <Route path='/admin/store/edit/:id' element={<LoggedInLayout name='StoreEditPage' />} />
              <Route path='/admin/products' element={<LoggedInLayout name='ProductPage' />} />
              <Route path='/admin/product/new' element={<LoggedInLayout name='AddProductPage' />} />
              <Route path='/admin/product/edit/:id' element={<LoggedInLayout name='EditProduct' />} />
              <Route path='/admin/customers' element={<LoggedInLayout name='CustomerPage' />} />
              <Route path='/admin/customer/new' element={<LoggedInLayout name='AddCustomerPage' />} />
              <Route path='/admin/customer/edit/:id' element={<LoggedInLayout name='EditCustomerPage' />} />
              <Route path='/admin/customer/edit/:id/address/new' element={<LoggedInLayout name='CustomerAddAddressPage' />} />
              <Route path='/admin/customer/edit/:id/address/edit/:addressId' element={<LoggedInLayout name='CustomerEditAddressPage' />} />
              <Route path='/admin/customer/edit/:id/order/edit/:orderid' element={<LoggedInLayout name='EditOrdersPage' />} />
              <Route path='/admin/categories' element={<LoggedInLayout name='CategoriesPage' />} />
              <Route path='/admin/category/new' element={<LoggedInLayout name='CategoryAddPage' />} />
              <Route path='/admin/category/edit/:id' element={<LoggedInLayout name='CategoryEditPage' />} />
              <Route path='/admin/salesandfees' element={<LoggedInLayout name='SalesAndFeesPage' />} />
              <Route path='/admin/salesandfees/byorders/:id/:date' element={<LoggedInLayout name='SalesAndFeesByOrderPage' />} />
              <Route path='/admin/settings/fees' element={<LoggedInLayout name='FeesPage' />} />
              <Route path='/admin/settings/fees/edit/:id' element={<LoggedInLayout name='FeesPage' />} />
              <Route path='/admin/settings/tax' element={<LoggedInLayout name='TaxPage' />} />
              <Route path='/admin/settings/shipping' element={<LoggedInLayout name='ShippingPage' />} />
              <Route path='/admin/settings/pages' element={<LoggedInLayout name='PageTable' />} />
              <Route path='/admin/settings/payment_gateway' element={<LoggedInLayout name='PaymentGatewayPage' />} />
              <Route path='/admin/settings/pages/edit/:id' element={<LoggedInLayout name='HomePage' />} />
              <Route path='/password-generation' element={<PasswordGeneration />} />
              <Route path='/admin/myprofile/:id' element={<LoggedInLayout name='MyProfilePage' />} />
              <Route path='/admin/myprofile/edit/:id' element={<LoggedInLayout name='EditMyProfilePage' />} />
              <Route path='/admin/myprofile/:id/reset-password' element={<LoggedInLayout name='ResetPasswordPage' />} />
              <Route path='/admin/order/:id/paypal' element={<LoggedInLayout name='PayPalPage' />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="/500-internal-server-error" element={<InternalServerErrorPage />} />
            </Routes>
          </PageContext.Provider>
        </StoreContext.Provider>
      </UserContext.Provider>
    </BrowserRouter>

  );
};


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
