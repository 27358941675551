import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Global/Scroll"
import { errorMessages } from "../../../Constants/Errors";
import { category_page_labels } from "../../../Constants/Labels";
import { categories_page_message, alert_message_type } from "../../../Constants/Messages";
import { callAPI, useStore, usePage, useUser } from "../../../Utils/utils";
import Message from "../../Message/Message";
import Alert from "../../Alert/Alert";
import { PopUpMessage } from "../../Global/PopupMessage/PopupMessage";
import ImageSelector from "../../ImageSelector/ImageSelector";
import ImagesPreview from "../../ImagesPreview/ImagesPreview";
import ErrorMessage from "../../Global/ErrorMessage";
import './CategoryEdit.css';

export default function CategoryEdit({ onUpdateCategory }) {

    var { id } = useParams();
    const navigate = useNavigate();
    const { page, setPage } = usePage()
    const { user, setUser } = useUser();
    const { store, setStore } = useStore()
    const [errors, setErrors] = useState();
    const [payLoad, setPayLoad] = useState()
    const [mode, setMode] = useState('list')
    const [alert, setAlert] = useState()
    const [loading, setLoading] = useState(false)
    const [lineItems, setLineItems] = useState([])
    const [storeName, setStoreName] = useState([]);
    const [formValues, setformValues] = useState({})
    const [productList, setProductList] = useState([])
    const [savedImages, setSavedImages] = useState([])
    const [addedProducts, setAddProducts] = useState([]);
    const [imageSelector, setImageSelector] = useState();
    const [selectedImages, setSelectedImages] = useState([])
    const [defaultProduct, setDefaultProduct] = useState([])
    const [removeProducts, setRemoveProducts] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [searchProductName, setSearchProductName] = useState('')
    const [updatedFormValues, setUpdatedFormValues] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [categoryBasedProduct, setCategoryBasedProduct] = useState([])
    const [openDelectPopup, setOpenDelectPopup] = useState();

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    // store onchage value in state
    const onChangeField = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value })
        setUpdatedFormValues({ ...updatedFormValues, [event.target.name]: event.target.value })
        setShowErrorMessage(false)
    }
    // onclick to  validate check require fields and post Storedata database
    useEffect(() => {
        if (id) {
            callAPI(`/admin/category/${id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },

                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data.success === true) {
                        setformValues(data.record);
                        if (data?.record?.category_image && data?.record?.category_image?.path && data?.record?.category_image?.name) {
                            setSavedImages([{
                                "image_id": data?.record?.category_image?.image_id,
                                "path": data?.record?.category_image?.path,
                                "name": data?.record?.category_image?.name
                            }])
                        } else {
                            // console.log('data.errors', data.errors)
                        }
                    }
                })
        }
    }, [])

    // get data from database based on id on page onload
    useEffect(() => {
        if (id) {
            var pay_load = {
                "category_id": id,
            }
            callAPI('/admin/category/products-for-category',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(pay_load)
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data.success) {
                        setCategoryBasedProduct(data?.records)
                    } else {
                        // console.log('Error occurred. Please try again.')
                    }
                }
                )
        }
    }, [])

    useEffect(() => {

        if (store?.id) {
            var inital_pay_load = {
                "store_id": store?.id,
                "status": "all",
                "page_size": 10,
                "current_page": 1
            }
            var pay_load = payLoad || inital_pay_load
            pay_load.page_size = 100
            pay_load.current_page = 1
            // console.log("pay_load", pay_load)
            callAPI('/admin/products/by-store',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(pay_load)
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response ", data.success)
                    if (data.success) {
                        setProductList(data.records)
                        if (removeProducts?.length == 0 && defaultProduct?.length == 0) {
                            var categoryProduct = categoryBasedProduct?.map((Category) =>
                                data.records?.find((list) => list?.product_id == Category?.product_id))
                            setDefaultProduct(categoryProduct)
                            setSelectedProducts(categoryProduct)
                            var newLineItems = []
                            //display the items based on the selected products
                            categoryProduct?.map((selectedProduct) => {
                                if (selectedProduct) {
                                    // Push a new object into newLineItems array
                                    newLineItems.push({
                                        product_id: selectedProduct?.product_id,
                                        store_id: store?.id,
                                        name: selectedProduct?.name,
                                        price_per_item: selectedProduct?.price,
                                        quantity: 1
                                    });
                                }
                            })

                            setLineItems(newLineItems)
                        }
                    } else {
                        // console.log("errors", data.errors)
                    }
                }
                )
        }
    }, [categoryBasedProduct, store?.id, payLoad])

    // check the required fields and Posting the data to database.
    const saveCategory = (isCategoryDelete) => {
        // console.log("isCategoryDelete", isCategoryDelete == true)
        // This enables smooth scrolling
        scrollToTop()

        // concat addedProducts and removeProducts 
        var checkDuplicateProduct_id = addedProducts?.concat(removeProducts)
        // check duplicate values
        function findDuplicates(arr) {
            return arr?.filter((currentValue, currentIndex) =>
                arr?.indexOf(currentValue) !== currentIndex);
        }
        // remove duplicate values in addedProducts and store in newProducts
        var newProducts = addedProducts
        newProducts = newProducts?.filter(item => !findDuplicates(checkDuplicateProduct_id)?.includes(item))
        // remove duplicate values in removeProducts and store in removeDefaultProducts
        var removeDefaultProducts = removeProducts
        removeDefaultProducts = removeDefaultProducts?.filter(item => !findDuplicates(checkDuplicateProduct_id)?.includes(item))

        //  updated values
        const payload = {
            "category_id": id,
            "store_id": store?.id,
            "name": updatedFormValues?.name?.trim(),
            "description": updatedFormValues?.description,
            "status": updatedFormValues?.status,
            "added_products": newProducts,
            "removed_products": removeDefaultProducts,
        }

        if (selectedImages == null) {
            payload.image = null
        }
        else if (selectedImages?.image_id) {
            payload.image = selectedImages?.image_id
        }

        // console.log("payload", payload)

        callAPI('/admin/category/update',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(payload)
            }, navigate, setLoading)

            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success === true) {
                    if (isCategoryDelete == true) {
                        onCategoryDelete()
                    }
                    else {
                        onUpdateCategory("success")
                    }

                }
                else {
                    setErrors(data?.errors)
                    setShowErrorMessage(true)
                    onUpdateCategory("error")
                    // console.log("errors", data?.errors)
                }
            }
            )
    }

    //get the product name during on change of the input value 
    const onSearchProductName = (event) => {
        setSearchProductName(event.target.value)
        setMode('open')

        var pay_load = {
            "store_id": store?.id,
            "status": "all",
        }

        if (event.target.value) {
            pay_load.filters = {
                "conditions": [
                    { "field": "p.name", "operator": "like", "value": event.target.value, "dataType": "string" }
                ]
            }
        }
        setPayLoad(pay_load)
    }

    const onClickProductsList = () => {
        // Set the mode to 'open' to indicate that the search results should be displayed.
        setMode('open');
        var pay_load = {
            "store_id": store?.id,
            "status": "all",
        }
        setPayLoad(pay_load)
    }

    //get list of products when on click the search icon
    const onClickProductSearchIcon = () => {
        setMode('open')

        var pay_load = {
            "store_id": store?.id,
            "status": "all",
        }

        if (searchProductName) {
            pay_load.filters = {
                "conditions": [
                    { "field": "p.name", "operator": "like", "value": searchProductName, "dataType": "string" }
                ]
            }
        }

        setPayLoad(pay_load)
    }

    const onChangeFields = (event, product) => {


        if (selectedProducts?.length == 0 && !selectedProducts?.some((selectedProduct) => selectedProduct?.product_id === product?.product_id)) {
            setSelectedProducts([product]);
        }
        else if (selectedProducts?.length > 0 && !selectedProducts?.some((selectedProduct) => selectedProduct?.product_id === product?.product_id)) {
            setSelectedProducts([...selectedProducts, product]);
        }
        else {
            // Remove the unselected product from the selectedProducts list.
            setSelectedProducts(selectedProducts?.filter((selectedProduct) => selectedProduct?.product_id !== product?.product_id));
        }

        if (defaultProduct?.some((selectedProduct) => selectedProduct?.product_id === product.product_id)) {
            setDefaultProduct(defaultProduct?.filter((selectedProduct) => selectedProduct?.product_id !== product?.product_id));
            if (removeProducts?.length == 0) {
                setRemoveProducts([product?.product_id])
            }
            else if (removeProducts?.length != 0) {
                setRemoveProducts([...removeProducts, product?.product_id]);
            }
        }

        if (addedProducts?.length == 0 && !defaultProduct?.some((selectedProduct) => selectedProduct?.product_id === product.product_id)) {
            var addedProductsIds = []
            addedProductsIds?.push(product?.product_id)
            setAddProducts(addedProductsIds)
        }

        else if (addedProducts?.length > 0 && !addedProducts?.some((product_id) => product_id === product?.product_id)) {
            setAddProducts([...addedProducts, product?.product_id]);
        }

        else {
            setAddProducts(addedProducts?.filter((selectedProduct) => selectedProduct !== product?.product_id));
        }
    }

    //Add the selected products to line items
    const onClickAddLineItems = () => {
        var newLineItems = [] //array of objects
        var newLineItems1 = [] //array of objects
        // Check if a product with the same product_id exists in LineItems

        //display the items based on the selected products
        selectedProducts?.map((selectedProduct) => {
            if (selectedProduct) {
                // Push a new object into newLineItems array
                newLineItems.push({
                    product_id: selectedProduct?.product_id,
                    store_id: store?.id,
                    name: selectedProduct?.name,
                    price_per_item: selectedProduct?.price,
                    quantity: 1
                });

                newLineItems1.push(selectedProduct?.product_id)
            }
        })

        // console.log("newline items during add line items - ", newLineItems)
        setLineItems(newLineItems)
        setAddProducts(newLineItems1)
        setMode('close')
    }

    //remove the line items
    const onClickRemoveProduct = (productIndex, product_id) => {
        //remove the line item from the line items
        setLineItems(lineItems?.filter((v, i) => i != productIndex))
        // remove the line item from the selected products list
        setSelectedProducts(selectedProducts?.filter((v, i) => i != productIndex))
        setAddProducts(addedProducts?.filter((v, i) => i != productIndex))
        setRemoveProducts((previousproducts) => [...previousproducts, product_id])
        setDefaultProduct(defaultProduct?.filter((v, i) => i !== productIndex))

    }

    //close the dropdown when click to cancel button
    const onClickCloseDropdown = () => {
        setMode('close')
        //clear the selected products when click to cancel button
        if (lineItems?.lenght == 0) {
            setSelectedProducts([])
        }
        else if (lineItems?.lenght != 0) {
            setSelectedProducts(lineItems)
            if (selectedProducts?.length != lineItems?.lenght) {
                setAddProducts(lineItems?.map(product => product?.product_id) || []);
            }
        }
    }

    const onOpen = (config) => {
        setImageSelector(config)
    }

    const onClose = () => {
        setImageSelector(false)
    }

    const onSelectImages = (images) => {
        setSelectedImages(images)
    }
    const onRemoveImageIds = () => {
        setSelectedImages(null)
    }

    const status = Object.keys(category_page_labels?.category_add_and_edit_page_status)?.map((name) => {
        const value = category_page_labels?.category_add_and_edit_page_status[name];
        return `${name},${value}`;
    });

    const onOpenMessagePopUp = () => {
        setOpenDelectPopup(true)
    }

    const onDelete = () => {
        if (lineItems?.length == 0) {
            saveCategory(true)
        }
        else {
            onCategoryDelete()
        }
    }

    const onCategoryDelete = () => {
        callAPI(`/admin/category/${id}/delete`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
            }, navigate, setLoading)
            .then(data => {
                // console.log("admin users update api response " + JSON.stringify(data));
                if (data.success) {
                    navigate("/admin/categories")
                    localStorage.setItem('Message', categories_page_message?.deleted_message)
                } else {
                    const errorObj = data?.errors?.find(error => error?.field_name == "category_id");
                    if (errorObj) {
                        const errorMessage = errorObj?.message;
                        setAlert({ text: errorMessages?.[errorMessage], type: alert_message_type.error })
                        setOpenDelectPopup(false)
                    }
                }
            }
            )
    }

    const onCloseMessagePopUp = () => {
        setOpenDelectPopup(false)
    }

    const onCloseDropdown = () => {
        setMode("close")
        setSearchProductName("")
    }

    return (
        <React.Fragment>

            <button className="btn btn-primary saveButton" onClick={saveCategory}>{category_page_labels?.category_edit_page_labels?.save_btn_label}</button>
            <button className="btn delectButton" onClick={() => onOpenMessagePopUp()}>{category_page_labels?.category_edit_page_labels?.delete_btn_label}</button>
            {openDelectPopup && <PopUpMessage delectMessage={true} isDelect={true} onDelete={onDelete} onClose={onCloseMessagePopUp} />}

            {alert?.text == errorMessages?.category_linked_with_product ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}

            <div className="row">
                <div className="col-lg-8">
                    <div className="card card-default">
                        <div className="card-header card-header-border-bottom">
                            <h2>{category_page_labels?.category_edit_page_labels?.title}</h2>
                        </div>

                        <div className="card-body">
                            <div className="ec-store-uploads">
                                <label className="form-label mandatory_field">{category_page_labels?.category_add_and_edit_page_labels?.name}</label>
                                <input type="text" className="form-control" maxLength="100" onChange={onChangeField} name='name' value={formValues['name'] || ""} />
                                <div className="errorMessage">
                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'name', errors })] || ErrorMessage({ fieldName: 'name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                </div>
                                <label className="form-label">{category_page_labels?.category_add_and_edit_page_labels?.description}</label>
                                <textarea type="text" className="form-control slug-title " rows="4" cols="50" maxLength="800" onChange={onChangeField} name='description' value={formValues['description'] || ""} />
                            </div>
                        </div>
                    </div>

                    <div className="card card-default mt-5">
                        <div className="card-header card-header-border-bottom">
                            <h2>{category_page_labels?.category_add_and_edit_page_labels?.products_block_title}</h2>
                        </div>
                        <div className="card-body">
                            <div className="productSearchWrapper">
                                <div className="search-form d-lg-inline-block searchWrapper">
                                    <div className="input-group">
                                        <input type="text" name="query" id="search-input" className="form-control searchInput" placeholder={`${category_page_labels?.category_add_and_edit_page_labels?.products_block_search_placholder}`} onChange={onSearchProductName} onClick={() => onClickProductsList()} />
                                        <span type="button" name="search" id="search-btn" className="product_search_icon" onClick={() => onClickProductSearchIcon()} >
                                            <i className="mdi mdi-magnify icon"></i>
                                        </span>
                                    </div>
                                </div>
                                {mode == 'open' &&
                                    <div className={`${productList ? "categorieList" : "noCategorieList"}`} >
                                        <ul id={`search-results`} >
                                            {productList?.map((list, index) => {
                                                var defaultSelectedProduct = defaultProduct?.find((item) => item?.product_id == list?.product_id)
                                                var alreadySelectedItem = lineItems?.find((item) => item?.product_id == list?.product_id)
                                                const isProductInSelected = selectedProducts?.some((selectedProduct) => selectedProduct?.product_id === list?.product_id);

                                                return (
                                                    <li
                                                        key={index}
                                                        className={`${isProductInSelected || defaultSelectedProduct ? "hasSelected seletedLineItem" : ""} productDropDown`}
                                                    >
                                                        <div
                                                            className={`${isProductInSelected || defaultSelectedProduct ? "hasSelected seletedLineItem" : ""} w-100`}

                                                            onClick={() => onChangeFields(!alreadySelectedItem, list)}>
                                                            <input
                                                                type="checkbox"
                                                                id={list?.product_id}
                                                                name={list?.product_id}
                                                                value={list?.product_id}
                                                                checked={isProductInSelected || defaultSelectedProduct ? true : false}
                                                                onChange={() => { }}
                                                            />
                                                            <span className="checkBoxLabel">
                                                                {list?.name}
                                                            </span>
                                                        </div>

                                                    </li>
                                                )
                                            })}
                                            {productList?.lenght == 0 || productList == "" ?  <p className="text-center mt-3 mb-5">{categories_page_message?.product_not_found}</p> : ""}
                                        </ul>
                                        {productList != '' && productList?.lenght != 0 &&
                                            <div className="d-flex productlistbutton mt-3 ">
                                                <button className="addButton" onClick={onClickAddLineItems}>{category_page_labels?.category_add_and_edit_page_labels?.products_block_btn_add}</button>
                                                <button className="cancelButton" onClick={onClickCloseDropdown}>{category_page_labels?.category_add_and_edit_page_labels?.products_block_btn_cancel}</button>
                                                <button className="ml-auto addButton" onClick={() => onCloseDropdown()}>{category_page_labels?.category_add_and_edit_page_labels?.products_block_btn_close}</button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div>
                                {lineItems?.map((lineItem, index) => {
                                    return (
                                        <div key={index} className="searchList">
                                            <span>{lineItem?.name}</span>
                                            <span className="removeProduct" onClick={() => onClickRemoveProduct(index, lineItem?.product_id)}><span className="mdi mdi-close"></span></span>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card card-default">
                        <div className="card-body ">
                            <div className="form-group">
                                <label className="form-label">{category_page_labels?.category_add_and_edit_page_labels?.status}</label>
                                <select className="form-control" name="status" onChange={onChangeField} value={formValues['status']}  >
                                    {status &&
                                        status?.map((optionString, index) => {
                                            const [name, value] = optionString.split(',');
                                            return <option value={name} key={index}>{value}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{category_page_labels?.category_add_and_edit_page_labels?.category_image_block_title}</h2>
                            </div>
                            <div className="card-body">
                                <ImagesPreview acceptMultipleFiles={false} featuredImage={false} userProfile={false} savedImages={savedImages} onOpen={onOpen} selectedImageInfo={selectedImages} onRemoveImageIds={onRemoveImageIds} deleteDisable={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ImageSelector Config={imageSelector} onClose={onClose} onSelectImageInfo={onSelectImages} />
            <div className="text-right mt-3">
                <button className="btn btn-primary" onClick={saveCategory}>{category_page_labels?.category_edit_page_labels?.save_btn_label}</button>
            </div>

        </React.Fragment >
    )
} 