export const CardConfig = {
  cards_settings: [
    {
      "product_type": "PERSONALIZED-CRD",
      "product_type_label": "Personalized Card",
      "getRecipients": true
    },
    {
      "product_type": "BLANK-CRD",
      "product_type_label": "Blank Card",
      "getRecipients": false,
      "quantity": true
    },
    {
      "product_type": "NON-ANIMATED-BUSINESS-CRD",
      "product_type_label": "Non Animated Business Card",
      "getRecipients": false,
      "quantity": false,
      "fields": [
        {
          "type": "input-text",
          "name": "name",
          "label": "Your name and business as it will appear on card",
          "max_char": 80,
          "mandatory": true
        },
        {
          "type": "email",
          "name": "email",
          "label": "Your email (we'll send you the ecard to distribute)",
          "mandatory": true
        },
        {
          "type": "input-textarea",
          "name": "message",
          "label": "Short message on card",
          "max_char": 140,
          "mandatory": true
        },
        {
          "type": "text",
          "name": "note",
          "note": "NOTE: If you want to add logo, email holidaycards@glwd.org with your name and logo and someone will be in touch."
        }
      ]
    },
    {
      "product_type": "NON-ANIMATED-PERSONAL-CRD",
      "product_type_label": "Non Animated Personal Card",
      "getRecipients": false,
      "quantity": false,
      "fields": [
        {
          "type": "input-text",
          "name": "name_personalization",
          "label": "Your name as it will appear on card",
          "max_char": 80,
          "mandatory": true
        },
        {
          "type": "email",
          "name": "email",
          "label": "Email",
          "mandatory": true
        }
      ]
    },
    {
      "product_type": "ANIMATED-CRD",
      "product_type_label": "Animated Card",
      "getRecipients": false,
      "quantity": false,
      "fields": [
        {
          "type": "input-text",
          "name": "name_personalization",
          "label": "Your name and business as it will appear on card",
          "max_char": 80,
          "mandatory": true
        },
        {
          "type": "email",
          "name": "email",
          "label": "Your email (we'll send you the ecard to distribute)",
          "mandatory": true
        },
        {
          "type": "input-textarea",
          "name": "message",
          "label": "Short message on card",
          "max_char": 140,
          "mandatory": true
        },
        {
          "type": "text",
          "name": "note",
          "note": "NOTE: If you want to add logo, email holidaycards@glwd.org with your name and logo and someone will be in touch."
        }
      ]
    },
    {
      "product_type": "BOX-SET-CRD",
      "product_type_label": "Box Set Cards",
      "getRecipients": false,
      "quantity": true
    }
  ]

};
