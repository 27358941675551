import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI, useStore, usePage, useUser } from "../../Utils/utils";
import Layout from "../../Components/Global/Layout";
import OrdersTable from '../../Components/OrdersForMultipleAddress/OrdersTable'
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import Button from '../../Components/Global/Button';
import Pagination from "../../Components/Global/Pagination/Pagination";
import Alert from "../../Components/Alert/Alert";
import Loader from "../../Components/Global/Loader/Loader";
import Message from "../../Components/Message/Message";
import { order_page_labels } from "../../Constants/Labels";
import { errorMessages } from "../../Constants/Errors";
import SecondaryLoader from "../.././Components/Global/SecondaryLoader/SecondaryLoader";
import { select_store_message, order_page_message } from "../../Constants/Messages";
import ExportForFulfillment from "./ExportForFulfillment";

const OrdersPage = () => {

    const { store, setStore } = useStore()
    const { page, setPage } = usePage()
    const { user, setUser } = useUser();
    const [loading, setLoading] = useState(false)
    const [onFileUploading, setOnFileUploading] = useState(false)
    const [mode, setMode] = useState('all')
    const [error, setErrors] = useState("")
    const [orders, setOrders] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [filter, setFilter] = useState()
    const [storeName, setStoreName] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [showFileUploadPopUp, setShowFileUploadPopUp] = useState(false)
    const [alert, setAlert] = useState()
    const [appliedFilter, setAppliedFilter] = useState([])
    const [searchValue, setSearchValue] = useState()
    const [popup, setPopup] = useState('')
    const [onOrderExportLoading, setOnOrderExportLoading] = useState(false);
    const [sampleFilterId, setSampleFilterId] = useState(1)
    const [fileUploadResponce, setFileUploadResponce] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [openModal,setOpenModal]= useState("")
    const [resultInfo, setResultInfo] = useState({
        total_rows: "",
        fetched_rows: "",
        total_pages: "",
        page_size: 0,
        current_page: "",
        success: true,
        errors: ""
    })
    const navigate = useNavigate()
    var storeId = store?.id

    const onPageSelect = (currentPage) => {
        setCurrentPage(currentPage)
        if (appliedFilter?.length != 0) {
            applyFilter(currentPage)
        }
    }

    useEffect(() => {
        var SavedMessage = localStorage?.getItem('Message')
        console.log("Message", SavedMessage);
        if (SavedMessage != null) {
            setAlert({ text: SavedMessage, type: "success" })
        }
    }, [])

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                setPopup('')
                setAppliedFilter([])
                setStoreName([])
                setFilter("")
            }
        }
    }, [storeName])

    //set current tab
    const onClickCurrentTab = (selectedTab) => {
        setMode(selectedTab)
        setSearchValue('')
    }

    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    //load order list
    useEffect(() => {
        if (appliedFilter?.length == 0) {
            var pay_load = {
                "store_id": storeId,
                "status": order_page_labels?.orders?.status[mode],
                "page_size": 10,
                "current_page": currentPage
            }
            getOrdersList(pay_load)
        }
    }, [mode, storeId, currentPage, appliedFilter, showFileUploadPopUp])

    //get orders list during on change the input box
    const onChangeSearchValue = (event) => {
        setSearchValue(event.target.value)
        var conditions = appliedFilter?.map(({ filter_id, ...rest }) => rest);
        var pay_load = {
            "store_id": storeId,
            "status": order_page_labels?.orders?.status[mode],
            "page_size": 10,
            "current_page": 1
        }

        if (event.target.value) {
            pay_load.filters = {
                "conditions": [
                    { "field": "o.order_id", "operator": "equals", "value": event.target.value, "dataType": "integer" }
                ]
            }
        }

        else if (!event.target.value && conditions && conditions?.length != 0) {
            pay_load.filters = {
                "conditions": conditions
            }
        }

        getOrdersList(pay_load)
    }

    const getOrdersList = (pay_load) => {

        if (pay_load.status != order_page_labels?.orders?.status[mode]) {
            pay_load.status = order_page_labels?.orders?.status[mode]
            pay_load.current_page = 1
        }

        // console.log("pay_load", pay_load)
        callAPI('/admin/orders/by-store',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                if (data.success) {
                    // console.log("search result in order list - ", data.records)
                    setOrders(data.records)
                    setResultInfo({
                        total_rows: data.total_rows,
                        fetched_rows: data.fetched_rows,
                        total_pages: data.total_pages,
                        page_size: data.page_size,
                        current_page: data.current_page,
                        success: data.success,
                        errors: data.errors
                    })
                }
                else {
                    // console.log("getting error from search result")
                }
            })

    }

    let select_store = store?.id == null || store?.id == undefined || store?.id == 0 || store?.name == "Global Admin" || store?.name == "Global" ? true : false


    const status_fliter = Object.keys(order_page_labels?.orders?.filter?.filter_values)?.map((name) => {
        const value = order_page_labels?.orders?.filter?.filter_values[name];
        return `${name},${value}`;
    });

    const filter_mode = Object.keys(order_page_labels?.orders?.filter?.filter_operation_mode)?.map((name) => {
        const mode = order_page_labels?.orders?.filter?.filter_operation_mode[name];
        return `${name},${mode}`;
    });

    const fliter_type = Object.keys(order_page_labels?.orders?.filter?.filter_operation_type)?.map((name) => {
        const type = order_page_labels?.orders?.filter?.filter_operation_type[name];
        return `${name},${type}`;
    });

    const onChangeFilterMode = (event) => {
        setFilter({ ...filter, [event.target.name]: event.target.value })
        hideErrorMessage()
    }

    const closePopup = (event) => {
        if (event == 'close') {
            setPopup('')
        }
        else {
            setPopup(event)
        }
        hideErrorMessage()
    }

    const onClickAddFilter = () => {

        if (popup == 'filter') {
            // setFilterPopup(false)

            if (filter) {
                setSampleFilterId(sampleFilterId + 1)
                var AddFilter = {}
                if (filter?.["filter_mode"] == "o.status") {

                    if (filter?.["filter_mode"] && filter?.['orderStatus']) {
                        AddFilter = {
                            "filter_id": sampleFilterId + 1,
                            "field": filter?.['filter_mode'],
                            "operator": "equals",
                            "value": filter?.['orderStatus'],
                            "dataType": "string"
                        }
                        setFilter({ filter_mode: 0, orderStatus: 0 })
                    } else {
                        setErrors("all")
                        setShowErrorMessage(true)
                    }
                }
                else if (filter?.["filter_mode"] == "o.order_date") {
                    if (filter?.['filter_mode'] && filter?.['from_date'] && filter?.["to_date"]) {
                        AddFilter = {
                            "filter_id": sampleFilterId + 1,
                            "field": filter?.['filter_mode'],
                            "operator": "between",
                            "from_date": filter?.['from_date'],
                            "to_date": filter?.["to_date"],
                            "dataType": "date"
                        }
                        setFilter({ filter_mode: 0 })
                    } else {
                        setErrors("all")
                        setShowErrorMessage(true)
                    }
                }
                else if (filter?.["filter_mode"] == "o.order_id") {
                    if (filter?.['filter_mode'] && filter?.['order_id']) {
                        const commaSeparatedNumbersPattern = /^(\d+,)*\d*$/;
                        if (commaSeparatedNumbersPattern.test(filter?.['order_id'])) {
                            var order_ids = filter?.['order_id']
                            let unique_order_ids = [...new Set(order_ids?.split(','))]?.join(',');
                            if (filter?.['order_id']?.endsWith(",")) {
                                order_ids = unique_order_ids?.slice(0, -1);
                            }
                            AddFilter = {
                                "filter_id": sampleFilterId + 1,
                                "field": filter?.['filter_mode'],
                                "operator": "in",
                                "value": unique_order_ids,
                                "dataType": "integer"
                            }
                            setFilter({ filter_mode: 0 })
                        } else {
                            setErrors("Invalid_filter_value")
                            setShowErrorMessage(true)
                        }

                    } else {
                        setErrors("all")
                        setShowErrorMessage(true)
                    }
                }
                else if (filter?.["filter_mode"] == "op.reference") {
                    if (filter?.['filter_mode'] && filter?.['reference']) {
                        AddFilter = {
                            "filter_id": sampleFilterId + 1,
                            "field": filter?.['filter_mode'],
                            "operator": "in",
                            "value": filter?.['reference'],
                            "dataType": "string"
                        }
                        setFilter({ filter_mode: 0 })
                    } else {
                        setErrors("all")
                        setShowErrorMessage(true)
                    }
                }
                else if (searchValue) {
                    AddFilter = {
                        "filter_id": sampleFilterId + 1,
                        "field": "o.order_id",
                        "operator": "equals",
                        "value": searchValue,
                        "dataType": "integer"
                    }
                    setFilter('')
                }
                else {
                    setErrors("all")
                    setShowErrorMessage(true)
                }

                if (appliedFilter.length === 0 && Object.keys(AddFilter).length !== 0) {
                    setAppliedFilter([AddFilter])
                } else if (Object.keys(AddFilter).length !== 0) {

                    const isDuplicate = appliedFilter.some((filter) => {
                        const { filter_id, ...restFilter } = filter; // Destructure filter object, excluding filter_id
                        const { filter_id: newFillter_id, ...restNewObj } = AddFilter; // Destructure newObj, excluding filter_id
                        return JSON.stringify(restFilter) === JSON.stringify(restNewObj);
                    });

                    if (isDuplicate) {
                        // console.log('The filter is already added.');
                        setErrors("filter")
                        setShowErrorMessage(true)
                    } else {
                        setAppliedFilter((prvAddFliter) => [...prvAddFliter, AddFilter]);
                    }
                }

            }
            else {
                setErrors("all")
                setShowErrorMessage(true)
            }
        }
        else {
            setPopup('filter')
        }
    }

    useEffect(() => {
        if (popup == '' && storeId && appliedFilter?.length != 0) {
            var filters = appliedFilter?.map(({ filter_id, ...rest }) => rest)
            const pay_load = {
                "store_id": storeId,
                "status": "all",
                "page_size": 10,
                "current_page": currentPage,
                ...(filters && { filters: { "conditions": filters } })
            }

            // console.log("pay_load", pay_load)
            getOrdersList(pay_load)
        }
    }, [appliedFilter])


    const applyFilter = (page_number) => {

        var Current_page_number = page_number > 1 ? page_number : 1
        // console.log("Current_page_number", Current_page_number)

        if (popup == 'filter') {
            setPopup('')
            if (!error) {
                onClickAddFilter()
            }
        }

        if (appliedFilter?.length != 0) {
            var conditions = appliedFilter?.map(({ filter_id, ...rest }) => rest);
        }

        var pay_load = {
            "store_id": storeId,
            "status": order_page_labels?.orders?.status[mode],
            "page_size": 10,
            "filters": {
                "conditions": conditions
            },
            "current_page": Current_page_number
        }

        // console.log("pay_load", pay_load)
        getOrdersList(pay_load)
    }

    const removeFilter = (id) => {
        const filteredData = appliedFilter?.filter(obj => obj?.filter_id != id);
        setAppliedFilter(filteredData)
        // console.log("filteredData", filteredData)
    }

    var divTags = appliedFilter?.map((values, index) => (

        <div className="card card-default filterMessage" key={index}>
            <span className="applied_filter_remove" onClick={() => removeFilter(values?.filter_id)}>X</span>
            {values?.field == "o.status" && <div className="filter_body">
                {order_page_labels?.orders?.filter?.filter_operation_mode?.[values?.field]}  {order_page_labels?.orders?.filter?.filter_operation_type?.[values?.operator]} {order_page_labels?.orders?.filter?.filter_values?.[values?.value]}
            </div>}
            {values?.field == "o.order_date" && <div className="filter_body">
                {order_page_message?.fillter_selected} {values?.from_date} to {values?.to_date}
            </div>}
            {values?.field == "o.order_id" && <div className="filter_body">
                {order_page_message?.order_id_in} {values?.value}
            </div>}
        </div>
    ));

    const hideErrorMessage = () => {
        if (showErrorMessage) {
            setShowErrorMessage(false)
            setErrors('')
        }
    }

    const exportData = (format) => {
        setOnOrderExportLoading(true)
        var conditions = appliedFilter?.map(({ filter_id, ...rest }) => rest);

        var pay_load = {
            "store_id": parseInt(storeId),
            "status": order_page_labels?.orders?.status[mode],
        }

        if (format) {
            pay_load.export_format = format
        }

        if (conditions && conditions?.length != 0) {
            pay_load.filters = {
                "conditions": conditions
            }
        }

        var export_format = format;
        var export_file_name;

        switch (export_format) {
            case "Fulfillment":
                export_file_name = "fulfillment_export.xlsx";
                break;
            case "LineItems":
                export_file_name = "line_items_export.xlsx";
                break;
            case "Orders":
                export_file_name = "order_export.xlsx";
                break;

        }
        // console.log("pay_load" + JSON.stringify(pay_load))
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL
        fetch(baseUrl + '/admin/data/export-orders',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                // console.log("data", data)
                if (data.constructor == Object) {
                    setLoading(false)
                    setOnOrderExportLoading(false)
                } else {
                    data.blob().then(blob => {
                        // blob.text().then( text => console.log(text))
                        setOnOrderExportLoading(false)
                        const fileSize = blob.size
                        if (fileSize > 500) {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = export_file_name
                            setLoading(false)
                            alink.click();
                        } else {
                            setLoading(false)
                            setErrors(["Error encountered while processing file"])
                        }
                    })
                }
            })
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        event.target.value = null;
        validateAndSetFile(file);
        setFileUploadResponce("")
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        event.target.value = null;
        validateAndSetFile(file);
        setFileUploadResponce("")
    };

    const validateAndSetFile = (file) => {
        if (file) {
            const fileName = file?.name?.toLowerCase();
            if (fileName?.endsWith('.csv')) {
                setSelectedFile(file);
                setErrors([])
            } else {
                // Show an error message for invalid file format
                setErrors(["Invalid file format. Please select a CSV file."]);
                // console.log("Invalid file format. Please select a csv")
                setSelectedFile(null);
            }
        }
    };

    const preventDefault = (event) => {
        event.preventDefault();
    };

    const handleClickSelectFile = () => {
        // Programmatically trigger the file input when the button is clicked
        fileInputRef.current.click();
    };

    const onUpload = () => {
        setOnFileUploading(true)
        setFileUploadResponce("")
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('store_id', store?.id)
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL

        fetch(baseUrl + '/admin/dataLoader/upload-shipping-update',
            {
                headers: { 'token': user?.auth_token },
                method: 'POST',
                body: formData
            })
            .then((response) => {
                const responseJson = response?.json()
                // console.log("data returned successfully")
                return responseJson
            })
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    setOnFileUploading(false)
                    setSelectedFile(null)
                    setFileUploadResponce(data)
                    setAlert({ text: "File uploaded successfully", type: "success" })
                } else {
                    setErrors(data?.errors || []);
                    setOnFileUploading(false)
                    setSelectedFile(null)
                    setAlert({ text: "Error occurred in file upload", type: "error" })
                    // console.log('res', data?.errors);
                }
            })
    }

    const onShowFileUploadPopUp = () => {
        setShowFileUploadPopUp(true)
        setFileUploadResponce("")
    }

    const onHideFileUploadPopUp = () => {
        setShowFileUploadPopUp(false)
        setFileUploadResponce("")
    }

    return (
        <Layout>

            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <div className="d-flex flex-column">
                    <BreadCrumb />
                </div>

                <div>
                    {onOrderExportLoading && <div className="position-relative">
                        <div className="nav-text"><SecondaryLoader className={"order_loader_top"} /></div>
                    </div>}
                    {select_store ? '' : <button className={'btn delectBtn mr-1 ml-1'} onClick={() => onShowFileUploadPopUp()} > {order_page_labels?.orders?.upload_shipping_status}</button>}
                    {select_store ? '' : <button className={'btn delectBtn mr-1'} onClick={() => closePopup('Fulfillment')} > {order_page_labels?.orders?.fulfillment?.title}</button>}
                    {select_store ? '' : <button className={'btn delectBtn mr-1'} onClick={() => exportData('LineItems')} > {order_page_labels?.orders?.export_line_item_btn}</button>}
                    {select_store ? '' : <button className={'btn delectBtn mr-1'} onClick={() => exportData('Orders')} > {order_page_labels?.orders?.export_btn}</button>}
                    {select_store ? '' : <button className={'btn delectBtn mr-1'} onClick={() => closePopup('filter')} > {order_page_labels?.orders?.filter_btn} </button>}
                    {select_store ? '' : <Button className={'btn btn-primary'} value={order_page_labels?.orders?.add_order} onClick={() => navigate('/admin/order/new')} />}
                </div>
            </div >

            {alert?.text == order_page_message.delete_message ?
                <div className="text-center" style={{ position: "relative" }}>
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}
            {popup!='' && <div className="card card-default">
                <div className={`filter_popup card card-default p-5 ${appliedFilter.length == 0 ? "filter_popup_height" : ""}`}>
                    <span className="close_fillter" onClick={() => closePopup('close')}>X</span>
                    <h3 className=" text-center">{order_page_labels?.orders?.[popup.toLocaleLowerCase()]?.title}</h3>
                    <Message type="errorMsg" text={errorMessages[error]} showMessage={showErrorMessage} style={{ position: "absolute", right: "44%", top: "55px" }} />
                    {popup=="filter"?<>
                    <div className="filter_dropDown_wrapper">
                        <div className="filter_operation_mode">
                            <label className="form-label mandatory_field">{order_page_labels?.orders?.filter?.filter_field}</label>
                            <select className="form-control mb-1" name="filter_mode" value={filter?.["filter_mode"] || ""} onChange={onChangeFilterMode}>
                                <option value={0} hidden >Select</option>
                                {filter_mode &&
                                    filter_mode?.map((optionString) => {
                                        const [name, mode] = optionString.split(',');
                                        return <option value={name} key={name}>{mode}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div className="filter_operation_mode">
                            <label className="form-label mandatory_field">{order_page_labels?.orders?.filter?.filter_type}</label>
                            {filter?.["filter_mode"] ?
                                <select className="form-control">
                                    {filter["filter_mode"] == "o.status" ? <option value={0} >{order_page_labels?.orders?.filter?.filter_operation_type["equals"]}</option> : ""}
                                    {filter["filter_mode"] == "o.order_date" && <option value={0} >{order_page_labels?.orders?.filter?.filter_operation_type["between"]}</option>}
                                    {filter["filter_mode"] == "o.order_id" || filter["filter_mode"] == "op.reference" ? <option value={0} >{order_page_labels?.orders?.filter?.filter_operation_type["in"]}</option> : ""}
                                </select>
                                :
                                <select className="form-control" name="fliter_type" value={filter?.["fliter_type"] || ""} onChange={onChangeFilterMode}>
                                    <option value="0" hidden >Select</option>
                                    {fliter_type &&
                                        fliter_type?.map((optionString) => {
                                            const [name, value] = optionString.split(',');
                                            return <option value={name} key={name}>{value}</option>;
                                        })}
                                </select>
                            }
                        </div>
                        <div className="filter_operation_mode">
                            {!filter?.["filter_mode"] || filter?.["filter_mode"] == "o.order_id" || filter?.["filter_mode"] == "op.reference" ? <label className="form-label mandatory_field">{order_page_labels?.orders?.filter?.filter_value}</label> : ""}
                            {filter?.["filter_mode"] == "o.order_date" &&
                                <div className="from_and_to_date  mr-5">
                                    <div className="filter_dateWrapper">
                                        <label className="form-label mandatory_field">{order_page_labels?.orders?.filter?.from_date}</label>
                                        <input type="date" className="form-control fillter_from_date" name="from_date" value={filter?.["from_date"] || ""} max={filter?.["to_date"]} onChange={onChangeFilterMode} />
                                    </div>
                                    <div className="filter_dateWrapper">
                                        <label className="form-label mandatory_field">{order_page_labels?.orders?.filter?.to_date}</label>
                                        <input type="date" className="form-control fillter_to_date" name="to_date" value={filter?.["to_date"] || ""} min={filter?.["from_date"]} onChange={onChangeFilterMode} />
                                    </div>
                                </div>
                            }


                            {filter?.["filter_mode"] == "o.status" && <label className="form-label mandatory_field">{order_page_labels?.orders?.filter?.order_status}</label>}
                            {
                                filter?.["filter_mode"] != "o.order_date" && filter?.["filter_mode"] != "o.order_id" && filter?.["filter_mode"] !== "op.reference" ? (
                                    <select className="form-control" name="orderStatus" value={filter?.["orderStatus"]} onChange={onChangeFilterMode}>
                                        <option value={0} hidden>Select</option>
                                        {status_fliter &&
                                            status_fliter?.map((optionString) => {
                                                const [name, value] = optionString.split(',');
                                                return <option value={name} key={name}>{value}</option>;
                                            })}
                                    </select>
                                ) : (
                                    <>
                                        {filter?.["filter_mode"] == "o.order_id" || filter?.["filter_mode"] == "op.reference" ?
                                            <div className="mb-0 p-0">
                                                <span className="mb-0 p-0">Order ids separated by ,</span>
                                                <textarea type="text" className="form-control mb-0 p-0" rows="5" cols="50" name={filter?.["filter_mode"] == "o.order_id" ? "order_id" : "reference"} maxLength="500" onChange={onChangeFilterMode} value={filter?.["filter_mode"] == "o.order_id" ? filter["order_id"] : filter["reference"] || ""} max={500}
                                                    onKeyDown={(e) => {
                                                        const allowedCharacters = /[0-9,]/; // Regular expression to allow only numbers and commas
                                                        if (!allowedCharacters.test(e.key) && e.key != "Backspace" && !e.ctrlKey) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onPaste={(e) => {
                                                        const clipboardData = e.clipboardData.getData("text/plain");
                                                        const allowedCharacters = /^[0-9,]+$/; // Regular expression to check if the clipboard data contains only numbers and commas

                                                        if (!allowedCharacters.test(clipboardData)) {
                                                            e.preventDefault();
                                                        }

                                                    }} />
                                            </div>
                                            : ""}
                                    </>
                                )
                            }


                        </div>
                        <div className={`fliter_operation_mode_btn ${filter?.["filter_mode"] == "o.order_date" ? "filter_btn_with_date" : ""}`}>
                            <button className="btn btn-primary" onClick={onClickAddFilter} disabled={filter ? false : true}> {order_page_labels?.orders?.filter?.add_filter_btn} </button>
                            <button className="btn btn-primary" onClick={applyFilter} disabled={appliedFilter?.length == 0 ? true : false}> {order_page_labels?.orders?.filter?.done_btn}</button>
                        </div>
                    </div>
                    {appliedFilter?.length != 0 &&
                        <div className="fillter_message_Wrapper">
                            {divTags}
                        </div>
                    }</>:popup=='Fulfillment'?<>
                    <ExportForFulfillment storeId={storeId} user={user} status={mode} setErrors={setErrors} setOnOrderExportLoading={setOnOrderExportLoading} setPopup={setPopup}/>
                    </>:null
                    }
                </div>
            </div>}

            {showFileUploadPopUp && <div className="card card-default">

                <div className={`filter_popup card card-default `}>
                    {alert?.text == "Error occurred in file upload" || alert?.text == "File uploaded successfully" ?
                        <div className="text-center">
                            <Alert text={alert.text} type={alert.type} setAlert={setAlert} style={{ top: "15px", left: "50px" }} />
                        </div>
                        : ''}
                    <span className="close_fillter_file_upload" onClick={() => onHideFileUploadPopUp()}>X</span>
                    <div className="p-3">
                        <div className="file-upload">
                            <>
                                <input
                                    type="file"
                                    name="file"
                                    accept=".csv"
                                    disabled={selectedFile == null ? false : true}
                                    onChange={handleFileSelect}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                <div
                                    className={`drop-area ${selectedFile ? 'selected' : ''}`}
                                    onDrop={handleFileDrop}
                                    onDragOver={preventDefault}
                                    onClick={() => handleClickSelectFile()}
                                    style={{ padding: "17px" }}
                                >
                                    <svg
                                        height="80px"
                                        width="80px"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 184.69 184.69"
                                        xmlSpace="preserve"
                                    >
                                        <g>
                                            <g>
                                                <g>
                                                    <path
                                                        style={{ fill: '#010002' }}
                                                        d="M149.968,50.186c-8.017-14.308-23.796-22.515-40.717-19.813 C102.609,16.43,88.713,7.576,73.087,7.576c-22.117,0-40.112,17.994-40.112,40.115c0,0.913,0.036,1.854,0.118,2.834 C14.004,54.875,0,72.11,0,91.959c0,23.456,19.082,42.535,42.538,42.535h33.623v-7.025H42.538 c-19.583,0-35.509-15.929-35.509-35.509c0-17.526,13.084-32.621,30.442-35.105c0.931-0.132,1.768-0.633,2.326-1.392 c0.555-0.755,0.795-1.704,0.644-2.63c-0.297-1.904-0.447-3.582-0.447-5.139c0-18.249,14.852-33.094,33.094-33.094 c13.703,0,25.789,8.26,30.803,21.04c0.63,1.621,2.351,2.534,4.058,2.14c15.425-3.568,29.919,3.883,36.604,17.168 c0.508,1.027,1.503,1.736,2.641,1.897c17.368,2.473,30.481,17.569,30.481,35.112c0,19.58-15.937,35.509-35.52,35.509 H97.391v7.025h44.761c23.459,0,42.538-19.079,42.538-42.535C184.69,71.545,169.884,53.901,149.968,50.186z"
                                                    />
                                                </g>
                                                <g>
                                                    <path
                                                        style={{ fill: '#010002' }}
                                                        d="M108.586,90.201c1.406-1.403,1.406-3.672,0-5.075L88.541,65.078 c-0.701-0.698-1.614-1.045-2.534-1.045l-0.064,0.011c-0.018,0-0.036-0.011-0.054-0.011c-0.931,0-1.85,0.361-2.534,1.045 L63.31,85.127c-1.403,1.403-1.403,3.672,0,5.075c1.403,1.406,3.672,1.406,5.075,0L82.296,76.29v97.227 c0,1.99,1.603,3.597,3.593,3.597c1.979,0,3.59-1.607,3.59-3.597V76.165l14.033,14.036 C104.91,91.608,107.183,91.608,108.586,90.201z"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                    {!selectedFile &&
                                        <>
                                            <p className='mt-3'>Drag and drop a file here, or</p>
                                            <button className='mt-3 mb-3'>Select a File</button>
                                        </>
                                    }

                                    {selectedFile && !fileUploadResponce ?
                                        <>
                                            <div className='btnAndLoderWrapper'>
                                                <button onClick={() => onUpload()} className='btn btn-primary text-center'>Upload</button>
                                                {onFileUploading &&
                                                    <div className='fileUploadLoader'>
                                                        <SecondaryLoader />
                                                    </div>
                                                }
                                            </div>
                                            <div className='mt-3'>
                                                <p>Selected File: {selectedFile?.name}</p>
                                            </div>
                                        </> : ""

                                    }
                                    {fileUploadResponce &&
                                        <div className="d-flex">
                                            <div> Rows Uploaded : {fileUploadResponce?.rows_uploaded} </div>
                                            <div className="ml-5"> Rows Processed : {fileUploadResponce?.rows_processed}  </div>
                                        </div>
                                    }

                                </div>

                                <div className='file_upload_note mt-3'>
                                    <p className="mandatory_field">Note</p>
                                    <span>1. The upload file has to be in csv format.</span>
                                    <span>2. Please use the 'Fulfillment Export' file, add date_shipped in YYYY-MM-DD format as the last column, export to csv and then use it for upload.</span>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>}
            {
                appliedFilter?.length != 0 && popup == '' &&
                <div className="fillter_message_Wrapper">
                    {divTags}
                </div>
            }
            {
                select_store ?
                    <div className="card card-default">
                        <div className="card-body select_store">
                            <p className="text-center">{select_store_message?.select_store}</p>
                        </div>
                    </div>
                    :
                    <div className="cardWrapper card-default">
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className="dataTables_wrapper">
                                    <div className="filterTab mb-3">
                                        <div className="navAndSearchWrapper">
                                            <ul className="nav nav-tabs nav-style-border" style={{ width: "80%" }}>
                                                <li className="nav-item mr-3" onClick={() => onClickCurrentTab("all")}>
                                                    <span className={`nav-link ${mode == 'all' && appliedFilter?.length == 0 ? 'active' : ''}`} onClick={() => onClickCurrentTab("all")}>{order_page_labels?.orders?.tabs_labels?.["all"]}</span>
                                                </li>
                                                <li className="nav-item mr-3" onClick={() => onClickCurrentTab("pending")}>
                                                    <span className={`nav-link ${mode == 'pending' && appliedFilter?.length == 0 ? 'active' : ''}`} onClick={() => onClickCurrentTab("pending")}>{order_page_labels?.orders?.tabs_labels?.["pending"]}</span>
                                                </li>
                                                <li className="nav-item mr-3" onClick={() => onClickCurrentTab("confirmed")}>
                                                    <span className={`nav-link ${mode == 'confirmed' && appliedFilter?.length == 0 ? 'active' : ''}`} onClick={() => onClickCurrentTab("confirmed")}>{order_page_labels?.orders?.tabs_labels?.["confirmed"]}</span>
                                                </li>
                                                <li className="nav-item mr-3" onClick={() => onClickCurrentTab("in-process")}>
                                                    <span className={`nav-link ${mode == 'in-process' && appliedFilter?.length == 0 ? 'active' : ''}`} onClick={() => onClickCurrentTab("in-process")}>{order_page_labels?.orders?.tabs_labels?.["in-process"]}</span>
                                                </li>
                                                <li className="nav-item mr-3" onClick={() => onClickCurrentTab("partially_shipped")}>
                                                    <span className={`nav-link ${mode == 'partially_shipped' && appliedFilter?.length == 0 ? 'active' : ''}`} onClick={() => onClickCurrentTab("partially_shipped")}>{order_page_labels?.orders?.tabs_labels?.["partially_shipped"]}</span>
                                                </li>
                                                <li className="nav-item mr-3" onClick={() => onClickCurrentTab("shipped")}>
                                                    <span className={`nav-link ${mode == 'shipped' && appliedFilter?.length == 0 ? 'active' : ''}`} onClick={() => onClickCurrentTab("shipped")}>{order_page_labels?.orders?.tabs_labels?.["shipped"]}</span>
                                                </li>
                                            </ul>
                                            <div className="search-form ">
                                                <div className="input-group">
                                                    <input type="number" className="form-control"
                                                        placeholder={`${order_page_labels?.orders?.placeholder}`} value={searchValue} onChange={onChangeSearchValue} />
                                                    <span className="search_icon">
                                                        <i className="mdi mdi-magnify"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {loading &&
                                        <Loader className={"table_loader"} />
                                    }
                                    <div style={{ position: "relative" }}>
                                        <div style={{ position: "relative" }}>
                                            {/* List Orders Based on Store */}
                                            <OrdersTable mode={mode} orders={orders} loader={loading} />

                                        </div>
                                    </div>
                                    <div className="paginationWrapper">
                                        <Pagination totalRows={resultInfo.total_rows} fetchedRows={resultInfo.fetched_rows} totalPages={resultInfo.total_pages} pageSize={resultInfo.page_size} currentPage={resultInfo.current_page} onPageSelect={onPageSelect} label='orders' loader={loading} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </Layout >


    )
}
export default OrdersPage;

