import React,{useEffect} from "react"
import { useUser } from "../Utils/utils";
import LoginFrom from "../Components/Global/Login/Login";
import ForgotPassword from "../Components/Global/ForgotPassword/ForgotPassword";

const Components = {
    LoginFrom: LoginFrom,
    ForgotPassword: ForgotPassword
}

const LoggedOutLayout = ({name}) => {
    const {user, setUser} = useUser()

    useEffect(() => {
        // console.log("user in out - ",user)
    },[])

    return (
        <>
            {React.createElement(Components[name])}
        </>  
        )
    
}

export default LoggedOutLayout;