import React, { useEffect, useState } from "react";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import StoreEditFormById from "../../Components/Store/StoreEdit/StoreEditFormById";
import Alert from "../../Components/Alert/Alert";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { item_required_error_message } from "../../Constants/Messages";
import { callAPI, usePage, useUser } from "../../Utils/utils";
import Loader from "../../Components/Global/Loader/Loader";

const StoreEditPage = () => {

    var { id } = useParams();
    const { page, setPage } = usePage();
    const [loading, setLoading] = useState(false);
    const [formValues, setformValues] = useState({});
    const [refreshCount, setRefreshCount] = useState(0);
    const [alert, setAlert] = useState();
    const { user, setUser } = useUser();
    const navigate = useNavigate();

    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    // page on load get localStorage message and store in alert useState
    useEffect(() => {
        var SavedMessage = localStorage.getItem('Message')
        if (SavedMessage != null) {
            setAlert({ text: SavedMessage, type: "success" })
        }
    }, [])


    useEffect(() => {
        if (user?.role != "GL-ADM") {
            navigate("*")
        }
    }, [user])

    // on page get data from database based on store id
    useEffect(() => {
        if (id) {
            callAPI(`/admin/stores/${id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data?.success == true) {
                        setformValues(data.record);
                    } else {
                        // console.log('Error occurred. Please try again', data?.errors)
                    }
                }
            )
        }
    }, [refreshCount])

    // on update store data increase the refreshCount 
    const onUpdateStore = (Message) => {
        if (Message == "success") {
            setAlert({ text: "Store saved successfully", type: "success" })
            setRefreshCount(refreshCount + 1)
        }
        else if (Message == "error") {
            setAlert({ text: item_required_error_message, type: "error" })
        }
    }

    return (
        <Layout>

            {loading &&
                <Loader className={"page_loader"} />
            }

            {alert?.text == 'Store saved successfully' || alert?.text == item_required_error_message || alert?.text == 'Store created successfully' ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                :
                ''}
            {loading == false &&
                <>
                    <div className="breadcrumb-wrapper breadcrumb-contacts">
                        <div className="d-flex flex-column">
                            <BreadCrumb value={formValues['store_name']} />
                        </div>
                    </div>
                    {/* store form by store ID  */}

                    <StoreEditFormById onUpdateStore={onUpdateStore} />
                </>
            }
        </Layout>
    )
}

export default StoreEditPage;