import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI, useStore, useUser } from "../../../../Utils/utils";
import { errorMessages } from "../../../../Constants/Errors";
import { store_page_labels } from "../../../../Constants/Labels";
import { scrollToTop } from "../../../Global/Scroll"
import Message from "../../../Message/Message";
import Loader from "../../../Global/Loader/Loader";
import ErrorMessage from "../../../Global/ErrorMessage";
import ImageSelector from "../../../ImageSelector/ImageSelector";
import ImagesPreview from "../../../ImagesPreview/ImagesPreview";

export default function EditStoreProfile({ onUpdateStore }) {

    var { id } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const { store, setStore } = useStore();
    const [errors, setErrors] = useState();
    const [config, setConfig] = useState();
    const [storeName, setStoreName] = useState();
    const [loading, setLoading] = useState(false)
    const [countryList, SetCountryList] = useState();
    const [formValues, setformValues] = useState({});
    const [savedImages, setSavedImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([])
    const [favicon, setFavIcon] = useState([]);
    const [savedFavicon, setSavedFavicon] = useState([])
    const [modifiedFormValues, setModifiedFormValues] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName?.[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    useEffect(() => {
        if (store?.id) {
            callAPI(`/admin/stores/${store?.id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },

                }, navigate, setLoading)
                .then(res => {
                    // console.log("api response " + JSON.stringify(res))
                    if (res.success === true) {
                        setformValues(res.record);
                        if (res?.record?.logo_image != undefined) {
                            setSavedImages([{
                                "image_id": res?.record?.logo_image?.image_id,
                                "path": res?.record?.logo_image?.path,
                                "name": res?.record?.logo_image?.name
                            }])
                        }
                        if (res?.record?.fav_icon_image != undefined) {
                            // console.log("image_id", res?.record?.logo_image?.image_id)
                            setSavedFavicon([
                                {
                                    "image_id": res?.record?.fav_icon_image?.image_id,
                                    "path": res?.record?.fav_icon_image?.path,
                                    "name": res?.record?.fav_icon_image?.name
                                }
                            ])
                        }
                    } else {
                        // console.log('Error occurred. Please try again.')
                    }
                }
                )
        }
    }, [store?.id])

    // page onload get data from database and list the countrylist
    useEffect(() => {
        callAPI("/admin/address/list",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success === true) {
                    SetCountryList(data.records);
                } else {
                    // console.log('Error occurred. Please try again.')

                }
            })

    }, [store?.id])

    const saveStore = () => {

        // This enables smooth scrolling
        scrollToTop()

        var pay_load = {
            "store_id": store?.id,
            "store_name": modifiedFormValues?.store_name?.trim(),
            "sub_domain": modifiedFormValues?.sub_domain,
            "private_host": modifiedFormValues?.private_host,
            "email": modifiedFormValues?.email?.toLowerCase()?.trim(),
            'phone': modifiedFormValues?.phone
        }

        if (selectedImages == null) {
            pay_load.logo = null
        }
        else if (selectedImages?.image_id) {
            pay_load.logo = selectedImages?.image_id
        }

        if (favicon == null) {
            pay_load.fav_icon = null
        }
        else if (favicon?.image_id) {
            pay_load.fav_icon = favicon?.image_id
        }


        if (modifiedFormValues?.line_1 || modifiedFormValues?.line_1 == "" ||
            modifiedFormValues?.line_2 || modifiedFormValues?.line_2 == "" ||
            modifiedFormValues?.city || modifiedFormValues?.city == "" ||
            modifiedFormValues?.state || modifiedFormValues?.state == "" ||
            modifiedFormValues?.zip || modifiedFormValues?.zip == "" ||
            modifiedFormValues?.country || modifiedFormValues?.country == ""
        ) {
            pay_load.address = {
                "address_id": formValues?.address_id,
                "line_1": modifiedFormValues?.line_1,
                "line_2": modifiedFormValues?.line_2,
                "city": modifiedFormValues?.city,
                "state": modifiedFormValues?.state,
                "zip": modifiedFormValues?.zip,
                "country": modifiedFormValues?.country
            };
        }

        // console.log("pay_load", pay_load)

        callAPI('/admin/stores/update',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading,)

            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    onUpdateStore("success")
                } else {
                    setErrors(data.errors)
                    onUpdateStore("error")
                    setShowErrorMessage(true)
                    // console.log('data', data)
                }
            }
            )
    }

    const onChangeField = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value })
        setModifiedFormValues({ ...modifiedFormValues, [event.target.name]: event.target.value })
        setShowErrorMessage(false) // hide errors
    }

    const onOpenLogoImageSelector = (config) => {
        config.logo = true
        setConfig(config)
    }

    const onOpenFaviconImageSelector = (config) => {
        config.favicon = true
        setConfig(config)
    }

    const onClose = () => {
        setConfig(false)
    }

    const onSelectImages = (images) => {

        if (config.logo) {
            setSelectedImages(images)
        }

        if (config.favicon) {
            setFavIcon(images)
        }
    }
    const onRemoveImageIds = () => {
        setSelectedImages(null)
    }
    const onRemoveFavIconImageIds = () => {
        setFavIcon(null)
    }
    var store_profile = store_page_labels?.store_add_and_edit_page_labels

    return (
        <React.Fragment>

            {loading &&
                <Loader className={"page_loader"} />
            }

            {!loading && formValues &&
                <div className="col-lg-9">
                    <div className="card card-default">
                        <div className="card-body card-header-border-bottom">
                            <div className="form-group row mb-3 d-flex justify-content-between">
                                <div className="col-8 col-form-label">
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark  mandatory_field">{store_profile?.store_name}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="store_name" disabled onChange={onChangeField} maxLength="100" value={formValues['store_name'] || ""} />
                                            </div>
                                            <div className="errorMessage">
                                                <Message text={errorMessages[ErrorMessage({ fieldName: "store_name", errors })] || ErrorMessage({ fieldName: 'store_name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark  mandatory_field">{store_profile?.giftinggood_url}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-lg-7">
                                            <input type="text" className="form-control" name='sub_domain' disabled value={formValues['sub_domain'] || ""} maxLength="25" onChange={onChangeField} />
                                            <div className="errorMessage">
                                                <Message text={errorMessages[ErrorMessage({ fieldName: "sub_domain", errors })] || ErrorMessage({ fieldName: 'sub_domain', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark  mandatory_field">{store_profile?.private_url}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-lg-7">
                                            <input type="text" className="form-control" name='private_url' disabled value={formValues['private_url'] || ""} maxLength="50" onChange={onChangeField} />
                                            <div className="errorMessage">
                                                <Message text={errorMessages[ErrorMessage({ fieldName: "private_url", errors })] || ErrorMessage({ fieldName: "private_url", errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark  mandatory_field">{store_profile?.email}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <input type="text" className="form-control email_input" name="email" disabled onChange={onChangeField} maxLength="100" value={formValues['email'] || ""} />
                                            </div>
                                            <div className="errorMessage">
                                                <Message text={errorMessages[ErrorMessage({ fieldName: "email", errors })] || ErrorMessage({ fieldName: 'email', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark">{store_profile?.phone}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="phone" onChange={onChangeField} maxLength="20" value={formValues['phone'] || ""} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="text-dark ">{store_profile?.store_logo}</div>
                                    <ImagesPreview acceptMultipleFiles={false} featuredImage={false} userProfile={formValues['store_name']} style={{ border: "none" }} savedImages={savedImages} onOpen={onOpenLogoImageSelector} selectedImageInfo={selectedImages} onRemoveImageIds={onRemoveImageIds} deleteDisable={true} />
                                    <div className="text-dark ">{store_profile?.store_favicon}</div>
                                    <ImagesPreview acceptMultipleFiles={false} featuredImage={false} userProfile={formValues['store_name']} style={{ border: "none" }} savedImages={savedFavicon} onOpen={onOpenFaviconImageSelector} selectedImageInfo={favicon} onRemoveImageIds={onRemoveFavIconImageIds} deleteDisable={true} />
                                    <ImageSelector Config={config} onClose={onClose} onSelectImageInfo={onSelectImages} />
                                </div>
                                <div className="mb-3">
                                    <h5 className="text-dark">{store_page_labels?.address_details}</h5>
                                </div>
                                <hr className="w-100"></hr>
                                <div className="form-group row mb-3 col-lg-12">
                                    <div className=" col-10 col-form-label">
                                        <div className="row mb-3">
                                            <div className="col-4 text-dark mandatory_field">{store_profile?.line_1}</div>
                                            <div className="col-1 text-dark">:</div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="line_1" onChange={onChangeField} maxLength="200" value={formValues['line_1'] || ""} />
                                                </div>
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: "line_1", errors })] || ErrorMessage({ fieldName: 'line_1', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4 text-dark">{store_profile?.line_2}</div>
                                            <div className="col-1 text-dark">:</div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="line_2" onChange={onChangeField} maxLength="200" value={formValues['line_2'] || ""} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4 text-dark mandatory_field">{store_profile?.city}</div>
                                            <div className="col-1 text-dark">:</div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="city" onChange={onChangeField} maxLength="30" value={formValues['city'] || ""} />
                                                </div>
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: "city", errors })] || ErrorMessage({ fieldName: 'city', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4 text-dark mandatory_field">{store_profile?.state}</div>
                                            <div className="col-1 text-dark">:</div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="state" onChange={onChangeField} maxLength="30" value={formValues['state'] || ""} />
                                                </div>
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: "state", errors })] || ErrorMessage({ fieldName: 'state', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4 text-dark mandatory_field">{store_profile?.zip}</div>
                                            <div className="col-1 text-dark">:</div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="zip" onChange={onChangeField} maxLength="20" value={formValues['zip'] || ""} />
                                                </div>
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: "zip", errors })] || ErrorMessage({ fieldName: 'zip', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 text-dark mandatory_field">{store_profile?.country}</div>
                                            <div className="col-1 text-dark">:</div>
                                            <div className="col-lg-7">
                                                <div className="form-group mb-4">
                                                    <select className="form-control" name='country' value={formValues['country'] || ""} onChange={onChangeField}>
                                                        <option hidden> {store_profile?.select_country} </option>
                                                        {countryList?.map((country, index) => {
                                                            return <option value={country.country_code} key={index}> {country.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: "country", errors })] || ErrorMessage({ fieldName: 'country', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary btn-position ml-auto mb-5 mr-5" onClick={saveStore}>{store_page_labels?.save_btn}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment >
    )
} 