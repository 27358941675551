import { useContext } from "react";
import { UserContext } from "../Contexts/UserContext";
import { PageContext } from "../Contexts/PageContext";
import { StoreContext } from "../Contexts/StoreContext";

export const useUser = () => {
    var { user, setUser } = useContext(UserContext)

    if (!user) {
        //console.log("getting user from local storage")
        user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))
    }
    return { user, setUser }
}

export const useStore = () => {
    var { store, setStore } = useContext(StoreContext)
    if (!store) {
        store = localStorage.getItem('store') && JSON.parse(localStorage.getItem('store'))
    }
    return { store, setStore }
}

export const usePage = () => {
    var { page, setPage } = useContext(PageContext)
    return { page, setPage }
}

export const callAPI = (slug, APIRequestInfo, navigate, setLoading, setAuthToken, setStore) => {

    // console.log('APIRequestInfo',APIRequestInfo)
    setLoading(true)
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL
    let URL = baseUrl + slug
    // console.log('calculated URL value -' + URL)
    // console.log("APIRequestInfo - "+JSON.stringify(APIRequestInfo))
    return new Promise((resolve, reject) => {
        fetch(URL, APIRequestInfo)
            .then((response) => {
                const responseJson = response?.json()
                // console.log("data returned successfully")
                return responseJson
            })
            .then((data) => {
                // console.log("response data - " + JSON.stringify(data))
                if (data.token == 'expired') {
                    //remove token from localstorage
                    localStorage.setItem('user', null)
                    localStorage.setItem('store', null)
                    localStorage.setItem('Message', null)
                    //remove token from context
                    setAuthToken(null)
                    setStore(null)
                    //redirect to home page
                    navigate("/")
                }
                resolve(data)
                setLoading(false)

                if (data) {
                    const error = data.errors?.find(item => item.field_name == 'error' || item.message == 'unexpected_error');
                    const message = localStorage.getItem('Message');
                    if (error) {
                        localStorage.setItem('Message', 'Unexpected error happened. Please try again later');
                    }
                    else if(message == 'Unexpected error happened. Please try again later' ){
                        localStorage.setItem('Message', null);
                    }
                }


            })
            .catch((err) => {
                // console.log("caught error in fetch")
                navigate("/500-internal-server-error")
                reject(err)
            }).finally(() => {
                setLoading(false)
            })
    })
}

export const isJson=(str)=>{
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }

}