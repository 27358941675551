import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { store_page_labels } from "../../../../Constants/Labels";
import { callAPI, useStore, useUser } from "../../../../Utils/utils";
import ImagesPreview from "../../../ImagesPreview/ImagesPreview";
import Loader from "../../../Global/Loader/Loader";
import './StoreProfile.css';

export default function StoreProfile() {

    var { id } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const { store, setStore } = useStore();
    const [loading, setLoading] = useState(false)
    const [selectedStore, setselectedStore] = useState({})
    const [storeName, setStoreName] = useState();
    const [logImage, setLogoImage] = useState([])
    const [favIcon, setFavIcon] = useState([])

    useEffect(() => {
        if (store?.id) {
            callAPI(`/admin/stores/${store?.id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },

                }, navigate, setLoading)

                .then(res => {
                    // console.log("api response " + JSON.stringify(res))
                    if (res.success === true) {
                        setselectedStore(res.record);

                        const logo = (
                            res?.record?.logo_image?.image_id !== undefined &&
                            res?.record?.logo_image?.path !== undefined &&
                            res?.record?.logo_image?.name !== undefined
                        ) ? [{ "image_id": res.record.logo_image.image_id, "path": res.record.logo_image.path, "name": res.record.logo_image.name }] : [];
                        const fav_icon_image = (
                            res?.record?.fav_icon_image?.image_id !== undefined &&
                            res?.record?.fav_icon_image?.path !== undefined &&
                            res?.record?.fav_icon_image?.name !== undefined
                        ) ? [{ "image_id": res?.record?.fav_icon_image?.image_id, "path": res?.record?.fav_icon_image?.path, "name": res?.record?.fav_icon_image?.name }] : [];

                        setLogoImage(logo)
                        setFavIcon(fav_icon_image)
                    } else {
                        // console.log('Error occurred. Please try again.')

                    }
                }
                )
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName?.[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    const storeFrontUrl = process.env.REACT_APP_STORE_FRONT_URL;

    const navigateToStoreFront = () => {
        if (selectedStore) { window.open(`http://${selectedStore?.sub_domain}.${storeFrontUrl}`) }
    }

    var store_profile = store_page_labels?.store_add_and_edit_page_labels

    return (
        <React.Fragment>
            {loading &&
                <Loader className={"page_loader"} />
            }

            {!loading &&
                <div className="col-lg-9">
                    <div className="card card-default">
                        <div className="card-header card-header-border-bottom">
                            <h2>{store_page_labels?.store_profile}</h2>
                        </div>
                        <div className="card-body card-header-border-bottom">
                            <div className="form-group row mb-3">
                                <div className="col-10 col-form-label">
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark ">{store_profile?.store_name}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore.store_name}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark ">{store_profile?.giftinggood_url}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7 store_url" onClick={navigateToStoreFront}>{`https://${selectedStore?.sub_domain}.${storeFrontUrl}`}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark ">{store_profile?.private_url}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore.private_url || "-"}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark ">{store_profile?.email}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore.email}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark ">{store_profile?.status}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className={`col-7 text-capitalize  ${selectedStore.status === 'active' ? 'status-text-success' : 'status-text-danger'}`}>{selectedStore.status}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark ">{store_profile?.phone}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore?.phone || "-"}</div>
                                    </div>
                                </div>
                                <div className="col-2 disable">
                                    <div className="ImagesPreview">
                                        <div className="text-dark ">{store_profile?.store_logo}</div>
                                        <ImagesPreview acceptMultipleFiles={false} savedImages={logImage} style={{ border: "none" }} imageSelectorPopUp={true} deleteDisable={false} userProfile={selectedStore.store_name} />
                                        <div className="text-dark ">{store_profile?.store_favicon}</div>
                                        <ImagesPreview acceptMultipleFiles={false} savedImages={favIcon} style={{ border: "none" }} imageSelectorPopUp={true} deleteDisable={false} userProfile={selectedStore.store_name} />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <h5 className="text-dark">{store_page_labels?.address_details}</h5>
                            </div>
                            <hr className="w-100"></hr>
                            <div className="form-group row mb-3 col-lg-12">
                                <div className=" col-10 col-form-label">
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark">{store_profile?.line_1}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore.line_1}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark">{store_profile?.line_2}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore.line_2 || "-"}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark">{store_profile?.city}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore.city}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark">{store_profile?.state}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore.state}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark">{store_profile?.zip}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore.zip}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 text-dark">{store_profile?.country}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{selectedStore.country}</div>
                                    </div>
                                </div>
                                <div className="col-2 formPosition">
                                    <button type="button" className="btn btn-primary btn-position" onClick={() => navigate(`/admin/storeprofile/edit`)}>{store_page_labels?.edit_btn}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment >
    )
} 