import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { store_page_labels } from "../../../Constants/Labels";
import { callAPI, usePage, useUser } from "../../../Utils/utils";
import { item_required_error_message, store_page_message, alert_message_type } from "../../../Constants/Messages";
import { scrollToTop } from "../../Global/Scroll"
import Message from "../../Message/Message";
import Alert from "../../Alert/Alert";
import ErrorMessage from "../../Global/ErrorMessage"
import './StoreCreateForm.css';

const StoreCreateForm = () => {

	const navigate = useNavigate();
	const { page, setPage } = usePage();
	const { user, setUser } = useUser();
	const [alert, setAlert] = useState()
	const [errors, setErrors] = useState();
	const [loading, setLoading] = useState(false);
	const [countryList, SetCountryList] = useState();
	const [formValues, setformValues] = useState({});
	const [showErrorMessage, setShowErrorMessage] = useState(false)

	// store onchage value in state
	const onChangeField = (event) => {
		const { name, value } = event.target;
		setformValues({ ...formValues, [name]: value });
		setShowErrorMessage(false)
		setErrors([])
		const formattedValue = value.toLowerCase()
			.replace(/\s+/g, '-')
			.replace(/-+/g, '-')
			.replace(/[^a-zA-Z0-9-./_]/g, '')
			?.trim();
		if (name === 'sub_domain') {
			setformValues({ ...formValues, [name]: formattedValue });
		}

		if (name === 'store_name') {
			setformValues({ ...formValues, ["sub_domain"]: formattedValue, ["store_name"]: value });
		}

	}

	// set the loading status into context whenever loading value changes  
	useEffect(() => {
		setPage({ isLoading: loading })
	}, [loading])

	// on page load get data from data base and store in state
	useEffect(() => {
		callAPI("/admin/address/list",
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'token': user?.auth_token
				},

			}, navigate, setLoading)
			.then(data => {
				// console.log("api response " + JSON.stringify(data))
				if (data.success == true) {
					SetCountryList(data?.records);
				} else {
					// console.log('res.error', data.error)
				}
			})
	}, [])

	// here we do from validation 
	const createStore = () => {

		// This enables smooth scrolling
		scrollToTop()

		var pay_load = {
			"store_name": formValues?.store_name?.trim()  || "",
			"email": formValues?.email?.toLowerCase()?.trim()  || "",
			'phone': formValues?.phone || "",
			"sub_domain": formValues?.sub_domain || "",
			"private_host": formValues?.private_host || "",
			"status": formValues?.status || 'inactive',
			"address": {
				"line_1": formValues?.line_1 || "",
				"line_2": formValues?.line_2 || "",
				"city": formValues?.city || "",
				"state": formValues?.state || "",
				"zip": formValues?.zip || "",
				"country": formValues?.country  || "USA"
			}
		}
		// console.log("pay_load", pay_load)
		callAPI('/admin/stores/create',
			{
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'token': user?.auth_token
				},
				body: JSON.stringify(pay_load)
			}, navigate, setLoading)
			.then(data => {
				// console.log("api response " + JSON.stringify(data))
				if (data.success == true) {
					navigate(`/admin/store/edit/${data?.store_id}`)
					localStorage.setItem('Message', store_page_message?.create_message)
				} else {
					setErrors(data?.errors)
					setShowErrorMessage(true)
					setAlert({ text: item_required_error_message, type: alert_message_type?.error })
					// console.log("data?.errors", data?.errors)
				}
			}
			)
	}

	const status = Object.keys(store_page_labels?.store_add_and_edit_page_status)?.map((name) => {
		const value = store_page_labels?.store_add_and_edit_page_status[name];
		return `${name},${value}`;
	});

	const domain = process.env.REACT_APP_STORE_FRONT_URL;

	return (

		<React.Fragment>

			{alert?.text ?
				<div className="text-center">
					<Alert text={alert.text} type={alert.type} setAlert={setAlert} />
				</div>
				: ''}

			<button className="btn btn-primary saveButton" onClick={createStore}>{store_page_labels?.store_add_page_labels?.btn_label}</button>

			<div className="row">
				<div className="col-lg-8">
					<div className="card card-default">
						<div className="card-header card-header-border-bottom">
							<h2>{store_page_labels?.store_add_page_labels.title}</h2>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.store_name}</label>
										<input type="text" className="form-control" name='store_name' value={formValues['store_name'] || ""} maxLength="100" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'store_name', errors })] || ErrorMessage({ fieldName: 'store_name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.email}</label>
										<input type="email" className="form-control email_input" name='email' value={formValues['email'] || ""} maxLength="100" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'email', errors })] || ErrorMessage({ fieldName: 'email', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.giftinggood_url}</label>
										<div className="domain_wrapper">
											<div className="sub_domain_and_error_wrapper">
												<input type="text" className="form-control" name='sub_domain' value={formValues['sub_domain'] || ""} maxLength="25"onChange={onChangeField} />
											</div>
											<span className="ml-1">.{domain}</span>
										</div>
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'sub_domain', errors })] || ErrorMessage({ fieldName: 'sub_domain', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label">{store_page_labels?.store_add_and_edit_page_labels?.private_url}</label>
										<input type="text" className="form-control" name='private_host' value={formValues['private_host'] || ""} maxLength="50" onChange={onChangeField} />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label>{store_page_labels?.store_add_and_edit_page_labels?.phone}</label>
										<input type="text" className="form-control" name='phone' value={formValues['phone'] || ""} maxLength="20" onChange={onChangeField} />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.line_1}</label>
										<input type="text" className="form-control" name='line_1' value={formValues['line_1'] || ""} maxLength="200" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label">{store_page_labels?.store_add_and_edit_page_labels?.line_2}</label>
										<input type="text" className="form-control" name='line_2' value={formValues['line_2'] || ""} maxLength="200" onChange={onChangeField} />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.city}</label>
										<input type="text" className="form-control" name='city' value={formValues['city'] || ""} maxLength="30" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'city', errors })] || ErrorMessage({ fieldName: 'city', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.state}</label>
										<input type="text" className="form-control" name='state' value={formValues['state'] || ""} maxLength="30" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'state', errors })] || ErrorMessage({ fieldName: 'state', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.zip}</label>
										<input type="text" className="form-control" name='zip' value={formValues['zip'] || ""} maxLength="20" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.country}</label>
										<select className="form-control overflow-auto" name='country' value={formValues['country'] || ""} onChange={onChangeField}>
											<option hidden>{store_page_labels?.store_add_and_edit_page_labels?.select_country}</option>
											{countryList?.map((country, index) => {
												return <option value={country.country_code} key={index}> {country.name}</option>
											})}
										</select>
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="card card-default">
						<div className="card-body">
							<div className="form-group ">
								<label className="form-label">{store_page_labels?.store_add_and_edit_page_labels?.status}</label>
								<select className="form-control" name='status' value={formValues['status'] || ""} onChange={onChangeField} >
									{status &&
										status?.map((optionString) => {
											const [name, value] = optionString.split(',');
											return <option value={name} key={name}>{value}</option>;
										})
									}
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="text-right">
				<button className="btn btn-primary" onClick={createStore}>{store_page_labels?.store_add_page_labels?.btn_label}</button>
			</div>
		</React.Fragment>
	)
}

export default StoreCreateForm