import React from "react";
import { useNavigate } from "react-router-dom";
import { store_page_labels } from "../../../Constants/Labels";
import { store_page_message } from "../../../Constants/Messages";
import './StoreTable.css';

export default function StoreTable({ mode, searchResult, loader }) {

    const navigate = useNavigate()

    // onclick to navigate store edit page based on storeId
    const editstore = (id) => {
        navigate(`/admin/store/edit/${id}`)
    }

    var message = ''

    if (mode == 'all') {
        var message = store_page_message?.all
    }
    
    else if (mode == 'active') {
        var message = store_page_message?.active
    }

    else if (mode == 'inactive') {
        var message = store_page_message?.inactive
    }


    return (
        <React.Fragment>

            <div className={`${loader ? 'loderHide' : 'loderShow'}`}>
                {searchResult === '' || searchResult === null || searchResult === undefined || searchResult.length === 0 ?
                    <div className="card">
                        <div className="card-body defaultText">
                            <p className="text-center">{message}</p>
                        </div>
                    </div>
                    :
                    <div className="tableOutline">
                        {mode &&
                            searchResult !== '' &&
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th className="col-lg-3">{store_page_labels?.store_page_table_head_labels?.store_name}</th>
                                        <th className="col-lg-2">{store_page_labels?.store_page_table_head_labels?.email}</th>
                                        <th className="col-lg-2">{store_page_labels?.store_page_table_head_labels?.phone}</th>
                                        <th className="col-lg-2">{store_page_labels?.store_page_table_head_labels?.status}</th>
                                        <th className="col-lg-2">{store_page_labels?.store_page_table_head_labels?.enrolled_date}</th>
                                        <th className="col-lg-2">{store_page_labels?.store_page_table_head_labels?.action}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {searchResult?.map((store, index) => {
                                        var getEnrolledDate = store.created_at
                                        let enrolledDate = getEnrolledDate?.substring(0, 10);
                                        var checkStatus = []
                                        checkStatus.push(store.status)
                                        const status = checkStatus[0] === 'active' ? 'Active' : checkStatus[0] === 'inactive' ? 'Inactive' : '';
                                        return (
                                            <tr key={index} >
                                                <td onClick={() => editstore(store.store_id)} role="button">{store.store_name}</td>
                                                <td>{store.email}</td>
                                                <td>{store.phone}</td>
                                                <td>{status}</td>
                                                <td> {enrolledDate}</td>
                                                <td >
                                                    <span className="mdi mdi-account-edit icon" onClick={() => editstore(store.store_id)} ></span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                    </div>
                }
            </div>
        </React.Fragment>
    )
}