import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI } from "../../../Utils/utils";
import { errorMessages } from "../../../Constants/Errors";
import { password_email } from "../../../Constants/Messages";
import { forgotPassword_page_labels } from "../../../Constants/Labels";
import ErrorMessage from "../../Global/ErrorMessage";
import Message from "../../Message/Message";
import SecondaryLoader from "../SecondaryLoader/SecondaryLoader"
import './ForgotPassword.css';
import '../Login/Login.css';
import ResetPassword from "../../Account/MyProfile/ResetPassword";


const ForgotPassword = () => {

    const navigate = useNavigate();
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false)
    const [formValues, setformValues] = useState({})
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isOtpSend,setIsOtpSend]=useState(false)


    const onChangeField = (event) => {
        const updatedFormValues = {
            ...formValues,
            [event.target.name]: event.target.value.toLowerCase() // Convert to lowercase
        };
        setformValues(updatedFormValues);
        setShowErrorMessage(false);
    }
    

    //on enter search the store list
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            forgotPassword()
        }
    }

    const forgotPassword = () => {
        setIsOtpSend(true)

        callAPI('/admin/users/forgot_password',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify(formValues)
            }, navigate, setLoading)

            .then(res => {
                console.log("api response " + JSON.stringify(res))
                if (res.success == true) {
                    // localStorage.setItem('Message', password_email)
                    // navigate('/')
                }
                else {
                    setErrors(res.errors)
                    setShowErrorMessage(true)
                    // console.log('res', res.errors)
                }
            }
            )
    }

    const goBack = () => {
        navigate("/")
    }

    return (
        <>
            <div className="forgotPassword showLogin">
                <div className="signinContainer">
                    <div className="form-container">
                        <div className="image-holder"></div>
                        <div >
                            <div className='go_back_recipients position-absolute' onClick={() => goBack()}>
                                <span className="mdi mdi-arrow-left pr-2"></span>
                                <p>Back</p>
                            </div>
                        </div>
                        <div className="form card-default">
                            <div className="formWrapper">
                                <div className="ec-brand">
                                    <span title="Gifting Good">
                                        <p className="brandTitle">
                                            <img src="/assets/img/Logo.jpg" alt="Logo" />
                                        </p>
                                    </span>
                                </div>

                                
                                {!isOtpSend?
                                <>
                                <div className="card-header card-header-border-bottom">
                                    <h2 className="m-auto">{forgotPassword_page_labels?.title}</h2>
                                </div>
                                <div className="card-body">
                                    
                                    <div className="form-group mt-2">
                                        <label className="form-label mandatory_field">{forgotPassword_page_labels?.email}</label>
                                        <input type="email" className="form-control email_input" name='email' onChange={onChangeField} value={formValues['email'] || ""} onKeyDown={handleKeyDown} />
                                        <div className="errorMessage">
                                            <Message text={errorMessages[ErrorMessage({ fieldName: 'email', errors })] || ErrorMessage({ fieldName: 'email', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                    <div className="submit_btn">
                                        <button className="btn btn-primary " onClick={() => forgotPassword()}>{forgotPassword_page_labels?.btn}</button>
                                        {loading && <SecondaryLoader className={"forgotPassword_loder"} />}
                                    </div>
                                </div>
                                </>:
                                    <ResetPassword mailId={formValues.email}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hideLogin">
                <div className="loginContainer">
                    <div className="card card-default col-lg-4 m-5 shadow">
                        <div className="card card-default shadow  ">
                            <div className="card-body non_desktop_mode">
                                <span> This application is supported only on the desktop version </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ForgotPassword;