import React from "react"
import  "./Message.css"

const Message = ({type,text,showMessage,style}) => {

    var text = showMessage ? text : "" 
    
    return(
        <p className={`message ${type}`} style={style}>
            {text && text.message ? text.message : text}
        </p>
    )
}

export default  Message;