import React, { useState, useEffect } from "react"
import { callAPI, useStore, useUser } from "../../../Utils/utils";
import { useNavigate } from "react-router-dom";
import { createdMessage } from "../../../Constants/Messages";
import { errorMessages } from "../../../Constants/Errors";
import { customer_page_labels } from "../../../Constants/Labels";
import { item_required_error_message } from "../../../Constants/Messages";
import { scrollToTop } from "../../Global/Scroll"
import ErrorMessage from "../../Global/ErrorMessage"
import Alert from "../../Alert/Alert";
import Message from "../../Message/Message";

export default function AddNewCustomer() {
	const navigate = useNavigate();
	const { user, setUser } = useUser();
	const [alert, setAlert] = useState()
	const { store, setStore } = useStore();
	const [errors, setErrors] = useState();
	const [loading, setLoading] = useState(false);
	const [storeName, setStoreName] = useState([]);
	const [formValues, setformValues] = useState({});
	const [countryList, SetCountryList] = useState();
	const [showErrorMessage, setShowErrorMessage] = useState(false)
	
	var storeId = store?.id

	useEffect(() => {
		if (storeName?.length == 0 || storeName == undefined) {
			setStoreName(() => [store?.name])
		}
		else {
			setStoreName((existingStoreId) => [...existingStoreId, store?.name])
		}
	}, [store?.id])

	useEffect(() => {
		if (storeName[1] != undefined) {
			if (storeName[0] != storeName[1]) {
				navigate("/admin")
				setStoreName([])
			}
		}
	}, [storeName])

	// on page load get data from data base and store in state
	useEffect(() => {
		callAPI("/admin/address/list",
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'token': user?.auth_token
				},

			}, navigate, setLoading)

			.then(data => {
				// console.log("api response " + JSON.stringify(data))
				if (data.success === true) {
					SetCountryList(data?.records);
				}
			}
			)
	}, [store?.id])

	// store onchanged values in state
	const onChangeField = (event) => {
		setformValues({ ...formValues, [event.target.name]: event.target.value })
		setShowErrorMessage(false)
		setErrors([])
	}

	// check the required fields and Posting the data to database.
	const createCustomer = () => {

		scrollToTop()

		var pay_load = {
			"first_name": formValues?.first_name?.trim() || "",
			"last_name": formValues?.last_name?.trim() || "",
			"email": formValues?.email?.toLowerCase()?.trim() || "",
			"phone": formValues?.phone || "",
			"status": formValues?.status || "active",
			"store_id": storeId
		};

		if (formValues?.line_1 || formValues?.line_2 || formValues?.city || formValues?.state || formValues?.zip || formValues?.country) {
			pay_load.address = {
				"line_1": formValues?.line_1 || "",
				"line_2": formValues?.line_2 || "",
				"city": formValues?.city || "",
				"state": formValues?.state || "",
				"zip": formValues?.zip || "",
				"country": formValues?.country || "USA",
			};
		}

		callAPI('/admin/customers/create',
			{
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'token': user?.auth_token
				},
				body: JSON.stringify(pay_load)
			}, navigate, setLoading, setStore)
			.then(data => {
				// console.log("api response " + JSON.stringify(data))
				if (data.success === true) {
					navigate(`/admin/customer/edit/${data?.customer_id}`)
					localStorage.setItem('Message', 'Customer' + " " + createdMessage)
				} else {
					setErrors(data?.errors)
					setAlert({ text: item_required_error_message, type: "error" })
					setShowErrorMessage(true)
					// console.log('res', data?.errors)
				}
			}
			)
	}


	const status = Object.keys(customer_page_labels?.customer_add_and_edit_page_status)?.map((name) => {
		const value = customer_page_labels?.customer_add_and_edit_page_status[name];
		return `${name},${value}`;
	});

	return (
		<React.Fragment>

			{alert?.text ?
				<div className="text-center">
					<Alert text={alert.text} type={alert.type} setAlert={setAlert} />
				</div>
				: ''}

			<button className="btn btn-primary saveButton" onClick={createCustomer}>{customer_page_labels?.customer_add_page_labels?.btn_label}</button>

			<div className="row ">
				<div className="col-lg-8">
					<div className="card card-default">
						<div className="card-header card-header-border-bottom">
							<h2>{customer_page_labels?.customer_add_page_labels?.title}</h2>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group mb-4">
											<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels.first_name}</label>
											<input type="text" className="form-control" name='first_name' value={formValues['first_name'] || ''} maxLength="30" onChange={onChangeField} />
											<div className="errorMessage">
												<Message text={errorMessages[ErrorMessage({ fieldName: 'first_name', errors })] || ErrorMessage({ fieldName: 'first_name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-4">
											<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels.last_name}</label>
											<input type="text" className="form-control" name='last_name' value={formValues['last_name'] || ''} maxLength="30" onChange={onChangeField} />
											<div className="errorMessage">
												<Message text={errorMessages[ErrorMessage({ fieldName: 'last_name', errors })] || ErrorMessage({ fieldName: 'last_name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-4">
											<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels.email}</label>
											<input type="email" className="form-control" name='email' value={formValues['email'] || ''} maxLength="50" onChange={onChangeField} />
											<div className="errorMessage">
												<Message text={errorMessages[ErrorMessage({ fieldName: 'email', errors })] || ErrorMessage({ fieldName: 'email', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-4">
											<label className="form-label">{customer_page_labels?.customer_add_and_edit_page_labels.phone}</label>
											<input type="text" className="form-control" name='phone' value={formValues['phone'] || ''} maxLength="20" onChange={onChangeField} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card card-default mt-3">
						<div className="card-header card-header-border-bottom">
							<h2>{customer_page_labels?.customer_add_and_edit_page_labels.address}</h2>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group mb-4">
										<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels.line_1}</label>
										<input type="text" className="form-control" name='line_1' value={formValues['line_1'] || ''} maxLength="200" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-4">
										<label className="form-label">{customer_page_labels?.customer_add_and_edit_page_labels.line_2}</label>
										<input type="text" className="form-control" name='line_2' value={formValues['line_2']} maxLength="200"onChange={onChangeField} />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-4">
										<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels.city}</label>
										<input type="text" className="form-control" name='city' value={formValues['city'] || ''} maxLength="30" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'city', errors })] || ErrorMessage({ fieldName: 'city', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-4">
										<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels.state}</label>
										<input type="text" className="form-control" name='state' value={formValues['state'] || ''} maxLength="30" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'state', errors })] || ErrorMessage({ fieldName: 'state', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-4">
										<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels.zip}</label>
										<input type="text" className="form-control" name='zip' value={formValues['zip'] || ''} maxLength="20" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-4">
										<label className="form-label mandatory_field">{customer_page_labels?.customer_add_and_edit_page_labels.country}</label>
										<select className="form-control overflow-auto" name='country' value={formValues['country']} onChange={onChangeField}>
											<option hidden>{customer_page_labels?.customer_add_and_edit_page_labels.select_country}</option>
											{countryList?.map((country, index) => {
												return <option value={country.country_code} key={index}> {country.name}</option>
											})}
										</select>
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="card card-default">
						<div className="card-body">
							<div className="form-group ">
								<label className="form-label">{customer_page_labels?.customer_add_and_edit_page_labels.status}</label>
								<select className="form-control" name='status' value={formValues['status'] || ''} onChange={onChangeField} >
									{status &&
										status?.map((optionString) => {
											const [name, value] = optionString.split(',');
											return <option value={name} key={name}>{value}</option>;
										})
									}
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="text-right">
				<button className="btn btn-primary" onClick={createCustomer}>{customer_page_labels?.customer_add_page_labels?.btn_label}</button>
			</div>

		</React.Fragment>
	)
} 