import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI, useUser } from "../../../Utils/utils";
import { useParams } from "react-router-dom";
import { profile_page_labels } from "../../../Constants/Labels";
import { errorMessages } from "../../../Constants/Errors";
import ErrorMessage from "../../Global/ErrorMessage"
import Message from "../../Message/Message";
import Loader from "../../Global/Loader/Loader";

const EditMyProfile = ({ onUpdateUser }) => {

    var { id } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useUser()
    const [formValues, setformValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errors, setErrors] = useState();
    
    const onChangeField = (event) => {
        const updatedName = event.target.name?.trim(); // Remove leading and trailing spaces
        const UpdateValue = event.target.value?.trim()
        setformValues({ ...formValues, [updatedName]: UpdateValue });
        setShowErrorMessage(false);
        setErrors([]);
    }
    

    useEffect(() => {
        if (user?.id) {
            callAPI(`/admin/users/${user?.id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)

                .then(res => {
                    // console.log("api response " + JSON.stringify(res))
                    if (res.success === true) {
                        // console.log('res', res)
                        setformValues(res?.record);
                    } else {
                        // console.log('res', res.error)
                    }
                }
                )
        }
    }, [])

    const save = () => {

        const payload = {
            "user_id": id,
            "role_cd": formValues['role_cd'],
            "first_name": formValues['first_name']?.trim(),
            "last_name": formValues['last_name']?.trim(),
            "email": formValues['email']?.toLowerCase()?.trim() || "",
            "store_id": formValues['store_id'],
            "status": formValues['status']
        };

        callAPI(`/admin/users/update`, { // Make an API call to update user data
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': user?.auth_token
            },
            body: JSON.stringify(payload) // Convert payload to JSON string
        }, navigate, setLoading)
            .then(data => { // Handle the response from the API call
                if (data.success) { // If the API call is successful
                    onUpdateUser("success"); // Call the onUpdateUser function
                } else {
                    setErrors(data?.errors); // Set the errors received from the API response
                    setShowErrorMessage(true); // Show error message
                    // console.log("data",data?.errors)
                    onUpdateUser("error")
                    if (data?.errors?.length === 0) { // If there are no errors
                        onUpdateUser("success"); // Call the onUpdateUser function
                    }
                }
            });
    }

    return (
        <React.Fragment>
            {loading &&
                <Loader className={"page_loader"} />
            }
            {!loading &&
                <div className="row">
                    <div className="col-lg-8">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{profile_page_labels?.my_profile}</h2>
                            </div>
                            <div className="card-body card-header-border-bottom">
                                <div className="form-group row mb-3">
                                    <div className="col-12 col-form-label">
                                        <div className="row mb-3">
                                            <div className="col-4 text-dark mandatory_field">{profile_page_labels?.first_name}</div>
                                            <div className="col-1 text-dark">:</div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="first_name" onChange={onChangeField} maxLength="30" value={formValues['first_name'] || ''} />
                                                </div>
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'first_name', errors })] || ErrorMessage({ fieldName: 'first_name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4 text-dark mandatory_field">{profile_page_labels?.last_name}</div>
                                            <div className="col-1 text-dark">:</div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="last_name" onChange={onChangeField} maxLength="30" value={formValues['last_name'] || ''} />
                                                </div>
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'last_name', errors })] || ErrorMessage({ fieldName: 'last_name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-4 text-dark mandatory_field">{profile_page_labels?.email}</div>
                                            <div className="col-1 text-dark">:</div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <input type="text" className="form-control email_input" disabled name="email" onChange={onChangeField} value={formValues['email'] || ''} />
                                                </div>
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'email', errors })] || ErrorMessage({ fieldName: 'email', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button className=" btn btn-primary ml-2" onClick={() => save()}>{profile_page_labels?.save}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment >
    )
}

export default EditMyProfile
