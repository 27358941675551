import React, { useEffect } from "react";
import Layout from "../../Components/Global/Layout";
import { useLocation, useNavigate } from "react-router-dom";

const OrderConfirmationPage = () => {

    const navigate = useNavigate();
    const location = useLocation()
    useEffect(() => {
        // Get the current URL
        var searchParams = new URLSearchParams(location?.search);
        var order_id = localStorage.getItem('order_id')
        // Get the value of the 'orderNumber' parameter
        var orderNumber = searchParams?.get('orderNumber');

        if (orderNumber) {
            navigate(`/admin/order/edit/${orderNumber}`)
            localStorage.setItem('Message', "Payment successfull!")
        }
        else if (order_id) {
            navigate(`/admin/order/edit/${order_id}`);
            localStorage.setItem('Message', "Payment successfull!")
        }
    }, [])


    return (
        <Layout>
        </Layout>
    )
}
export default OrderConfirmationPage;

