import React, { useState, useRef } from 'react';
import { useUser } from "../../Utils/utils";
import SecondaryLoader from "../Global/SecondaryLoader/SecondaryLoader";
import Alert from '../Alert/Alert';
import './FileUpload.css';

function FileUpload({ onHideUpload, alertStyle, id }) {
    const { user, setUser } = useUser();
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [alert, setAlert] = useState()

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        event.target.value = null;
        validateAndSetFile(file);
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        event.target.value = null;
        validateAndSetFile(file);
    };

    const validateAndSetFile = (file) => {
        if (file) {
            const fileName = file?.name?.toLowerCase();
            if (fileName?.endsWith('.csv')) {
                setSelectedFile(file);
                setErrors([])
            } else {
                // Show an error message for invalid file format
                setErrors(["Invalid file format. Please select a CSV file."]);
                // console.log("Invalid file format. Please select a csv")
                setSelectedFile(null);
            }
        }
    };

    const preventDefault = (event) => {
        event.preventDefault();
    };

    const handleClickSelectFile = () => {
        // Programmatically trigger the file input when the button is clicked
        fileInputRef.current.click();
    };

    const onUpload = () => {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('customer_id', id)
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL

        fetch(baseUrl + '/admin/address/upload-recipients',
            {
                headers: { 'token': user?.auth_token },
                method: 'POST',
                body: formData
            })
            .then((response) => {
                const responseJson = response?.json()
                // console.log("data returned successfully")
                return responseJson
            })
            .then(data => {
                //console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    setLoading(false)
                    setSelectedFile(null)
                    onHideUpload({ text: "File uploaded successfully", type: "success" })
                } else {
                    setErrors(data?.errors || []);
                    setLoading(false)
                    setSelectedFile(null)
                    setAlert({ text: "Error occurred in file upload", type: "error" })
                    // console.log('res', data?.errors);
                }
            })
    }


    const handleDownload = () => {
        // Construct the path to the local Excel file
        // const excelFilePath = process.env.PUBLIC_URL + "/example.xlsx";

        // Create a virtual anchor element to trigger the download
        const link = document.createElement("a");
        link.href = "../../../assets/files/sample upload file.csv";
        link.download = "sample upload file.csv"; // Specify the desired file name for download
        link.click();
    };

    // console.log("errors", errors)
    // console.log("selectedFile", selectedFile)

    return (

        <div className="file-upload-container mt-2">
            {alert?.text ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} style={alertStyle} />
                </div>
                : ''}
            <div className='arrowAndBtnWrapper'>
                <div className='go_back_recipients' onClick={() => onHideUpload()}>
                    <span className="mdi mdi-arrow-left pr-2"></span>
                    <p>Recipients</p>

                </div>
                <div>
                    <span onClick={() => handleDownload()} className='sample_file_link'>Sample Upload File</span>
                </div>
            </div>
            <div className="file-upload">

                <>
                    <input
                        type="file"
                        name="file"
                        accept=".csv"
                        disabled={selectedFile == null ? false : true}
                        onChange={handleFileSelect}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                    />
                    <div
                        className={`drop-area ${selectedFile ? 'selected' : ''}`}
                        onDrop={handleFileDrop}
                        onDragOver={preventDefault}
                        onClick={() => handleClickSelectFile()}
                    >

                        <svg
                            height="80px"
                            width="80px"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 184.69 184.69"
                            xmlSpace="preserve"
                        >
                            <g>
                                <g>
                                    <g>
                                        <path
                                            style={{ fill: '#010002' }}
                                            d="M149.968,50.186c-8.017-14.308-23.796-22.515-40.717-19.813 C102.609,16.43,88.713,7.576,73.087,7.576c-22.117,0-40.112,17.994-40.112,40.115c0,0.913,0.036,1.854,0.118,2.834 C14.004,54.875,0,72.11,0,91.959c0,23.456,19.082,42.535,42.538,42.535h33.623v-7.025H42.538 c-19.583,0-35.509-15.929-35.509-35.509c0-17.526,13.084-32.621,30.442-35.105c0.931-0.132,1.768-0.633,2.326-1.392 c0.555-0.755,0.795-1.704,0.644-2.63c-0.297-1.904-0.447-3.582-0.447-5.139c0-18.249,14.852-33.094,33.094-33.094 c13.703,0,25.789,8.26,30.803,21.04c0.63,1.621,2.351,2.534,4.058,2.14c15.425-3.568,29.919,3.883,36.604,17.168 c0.508,1.027,1.503,1.736,2.641,1.897c17.368,2.473,30.481,17.569,30.481,35.112c0,19.58-15.937,35.509-35.52,35.509 H97.391v7.025h44.761c23.459,0,42.538-19.079,42.538-42.535C184.69,71.545,169.884,53.901,149.968,50.186z"
                                        />
                                    </g>
                                    <g>
                                        <path
                                            style={{ fill: '#010002' }}
                                            d="M108.586,90.201c1.406-1.403,1.406-3.672,0-5.075L88.541,65.078 c-0.701-0.698-1.614-1.045-2.534-1.045l-0.064,0.011c-0.018,0-0.036-0.011-0.054-0.011c-0.931,0-1.85,0.361-2.534,1.045 L63.31,85.127c-1.403,1.403-1.403,3.672,0,5.075c1.403,1.406,3.672,1.406,5.075,0L82.296,76.29v97.227 c0,1.99,1.603,3.597,3.593,3.597c1.979,0,3.59-1.607,3.59-3.597V76.165l14.033,14.036 C104.91,91.608,107.183,91.608,108.586,90.201z"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>

                        {!selectedFile &&
                            <>
                                <p className='mt-3'>Drag and drop a file here, or</p>
                                <button className='mt-3 mb-3'>Select a File</button>
                            </>
                        }

                        {selectedFile &&
                            <>
                                <div className='btnAndLoderWrapper'>
                                    <button onClick={() => onUpload()} className='btn btn-primary text-center'>Upload</button>
                                    {loading &&
                                        <div className='fileUploadLoader'>
                                            <SecondaryLoader />
                                        </div>
                                    }
                                </div>
                                <div className='mt-3'>
                                    <p>Selected File: {selectedFile?.name}</p>
                                </div>
                            </>

                        }
                        {errors && errors?.length != 0 && selectedFile == null ?
                            <>
                                {errors?.[0] == "Invalid file format. Please select a CSV file." ?
                                    <div className="errorText">{`Error : ${errors?.[0]}`}</div>
                                    :
                                    <div className={`${errors?.length < 4 ? "errorRows" : "errorRowsLimitIncrease"}`}>
                                        {
                                            errors?.map((error, index) => {
                                                return (
                                                    <>
                                                        {error?.message && <p key={index} className="errorText">{`Errors : ${error?.message}`}</p>}
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                <p className="errorText">Check the 'Note' section below and accordingly correct the data and try uploading again.</p>
                            </>
                            : ""
                        }
                    </div>

                </>

                <div className='file_upload_note mt-3'>
                    <p className="mandatory_field">Note</p>
                    <span>1. The upload file has to be in csv format.</span>
                    <span>2. All fields except 'Honorific,' 'Company,' 'Apartment, Suite, etc,' and 'Personal Message' are mandatory. </span>
                    <span>3. If Name and Address are similar between recipients then they will be considered as duplicates.</span>
                    <span>4. You can use the sample file provided for the upload.</span>
                    <span>5.'Personal Message' should not exceed 80 characters.</span>
                    <span>6. Use 3 letter country codes. Ex: USA, CAN etc..</span>
                </div>
            </div>
        </div>
    );
}

export default FileUpload;
