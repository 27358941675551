export const createdMessage = 'created successfully';
export const updatedMessage = 'Updated Successfully';
export const savedmessage = 'saved successfully';
export const email_sent_message = "Password-generated link sent to your email";
export const copyLinkMessage = "Password generation link";
export const Invalid_password_link = "Invalid session please try resetting your password again!."
export const inactive_login = {
    "store_inactv": "Your account has been blocked contact your admin",
    "store_inactv": "Your account has been blocked contact your admin"
}
export const item_required_error_message = "There are items that require your attention";
export const password_email = "Password reset initiated. Check your email for instructions.";
export const password_success = "Password reset successfully!";
export const password_reset = {
    "password_reset_message": "Password reset initiated. Check your email at",
    "instructions": "for instructions and the",
    "link": "reset link",
}

export const page_not_found = {
    "h2_content": "404",
    "h4_content": "Oops! Page not found",
    "p_content": "Sorry, the page you're looking for doesn't exist",
    "btn": "return home"
}

export const user_page_message = {
    "all": "There are no users",
    "active": "There are no active users",
    "inactive": "There are no inactive users",
    "saved_message": "User saved successfully",
    "deleted_message": "User deleted successfully",
    "create_message": "User account created. Check your email for password reset instructions.",
    "ST-ADM": "Store Admin",
    "GL-ADM": 'Global Admin',
}

export const store_page_message = {
    "all": "There are no stores",
    "active": "There are no active stores",
    "inactive": "There are no inactive stores",
    "saved_message": "Store saved successfully",
    "create_message": "Store created successfully",
    "favicon": "16x16 pixels"
}

export const product_page_message = {
    "all": "There are no product",
    "active": "There are no active product",
    "inactive": "There are no inactive product",
    "saved_message": "Product saved successfully",
    "create_message": "Product created successfully",
    "deleted_message": "Product deleted successfully",
    "category_not_found": "No Category found!",
}

export const customer_page_message = {
    "all": "There are no customer",
    "active": "There are no active customer",
    "inactive": "There are no inactive customer",
    "saved_message": "Customer saved successfully",
    "create_message": "Customer created successfully",
    "recipients": "No address is available for this customer",
    "all_orders": "There are no orders in this customer",
    "pending_orders": "There are no orders in Pending",
    "confirmed_orders": "There are no orders under in confirmed",
    "inprocess_orders": "There are no orders under in process",
    "dispatched_orders": "There are no order under dispatched",
    "shipped_orders": "There are no order under Shipped"
}

export const categories_page_message = {
    "all": "There are no categories",
    "active": "There are no active categories",
    "inactive": "There are no inactive categories",
    "saved_message": "Category saved successfully",
    "create_message": "Category created successfully",
    "product_not_found": "No Product found!",
    "deleted_message": "Category deleted successfully",
}

export const sales_and_fees_page_message = {
    "stores_not_found": "There are no stores",
    "no_transaction": "No transaction available in this date"
}

export const fees_page_message = {
    "no_fees": "No fees available ",
    "saved_message": "Fees saved successfully"
}

export const payment_gateway_page_message = {
    "saved_message": "Payment gateway saved successfully"
}

export const order_page_message = {
    "customer_not_fount": "No Customer found!",
    "product_not_fount": "No products found!",
    "searching": "Searching...",
    "select_customer": "Please select a Customer",
    "recipient_not_fount": "No recipient is available for this customer",
    "order_logs_not_found": "There are no order logs history under this order",
    "payment_history_not_found": "There are no payment history under this order",
    "donation_amount": "Do you want to add an additional donation amount?",
    // "donation_checkbox": "I would like to cover the fees associated with my donation so more of my donation goes to",
    "donation_checkbox": "I would like to cover the credit card fees associated with my order.",
    "same_as_return_address": "Is Same as return / shipping  address",
    "create_message": "Order created successfully",
    "delete_message": "Order deleted successfully",
    "recipient_error": "Please Select at least one recipient",
    "all": "There are no orders",
    "pending": "There are no orders in Pending",
    "confirmed": "There are no orders under in confirmed",
    "in-process": "There are no orders under in process",
    "dispatched": "There are no order under Shipped",
    "shipped": "There are no order under Shipped",
    "partially_shipped": "There are no order under Partially Shipped",
    "fillter_selected": "Order Date Between",
    "order_id_in": "Order Ids In",
    "logs_not_found": "There are no order logs history under  this order",
    "payment_not_found": "There are no payment history under  this order",
    "requested_send_date": "Requested send date"
}

export const select_store_message = {
    "select_store": "Please Select a Store"
}

export const home_page_message = {
    "collection_not_available": "Collections is empty"
}


export const alert_message_type = {
    "success": "success",
    "error": "error"
}

export const Popup_messages = {
    "delect_message": "Are you sure you want to delete?",
    "category_warning": "Before deleting, remove the products from the category."
}

export const recipient_salutation = {
    'Mr.': 'Mr.',
    'Ms.': 'Ms.',
    'Mrs.': 'Mrs.',
    'Mr. & Mrs': 'Mr. & Mrs',
    'Dr.': 'Dr.',
    'Dr. & Mrs': 'Dr. & Mrs',
    'Drs.': 'Drs.',
    'Dr. & Dr.': 'Dr. & Dr.'
}
