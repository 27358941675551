import React, { useState, useEffect } from "react";
import './ProgressBar.css';
import { usePage } from "../../../Utils/utils";

const ProgressBar = () => {

    const [progressPercentage, setProgressPercentage] = useState(0);
    const { page, setPage } = usePage()

    useEffect(() => {
        if (progressPercentage == 0) {
            // page.isLoading is true i values is 0 then loop was started or page.isLoading is false i value is 100 loop will be end
            var i = page?.isLoading == true ? 0 : 100;
            // console.log('i', i)
            if (progressPercentage == 0) {
                var intervalId;
                intervalId = setInterval(() => {
                    // console.log(i);
                    i += 10;
                    setProgressPercentage(i)
                    if (i >= 100) {
                        // i = 100;
                        clearInterval(intervalId);
                    }
                }, 1000);
            }
        }
    }, [page?.isLoading, progressPercentage])

    const autoIncreaseWidth = progressPercentage + '%';

    useEffect(() => {
        if (page?.isLoading == true) {
            if (progressPercentage == 100) {
                setProgressPercentage(0)
            }
        }
    }, [page?.isLoading, progressPercentage])
    
    return  <div className={`${page?.isLoading ? 'progressBarVisible' : 'progressBarHide'}`} style={{ width: autoIncreaseWidth }}></div>
}

export default ProgressBar