import React from "react";
import { useNavigate } from "react-router-dom";
import { order_page_labels } from "../../Constants/Labels";
import { order_page_message } from "../../Constants/Messages"

export default function OrdersTable({ mode, orders, loader }) {
    const navigate = useNavigate();

    // here navigate from orderList to orderView based on orderId
    const viewOrder = (id) => {
        navigate(`/admin/order/edit/${id}`, { state: { orderId: id } })
    }
   
    return (
        <React.Fragment>
            {/* <!-- CONTENT WRAPPER --> */}
            <div className={`${loader ? 'loderHide' : 'loderShow'}`}>
                {/*orders-{JSON.stringify(orders)} */}
                {orders == '' || orders == null || orders == undefined || orders?.length == 0 ?
                    <div className="card">
                        <div className="card-body defaultText">
                            <p className="text-center">{order_page_message[mode]}</p>
                        </div>
                    </div>
                    :
                    <div className="tableOutline">
                        {mode &&
                            orders != '' &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.order_id}</th>
                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.order_date}</th>
                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.customer}</th>
                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.items}</th>
                                        <th className="col-lg-2">{order_page_labels?.orders?.table_head?.amount}</th>
                                        <th className="col-lg-3">{order_page_labels?.orders?.table_head?.status}</th>
                                        <th className="col-lg-1">{order_page_labels?.orders?.table_head?.action}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {orders?.map((order, index) => {
                                        var orderDate = order.order_date?.substring(0, 10)
                                        return (
                                            <tr key={index} >
                                                <td className="hand" onClick={() => viewOrder(order?.order_id)}>{order?.order_id}</td>
                                                <td>{orderDate}</td>
                                                <td>{order?.first_name == "NULL" || order?.first_name == null || order?.first_name == "null" || order?.first_name == 0 || order?.first_name == "0" || !order?.first_name ? "" : order?.first_name} {order?.last_name == "NULL" || order?.last_name == null || order?.last_name == "null" || order?.last_name == 0 || order?.last_name == "0" || !order?.last_name ? "" : order?.last_name}</td>
                                                <td>{order?.items}</td>
                                                <td>{order?.total}</td>
                                                <td>{order_page_labels?.orders?.orders_status[order?.status]}</td>
                                                <td>
                                                    <span className="mdi mdi-account-edit icon" onClick={() => viewOrder(order?.order_id)} ></span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                    </div>
                }
            </div>

        </React.Fragment >
    )
}