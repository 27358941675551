import React from "react";
import { useNavigate } from "react-router-dom";
import { customer_page_labels } from "../../../Constants/Labels";
import { customer_page_message } from "../../../Constants/Messages";
import "./CustomerTable.css";

export default function CustomerList({ mode, searchResult, loader }) {

	const navigate = useNavigate();
	
	// here navigate from customerList to customerView based on customerId
	const editCustomer = (id) => {
		navigate(`/admin/customer/edit/${id}`)
	}

	return (
		<React.Fragment>
			<div className={`${loader ? 'loderHide' : 'loderShow'}`}>
				{searchResult === '' || searchResult === null || searchResult === undefined || searchResult.length === 0 ?
					<div className="card">
						<div className="card-body defaultText">
							<p className="text-center">{customer_page_message[mode]}</p>
						</div>
					</div>
					:
					<div className="tableOutline">
						{mode &&
							searchResult != '' &&
							<table id="responsive-data-table " className="table" >
								<thead>
									<tr>
										<th className="col-lg-3">{customer_page_labels?.customer_page_table_head_labels?.name}</th>
										<th className="col-lg-3">{customer_page_labels?.customer_page_table_head_labels?.email}</th>
										<th className="col-lg-2">{customer_page_labels?.customer_page_table_head_labels?.phone}</th>
										<th className="col-lg-2">{customer_page_labels?.customer_page_table_head_labels?.status}</th>
										<th className="col-lg-2">{customer_page_labels?.customer_page_table_head_labels?.enrolled_date}</th>
										<th className="col-lg-2">{customer_page_labels?.customer_page_table_head_labels?.action}</th>
									</tr>
								</thead>
								<tbody>
									{/* get data from config in looping method */}
									{searchResult?.map((customer, index) => {
										var getEnrolledDate = customer.created_at
										let enrolledDate = getEnrolledDate.substring(0, 10);
										const status = customer.status === 'active' ? customer.status === 'inactive' ? '' : 'Active' : 'Inactive'
										return (
											<tr key={index} >
												<td role='button' onClick={() => editCustomer(customer.customer_id)} >{customer?.first_name == "NULL" ? "": customer?.first_name} {customer?.last_name == "NULL" ? "": customer?.last_name}</td>
												<td>{customer?.email == "NULL" ? "" : customer?.email }</td>
												<td>{customer?.phone == "NULL" ? "" : customer?.phone || ""}</td>
												<td>{status}</td>
												<td>{enrolledDate}</td>
												<td >
													{/*here get customerId on onclick method  */}
													<span className="mdi mdi-account-edit icon" onClick={() => editCustomer(customer.customer_id)} ></span>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						}
					</div>
				}
			</div>

		</React.Fragment>
	)
}