import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { callAPI, useUser } from "../../../Utils/utils";
import { item_required_error_message } from "../../../Constants/Messages";
import Layout from "../../../Components/Global/Layout";
import BreadCrumb from '../../../Components/Global/BreadCrumb/BreadCrumb';
import EditStoreProfile from '../../../Components/Account/StoreProfile/EditStorePofile/EditStoreProfile';
import Alert from "../../../Components/Alert/Alert";

const EditStoreProfilePage = () => {
    var { id } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const [loading, setLoading] = useState(false)
    const [formValues, setformValues] = useState({})
    const [refreshCount, setRefreshCount] = useState(0)
    const [alert, setAlert] = useState()
    
    useEffect(() => {
        callAPI(`/admin/stores/${id}`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },

            }, navigate, setLoading)

            .then(res => {
                // console.log("api response " + JSON.stringify(res))
                if (res.success === true) {
                    setformValues(res.record);
                } else {
                    // console.log('Error occurred. Please try again.')

                }
            }
            )

    }, [refreshCount])

    const onUpdateStore = (Message) => {
        if (Message == "success") {
            setAlert({ text: "Store saved successfully", type: "success" })
            setRefreshCount(refreshCount + 1)
        }
        else if (Message == "error") {
            setAlert({ text: item_required_error_message, type: "error" })
        }
    }

    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <div className="d-flex flex-column">
                    <BreadCrumb value={formValues['store_name']} />
                </div>
            </div>
            {/* store form by store ID  */}
            {alert?.text == 'Store saved successfully' || alert?.text == item_required_error_message ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}
            <EditStoreProfile onUpdateStore={onUpdateStore} />
        </Layout>
    )
}
export default EditStoreProfilePage;

