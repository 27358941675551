import React, { useState, useEffect } from "react";
import { callAPI, usePage, useStore, useUser } from "../../../Utils/utils";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { customer_page_labels, recipients_address } from "../../../Constants/Labels";
import ErrorMessage from "../../Global/ErrorMessage"
import Message from "../../Message/Message";

export default function EditAddress({ title, size, setMode, selectedAddressId, customerId, onCompletion, onUpdateAddress }) {

    var { id, addressId } = useParams();
    const { store, setStore } = useStore();
    const { page, setPage } = usePage();
    const [formValues, setformValues] = useState({})
    const [modifiedFormValues, setModifiedFormValues] = useState()
    const [loading, setLoading] = useState(false)
    const [countryList, SetCountryList] = useState()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errors, setErrors] = useState();
    const [storeName, setStoreName] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setUser } = useUser();

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    //set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    // onChangeField values store in state
    const onChangeField = (event) => {
        setModifiedFormValues({ ...modifiedFormValues, [event.target.name]: event.target.value })
        setformValues({ ...formValues, [event.target.name]: event.target.value })
        setShowErrorMessage(false)
        setErrors([])
    }

    // onclick validate check required and post the data to database 
    const saveAddress = () => {


        location?.state?.from != 'orders' &&
            window?.scrollTo({
                top: 0,
                behavior: 'smooth' // This enables smooth scrolling
            });


        const pay_load = {
            "customer_id": id || customerId,
            "address_id": addressId || selectedAddressId,
            "salutation": modifiedFormValues?.salutation,
            "name": modifiedFormValues?.name?.trim(),
            "line_1": modifiedFormValues?.line_1,
            "line_2": modifiedFormValues?.line_2,
            "line_3": modifiedFormValues?.line_3,
            "city": modifiedFormValues?.city,
            "state": modifiedFormValues?.state,
            "zip": modifiedFormValues?.zip,
            "country": modifiedFormValues?.country,
            "personalisation": modifiedFormValues?.personalisation,
            "sender_name_on_card":modifiedFormValues?.sender_name_on_card,
            "addressee_name": modifiedFormValues?.addressee_name
        };


        // console.log("pay",pay_load)
        callAPI("/admin/address/update",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                if (data.success) {
                    if (location?.state?.from == 'orders') {
                        setMode('openRecipientList')
                        var alert = { text: "Recipient has been Updated", type: "success" }
                        onCompletion(alert)
                    }
                    else if (location?.state?.from != 'orders') {
                        onUpdateAddress("success")
                    }
                }
                else {
                    setErrors(data?.errors)
                    onUpdateAddress("error")
                    setShowErrorMessage(true)
                    // console.log('data', data?.errors)
                }
            }
            )
    }

    useEffect(() => {

        var pay_load = {
            "customer_id": id || customerId,
            "address_id": addressId || selectedAddressId,
        }
        callAPI("/admin/address/by-customer",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    // console.log("api response to get selected address list" + JSON.stringify(data.record))
                    setformValues(data.record);
                } else {
                    // console.log('Error occurred. Please try again.')
                }
            })
    }, [id, store?.id])

    // page onload get the data from database list the country names
    useEffect(() => {
        callAPI("/admin/address/list",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success === true) {
                    SetCountryList(data?.records);
                } else {
                    // console.log('data', data?.errors)
                }
            })
    }, [])

    return (
        <React.Fragment>

            <div className={`editAddress ${size}`}>

                {location?.state?.from != 'orders' && <button className="btn btn-primary saveButton" onClick={saveAddress}>{recipients_address?.edit_btn_label}</button>}

                <div className="row">
                    <div className="row mt-2" >
                        <div className="col-lg-12">
                            <div className={`card card-default ${location?.state?.from == 'orders' && "ordersCard"}`}>
                                <div className="card-header card-header-border-bottom">
                                    {location?.state?.from == 'orders' ?
                                        <div className="recipient_header_wrapper">
                                            <div className="go_back" onClick={() => setMode('openRecipientList')}>
                                                <span className="mdi mdi-arrow-left pr-2" onClick={() => setMode('openRecipientList')}></span>
                                                <h2>Back</h2>
                                            </div>
                                            <h2 className="text-center">{`${title ? title : recipients_address?.add_title}`}</h2>
                                            <button className="btn btn-primary " onClick={saveAddress}>{recipients_address?.edit_btn_label}</button>
                                        </div>
                                        :
                                        <h2>{title ? title : 'Edit Recipients'}</h2>
                                    }
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{recipients_address?.salutation}</label>
                                                <input type="text" className="form-control" name='salutation' value={formValues['salutation'] == "NULL" ? "" : formValues['salutation'] || ""} maxLength="10" onChange={onChangeField} />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group mb-4">
                                                <label className="form-label mandatory_field">{recipients_address?.name}</label>
                                                <input type="text" className="form-control" name='name' value={formValues['name'] == "NULL" ? "" : formValues['name'] || ""} maxLength="100" onChange={onChangeField} />
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'name', errors })] || ErrorMessage({ fieldName: 'name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{recipients_address?.company}</label>
                                                <input type="text" className="form-control" maxLength="150" name='line_3' value={formValues['line_3'] == "NULL" ? "" : formValues['line_3'] || ""} onChange={onChangeField} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label mandatory_field">{recipients_address.line_1}</label>
                                                <input type="text" className="form-control" name='line_1' value={formValues['line_1'] == "NULL" ? "" : formValues['line_1'] || ""} maxLength="200" onChange={onChangeField} />
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{recipients_address.line_2}</label>
                                                <input type="text" className="form-control" name='line_2' value={formValues['line_2'] == "NULL" ? "" : formValues['line_2'] || ""} maxLength="200" onChange={onChangeField} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label mandatory_field">{recipients_address.city}</label>
                                                <input type="text" className="form-control" maxLength="30" name='city' value={formValues['city'] == "NULL" ? "" : formValues['city'] || ""} onChange={onChangeField} />
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'city', errors })] || ErrorMessage({ fieldName: 'city', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label mandatory_field">{recipients_address.state}</label>
                                                <input type="text" className="form-control" maxLength="30" name='state' value={formValues['state'] == "NULL" ? "" : formValues['state'] || ""} onChange={onChangeField} />
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'state', errors })] || ErrorMessage({ fieldName: 'state', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label mandatory_field">{recipients_address.zip}</label>
                                                <input type="text" className="form-control" maxLength="20" name='zip' value={formValues['zip'] || ""} onChange={onChangeField} />
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label mandatory_field">{recipients_address?.country}</label>
                                                <select className="form-control" name='country' value={formValues['country'] || ""} onChange={onChangeField}>
                                                    <option hidden>{customer_page_labels?.customer_add_and_edit_page_labels?.select_country}</option>
                                                    {countryList?.map((country, index) => {
                                                        return <option value={country.country_code} key={index}> {country.name}</option>
                                                    })}
                                                </select>
                                                <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label>{recipients_address?.sender_name_on_card}</label>
                                                {/* <p style={{fontSize:"12px"}}>Characters remaining: {80 - (formValues['sender_name_on_card']  || '')?.length}</p> */}
                                                <input type="text" className="form-control" name='sender_name_on_card' maxLength="80" value={formValues['sender_name_on_card'] == "NULL" ? "" : formValues['sender_name_on_card'] || ""} onChange={onChangeField} />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label>{recipients_address?.personalization}</label>
                                                <p style={{fontSize:"12px"}}>Characters remaining: {80 - (formValues['personalisation']  || '')?.length}</p>
                                                <input type="text" className="form-control" name='personalisation' maxLength="80" value={formValues['personalisation'] == "NULL" ? "" : formValues['personalisation'] || ""} onChange={onChangeField} />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label>{recipients_address?.["addressee_name"]}</label>
                                                {/* <p style={{fontSize:"12px"}}>Characters remaining: {80 - (formValues['addressee_name']  || '')?.length}</p> */}
                                                <input type="text" className="form-control" name='addressee_name' maxLength="80" value={formValues['addressee_name'] == "NULL" ? "" : formValues['addressee_name'] || ""} onChange={onChangeField} />
                                            </div>
                                        </div>
                                        {/* {location?.state?.from == 'orders' &&
                                            <div className="col-lg-6">
                                                <div className="saveBtn mt-5">
                                                    <button className="btn btn-primary ml-5" onClick={saveAddress}>{recipients_address?.edit_btn_label}</button>
                                                </div>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {location?.state?.from != 'orders' &&
                <div className="text-right mt-3">
                    <button className="btn btn-primary" onClick={saveAddress}>{recipients_address?.edit_btn_label}</button>
                </div>
            }

        </React.Fragment >
    )
} 