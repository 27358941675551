import React, { useState, useEffect } from "react";
import Alert from "../../Components/Alert/Alert";
import Layout from "../../Components/Global/Layout";
import Message from "../../Components/Message/Message";
import Loader from "../../Components/Global/Loader/Loader";
import EditUser from "../../Components/Users/EditUser/EditUser";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import { useParams, useNavigate } from "react-router-dom";
import { callAPI, usePage, useUser } from "../../../src/Utils/utils";
import { item_required_error_message, user_page_message } from "../../Constants/Messages";
import { errorMessages } from "../../Constants/Errors";


const EditUserPage = () => {

    var { id } = useParams();
    const navigate = useNavigate();
    const { page, setPage } = usePage()
    const { user, setUser } = useUser();
    const [alert, setAlert] = useState()
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState()
    const [formValues, setformValues] = useState({})
    const [refreshCount, setRefreshCount] = useState(0)

    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    // page on load get localStorage message and store in alert useState
    useEffect(() => {
        var SavedMessage = localStorage?.getItem('Message')
        if (SavedMessage != null) {
            setAlert({ text: SavedMessage, type: "success" })
        }
    }, [])

    useEffect(() => {

        if (id) {
            callAPI(`/admin/users/${id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)

                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data.success === true) {
                        setformValues(data.record);
                    } else {
                        setErrors(data?.errors)
                        // console.log('errors',data?.errors)
                    }
                }
                )
        }

    }, [refreshCount, id])

    // on update user data increase the refreshCount 
    const onUpdateUser = (Message) => {

        if (Message == "success") {
            setAlert({ text: "User saved successfully", type: "success" })
            setRefreshCount(refreshCount + 1)
        }
        else if (Message == "error") {
            setAlert({ text: item_required_error_message, type: "error" })
        }
    }

    var message = alert?.text == user_page_message?.create_message ? "30px" : "50px";

    function getErrorMessage(fieldName) {
        const error = errors?.find(item => item?.field_name === fieldName);
        return error ? error.message : "Field not found";
    }

    return (
        <Layout>

            {loading &&
                <Loader className={"page_loader"} />
            }
            {!loading &&
                <>
                    <div className="breadcrumb-wrapper breadcrumb-contacts">
                        <div className="d-flex flex-column">
                            <BreadCrumb value={formValues['first_name'] + " " + formValues['last_name']} />
                        </div>
                    </div>
                    {/*Edit User Details By Id*/}
                    {alert?.text == user_page_message?.saved_message || alert?.text == item_required_error_message || alert?.text == user_page_message?.create_message ?
                        <div className="text-center">
                            <Alert text={alert.text} type={alert.type} setAlert={setAlert} style={{ top: message }} />
                        </div>
                        : ''}
                    {errors ?
                        <div className="card">
                            <div className="card-body defaultText">
                                <div className="text-center">
                                    <Message text={errorMessages[getErrorMessage("user")]} type={"normal"} showMessage={true} />
                                </div>
                            </div>
                        </div>
                        :
                        <EditUser onUpdateUser={onUpdateUser} />
                    }
                </>
            }
        </Layout>
    )
}

export default EditUserPage;

