import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./BreadCrumb.css";


const BreadCrumb = ({ value }) => {

    var { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation()
    const current_page = location?.pathname?.substring(1).split("/");

    const onSelectPage = (pageName) => {
        navigateToPage(String(pageName))
    }

    // here check condition if condition was satisfied navigate to that page
    const navigateToPage = (pageName) => {

        if (current_page[1] == 'customer' && current_page[4] == 'order' && pageName != "customer") {
            navigate(`/admin/customer/edit/${id}`)
        }

        else if (current_page[4] == 'address' && pageName != "customer") {
            navigate(`/admin/customer/edit/${id}`)
        }

        else {

            if (current_page[1] == 'store' || current_page[1] == 'store') {
                navigate('/admin/stores')
            }
            else if (current_page[1] == 'product' || current_page[1] == 'products') {
                navigate('/admin/products')
            }
            else if (current_page[1] == 'customers' || current_page[1] == 'customer' || pageName == "customer") {
                navigate('/admin/customers')
            }
            else if (current_page[1] == 'order' || current_page[1] == 'orders') {
                navigate('/admin/orders')
            }
            else if (current_page[1] == 'user' || current_page[1] == 'users') {
                navigate('/admin/users')
            }
            else if (current_page[1] == 'category' || current_page[1] == 'categorys') {
                navigate('/admin/categories')
            }
            else if (current_page[2] == 'pages') {
                return navigate(`/admin/settings/pages`)
            }
            else if (current_page[1] == 'storeprofile' || current_page[3] == 'edit') {
                return navigate(`/admin/storeprofile`)
            }
            else if (current_page[1] == 'salesandfees') {
                return navigate(`/admin/salesandfees`)
            }
            else if (current_page[1] == 'myprofile') {
                return navigate(`/admin/myprofile/${id}`)
            }

        }

    }


    const currentPage = () => {
        return current_page[1] === 'salesandfees'
            ? 'Sales And Fees'
            : current_page[1] === 'storeprofile'
                ? 'Store Profile'
                : current_page[1] === 'myprofile'
                    ? 'My Profile'
                    : current_page[1];
    };

    var profile

    if (current_page?.[1] == "myprofile" || current_page?.[1] == "storeprofile") {
        if (current_page[2] == "edit") {
            profile = true
        }
        else {
            profile = false
        }
    }
    else {
        if (current_page?.[2] == "edit" || current_page?.[2]) {
            profile = true
        }
    }

    var home_page = current_page[1] == undefined || current_page.length == 0

    var dispaly_arrow = current_page[2] == undefined || current_page[2] == id ? current_page[3]=="reset-password"?"arrow_show":"arrow_hide" : "arrow_show"

    var dispaly_arrow_1 = current_page[4] ? "arrow_show" : "arrow_hide"

    var sub_page


    if (current_page[2] != id) {
        if (current_page[1] == "customer" && current_page[4] == "order") {
            sub_page = current_page[4]
        }
        else {
            sub_page = current_page[2]
        }

    }
    else{
        if(current_page[3]=="reset-password"){
            sub_page=current_page[3]
        }
    }

    var select_page = profile == true ? "selected_text cursor_pointer" : ""

    var select_page_1 = current_page[4] ? "selected_text cursor_pointer" : ""

    var address_page

    if (current_page[4]) {
        if (current_page[4] == "address") {
            address_page = current_page[4]
        }
        else if (current_page[4] != "address") {
            if (current_page[1] == "customer" && current_page[4] == "order") {
                address_page = current_page[6]
            }
            if (current_page[1] == "setting" && current_page[2] == "page") {
                address_page = "Home"
            }
            else {
                address_page = current_page[4]
            }
        }

    }

    var pageName

    if (value && value != "undefined undefined" && value != undefined && value != "undefined") {

        if (current_page[1] == "customer" && current_page[4] == "order") {
            pageName = current_page[4]
        }
        if (current_page[1] == "order" && current_page[4] == id) {
            pageName = current_page[3]
        }

        else if (value) {
            pageName = value
        }
    }

    else if (current_page[1] == "order" && current_page[2] == "edit") {
        pageName = current_page[3]
    }

    else {
        pageName = sub_page
    }

    console.log("pageName",pageName,current_page);

    return (
        <React.Fragment>

            <div className="bread_crumb_wrapper">

                {home_page ? <span className="home_page">Home</span>
                    :
                    <>
                        <span onClick={() => navigate('/admin')} className="home_page selected_text cursor_pointer">Home</span>
                        <span className='mdi mdi-chevron-right arrow_icon' />

                        <span onClick={() => onSelectPage(currentPage())} className={`current_page ${select_page}`}>{currentPage()} </span>
                        <span className={`mdi mdi-chevron-right arrow_icon ${dispaly_arrow}`} />

                        <span onClick={() => onSelectPage(pageName)} className={`current_page ${select_page_1}`} >{pageName}</span>

                        <span className={`mdi mdi-chevron-right arrow_icon ${dispaly_arrow_1}`} />

                        <span onClick={() => onSelectPage(address_page)} className="current_page">{address_page}</span>

                    </>
                }

            </div>

        </React.Fragment >
    )
}

export default BreadCrumb;