import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { store_page_labels } from "../../Constants/Labels"
import { callAPI, usePage, useUser } from "../../Utils/utils";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import Button from '../../Components/Global/Button';
import StoreTable from '../../Components/Store/StoreTable/StoreTable'
import Pagination from "../../Components/Global/Pagination/Pagination";
import Loader from "../../Components/Global/Loader/Loader";

const StoresPage = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState('all')
    const [searchValue, setSearchValue] = useState()
    const [searchResult, setSearchResult] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [payLoad, setPayLoad] = useState()
    const { user, setUser } = useUser(0);
    const { page, setPage } = usePage()
    const [resultInfo, setResultInfo] = useState({
        total_rows: "",
        fetched_rows: "",
        total_pages: "",
        page_size: 0,
        current_page: 1,
        success: true,
        errors: ""
    })

    const onPageSelect = (currentPage) => {
        setCurrentPage(currentPage)
        onClickSearchBtn(currentPage)
    }

    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    useEffect(() => {
        if (user?.role != "GL-ADM") {
            navigate("*")
        }
    }, [user])

    useEffect(() => {

        var initial_pay_load = {
            "status": mode,
            "current_page": currentPage
        }

        var pay_load = payLoad || initial_pay_load
        // console.log("pay_load",pay_load)
        callAPI('/admin/stores/list',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)

            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    setSearchResult(data.records)
                    setResultInfo({
                        total_rows: data.total_rows,
                        fetched_rows: data.fetched_rows,
                        total_pages: data.total_pages,
                        page_size: data.page_size,
                        current_page: data.current_page,
                        success: data.success,
                        errors: data.errors
                    })
                } else {
                    // console.log('Error occurred. Please try again.')
                }
            })
    }, [mode, payLoad])
    // console.log("currentPage",currentPage)

    const onChangeSearchValue = (event) => {
        // console.log("on search value - ", event.target.value ? true : false)
        setSearchValue(event.target.value)
        if (event.target.value) {
            var pay_load = {
                "status": mode,
                "filters": {
                    "conditions": [
                        { "field": "store_name", "operator": "like", "value": event.target.value, "dataType": "string" }
                    ]
                },
                "current_page": 1
            }
        } else {
            pay_load = {
                "status": mode,
                "current_page": 1
            }
        }
        setPayLoad(pay_load)
    }

    //during on search icon clicked get the result of store
    const onClickSearchBtn = (currentPage) => {
        getSearchResult(currentPage)
    }

    //on enter search the store list
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getSearchResult()
        }
    }

    const getSearchResult = (currentPage) => {

        if (searchValue) {
            var pay_load = {
                "status": mode,
                "filters": {
                    "conditions": [
                        { "field": "store_name", "operator": "like", "value": searchValue, "dataType": "string" }
                    ]
                },
                "current_page": currentPage
            }
        } else {
            pay_load = {
                "status": mode,
                "current_page": currentPage
            }
        }
        setPayLoad(pay_load)
    }

    const updatedSearchResult = searchResult?.filter(store => store.store_name !== "Global" );
    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <BreadCrumb />
                <Button className={'btn btn-primary'} onClick={() => navigate('/admin/store/new')} value={'Add Store'} />
            </div>

            <div className="cardWrapper card-default">
                <div className="card-body" >
                    <div className="table-responsive" >
                        <div className="dataTables_wrapper" >
                            <div className="filterTab mb-3">
                                <div className="navAndSearchWrapper">
                                    <ul className="nav nav-tabs nav-style-border w-75">
                                        <li className="nav-item" onClick={() => setMode('all')}>
                                            <span className={`nav-link ${mode == 'all' ? 'active' : ''}`}>{store_page_labels?.store_page_tab_labels?.all}</span>
                                        </li>
                                        <li className="nav-item" onClick={() => setMode('active')}>
                                            <span className={`nav-link ${mode == 'active' ? 'active' : ''}`}>{store_page_labels?.store_page_tab_labels?.active}</span>
                                        </li>
                                        <li className="nav-item" onClick={() => setMode('inactive')}>
                                            <span className={`nav-link ${mode == 'inactive' ? 'active' : ''}`}>{store_page_labels?.store_page_tab_labels?.inactive}</span>
                                        </li>
                                    </ul>

                                    <div className="search-form">
                                        <div className="input-group ">
                                            <input type="text" className="form-control"
                                                placeholder="Search by Store Name" onChange={onChangeSearchValue} onKeyDown={handleKeyDown} />
                                            <span className="search_icon">
                                                <i className="mdi mdi-magnify"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {loading &&
                                <Loader className={"table_loader"} />
                            }

                            {/* list store data in storeTable */}
                            <StoreTable mode={mode} searchResult={updatedSearchResult} loader={loading} />
                            <div className="paginationWrapper">
                                <Pagination totalRows={resultInfo?.total_rows - 1} fetchedRows={resultInfo?.fetched_rows - 1} totalPages={resultInfo?.total_pages} pageSize={resultInfo?.page_size} currentPage={resultInfo?.current_page} onPageSelect={onPageSelect} label='stores' loader={loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default StoresPage;

