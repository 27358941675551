import React from "react";
import { useNavigate } from "react-router-dom";
import { user_page_message } from "../../Constants/Messages"
import { user_page_labels } from "../../Constants/Labels"

export default function UsersList({ mode, searchResult, loader }) {

    const navigate = useNavigate();
    const userEdit = (id) => {
        navigate(`/admin/user/edit/${id}`)
    }

    return (
        <React.Fragment>
            <div className={`${loader ? 'loderHide' : 'loderShow'}`}>
                {searchResult === '' || searchResult === null || searchResult === undefined || searchResult.length === 0 ?
                    <div className="card">
                        <div className="card-body defaultText">
                            <p className="text-center">{user_page_message[mode]}</p>
                        </div>
                    </div>
                    :
                    <div className={`${loader ? 'loderHide' : 'loderShow'} tableOutline`}>
                        {mode &&
                            searchResult !== '' &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="col-lg-2">{user_page_labels?.user_page_table_head_labels?.name}</th>
                                        <th className="col-lg-3">{user_page_labels?.user_page_table_head_labels?.email}</th>
                                        <th className="col-lg-2">{user_page_labels?.user_page_table_head_labels?.role}</th>
                                        <th className="col-lg-2">{user_page_labels?.user_page_table_head_labels?.status}</th>
                                        <th className="col-lg-2">{user_page_labels?.user_page_table_head_labels?.enrolled_date}</th>
                                        <th className="col-lg-1">{user_page_labels?.user_page_table_head_labels?.action}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {searchResult?.map((user, index) => {

                                        let getenrolledDate = String(user.created_at);
                                        let enrolledDate = getenrolledDate.substring(0, 10);
                                        var checkStatus = []
                                        checkStatus.push(user.status)
                                        const status = checkStatus[0] === 'active' ? 'Active' : checkStatus[0] === 'inactive' ? 'Inactive' : '';

                                        return (
                                            < tr key={index} >
                                                <td role='button' onClick={() => userEdit(user.user_id)}>{user.first_name} {user.last_name}</td>
                                                <td>{user.email}</td>
                                                <td>{user_page_message[user?.role_cd]}</td>
                                                <td>{status}</td>
                                                <td>{enrolledDate}</td>
                                                <td >
                                                    <span className="mdi  mdi-account-edit  icon" onClick={() => userEdit(user.user_id)} ></span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                    </div>
                }
            </div>
        </React.Fragment >
    )
}