import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors"
import { scrollToTop } from "../../../Components/Global/Scroll";
import { callAPI, useStore, useUser } from "../../../Utils/utils";
import { sales_and_fees_page_labels } from "../../../Constants/Labels";
import moment from "moment";
import Loader from "../../Global/Loader/Loader";
import Message from "../../Message/Message";
import Pagination from "../../Global/Pagination/Pagination";
import BreadCrumb from '../../../Components/Global/BreadCrumb/BreadCrumb';
import ErrorMessage from "../../Global/ErrorMessage";

const SalesAndFeesStore = () => {

    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const { store, setStore } = useStore()
    const [errors, setErrors] = useState();
    const [payLoad, setPayLoad] = useState();
    const [loading, setLoading] = useState(false);
    const [formValues, setformValues] = useState({});
    const [totalSales, setTotalSales] = useState()
    const [storeSalesInfo, setStoreSalesInfo] = useState()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [resultInfo, setResultInfo] = useState({
        total_rows: "",
        fetched_rows: "",
        total_pages: "",
        page_size: 0,
        current_page: "",
        success: true,
        errors: ""
    })

    var store_id = store?.id
    const navigateToOrderPage = (id, date) => {
        navigate(`/admin/salesandfees/byorders/${id}/${date}`)
    }

    useEffect(() => {
        setLoading(true)
        callAPI("/admin/fees/by-store",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    setTotalSales(data?.records)
                    setLoading(false)
                    setStoreSalesInfo(data)
                    setResultInfo({
                        total_rows: data.total_rows,
                        fetched_rows: data.fetched_rows,
                        total_pages: data.total_pages,
                        page_size: data.page_size,
                        current_page: data.current_page,
                        success: data.success,
                        errors: data.errors
                    })
                } else {
                    setErrors(data.errors)
                    setLoading(false)
                    // console.log('data.error', data.errors)
                }
            })
    }, [payLoad, store_id])
    // console.log("payLoad", payLoad)

    useEffect(() => {
        if (formValues?.time_window != "custom") {
            setPayLoad({
                "store_id": store_id,
                "time_window": {
                    "window_type": formValues?.time_window || "this-week"
                }
            })
        }

    }, [formValues, store_id])

    // store onchage value in state
    const onChangeField = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value })
        setShowErrorMessage(false)
        setErrors([])
    }

    const onSelectedDate = () => {
        setShowErrorMessage(true)
        const time_window = {
            "window_type": "custom",
            "from_date": formValues["from_date"] ? moment(formValues["from_date"]).format("YYYY-MM-DD") : "",
            "to_date": formValues["to_date"] ? moment(formValues["to_date"]).format("YYYY-MM-DD") : ""
        };

        setPayLoad({
            "time_window": time_window,
            "store_id": store?.id,
        });
    }

    const onPageSelect = (currentPage) => {

        // This enables smooth scrolling
        scrollToTop()
        var pay_load = {
            "store_id": store_id,
            "time_window": payLoad?.time_window,
            "page_size": 10,
            "current_page": currentPage
        }
        setPayLoad(pay_load)
    }

    var order_count = storeSalesInfo?.total_fees?.order_count || 0
    var sales_count = storeSalesInfo?.total_fees?.total_sales == null ? 0 : storeSalesInfo?.total_fees?.total_sales;
    var fees_amount = storeSalesInfo?.total_fees?.total_fees == null ? 0 : storeSalesInfo?.total_fees?.total_fees;
    var net_amount = storeSalesInfo?.total_fees?.net_sales == null ? 0 : storeSalesInfo?.total_fees?.net_sales;

    // console.log("totalSales", totalSales)
    // console.log("loading",loading)

    return (
        <>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <div className="d-flex flex-column">
                    <BreadCrumb />
                </div>
                <div className="date_and_dropdown_wrapper">
                    {formValues?.time_window == "custom" &&
                        <div className=" custome_address_wrapper">
                            <div className="custome_address">
                                <div className="from_date">
                                    <label className="form-label mandatory_field">{sales_and_fees_page_labels?.sales_and_fees_page_custom?.from_date}</label>
                                    <input type="date" placeholder="from" className="form-control" name='from_date' max={formValues['to_date']} value={formValues['from_date'] || ''} onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'from_date', errors }) || ErrorMessage({ fieldName: 'from_date', errors })]} type={"errorMsg"} style={{ marginTop: "17px" }} showMessage={showErrorMessage} />
                                    </div>
                                </div>

                                <div className="to_date">
                                    <label className="form-label mandatory_field">{sales_and_fees_page_labels?.sales_and_fees_page_custom?.to_date}</label>
                                    <input type="date" className="form-control" name='to_date' placeholder="To date" min={formValues['from_date']} value={formValues['to_date'] || ''} onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'to_date', errors })] || ErrorMessage({ fieldName: 'to_date', errors })} type={"errorMsg"} style={{ marginTop: "17px" }} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                                <div className="apply_btn">
                                    <button className="btn btn-primary" onClick={onSelectedDate}>{sales_and_fees_page_labels?.sales_and_fees_page_custom?.btn_label}</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="drop_down">
                        <select className="form-control" name='time_window' onChange={onChangeField} value={formValues['time_window'] || "this-week"} >
                            {sales_and_fees_page_labels?.sales_and_fees_options?.map((option, index) => (
                                <option key={index} value={option?.value}>{option?.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-12 d-flex flex-wrap salesOverviewCard">
                <div className="col-lg-2 card card-body">
                    <span>{sales_and_fees_page_labels?.sales_and_fees_page_container_labels?.orders} : {order_count}</span>
                </div>
                <div className="col-lg-2 card card-body">
                    <span>{sales_and_fees_page_labels?.sales_and_fees_page_container_labels?.net_sales} : {sales_count}</span>
                </div>
                <div className="col-lg-2 card card-body">
                    <span> {sales_and_fees_page_labels?.sales_and_fees_page_container_labels?.fees} : {fees_amount}</span>
                </div>
                <div className="col-lg-2 card card-body">
                    <span> {sales_and_fees_page_labels?.sales_and_fees_page_container_labels?.net} : {net_amount}</span>
                </div>
            </div>


            <div className=" cardWrapper card-default mt-5">
                <div className="card-body " >

                    {loading && !totalSales ?
                        <Loader className={"table_loader"} /> : ""
                    }

                    <div className="table-responsive tableOutline" >
                        {!loading ?
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th className="col-lg-3">{sales_and_fees_page_labels?.store_sales_and_fees_page_table_head?.transaction_date}</th>
                                        <th className="col-lg-3">{sales_and_fees_page_labels?.store_sales_and_fees_page_table_head?.net_sales}</th>
                                        <th className="col-lg-3">{sales_and_fees_page_labels?.store_sales_and_fees_page_table_head?.fees}</th>
                                        <th className="col-lg-3">{sales_and_fees_page_labels?.store_sales_and_fees_page_table_head?.net}</th>
                                        <th className="col-lg-1">{sales_and_fees_page_labels?.store_sales_and_fees_page_table_head?.action}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {totalSales && totalSales?.map((fees, index) => {
                                        return (
                                            <tr key={index} >
                                                <td role="button" onClick={() => navigateToOrderPage(store_id, fees?.date)}>{fees?.date}</td>
                                                <td>{fees?.sales}</td>
                                                <td> {fees?.fees}</td>
                                                <td> {fees?.net}</td>
                                                <td >
                                                    <span className="mdi mdi-eye-outline icon" onClick={() => navigateToOrderPage(store_id, fees?.date)}></span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> 
                        : ""}
                    </div>
                    {!loading && totalSales ?
                        <div className="paginationWrapper">
                            <Pagination totalRows={resultInfo.total_rows} fetchedRows={resultInfo.fetched_rows} totalPages={resultInfo.total_pages} pageSize={resultInfo.page_size} currentPage={resultInfo.current_page} onPageSelect={onPageSelect} label={sales_and_fees_page_labels?.store_sales_and_fees_page_pagination_label} loader={loading} />
                        </div> : ""}
                </div>
            </div>


        </>
    )
}
export default SalesAndFeesStore;