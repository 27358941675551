import React from 'react';
import "./PageNotFound.css";
import { useNavigate } from 'react-router-dom';
import { page_not_found } from "../../../Constants/Messages"

const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <div className='page_note_found'>
            <div id="error-page">
                <div className="content">
                    <h2 className="header">
                        {page_not_found?.h2_content}
                    </h2>
                    <h4>
                        {page_not_found?.h4_content}
                    </h4>
                    <p>
                        {page_not_found?.p_content}
                    </p>
                    <div className="btns">
                        <button onClick={() => navigate("/admin")}>{page_not_found?.btn}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
