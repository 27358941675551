import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI, useStore, useUser } from "../../Utils/utils";
import { useLocation } from "react-router-dom";
import { header_labels } from "../../Constants/Labels";
import PageTitle from "../Global/PageTitle/PageTitle";
import ProgressBar from "../Global/ProgressBar/ProgressBar";
import Logo from "../Logo/Logo"
import "./Header.css";

export default function Header({ globalHide }) {

    const navigate = useNavigate();
    const [showUser, setShowUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [storeData, setStoreData] = useState()
    const [storeListData, setStoreListData] = useState();
    const [formValues, setformValues] = useState({});
    const { store, setStore } = useStore();
    const { user, setUser } = useUser();

    const location = useLocation()
    const current_page = location?.pathname?.substring(1).split("/")
    const userName = user?.first_name + " " + user?.last_name;

    useEffect(() => {
        if (current_page[1] == 'stores' || current_page[1] == 'store') {
            if (user?.role != "ST-ADM"){
            setStore({ id: 0, name: "Global" })
            localStorage.setItem('store', JSON.stringify({ id: 0, name: "Global" }))
            }
        }
    }, [])

    useEffect(() => {
        callAPI('/admin/stores/list',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(
                    {
                        "status": "active",
                        "current_page": 1,
                        "page_size": 'all',
                    }
                )
            }, navigate, setLoading, setStore)

            .then(data => {
                // console.log("store list - " + JSON.stringify(data))
                if (data?.success === true) {
                    if (data?.records?.length > 0) {
                        setStoreListData(data?.records);
                    }
                    else {
                        // console.log('No store Data Found !');
                    }

                } else {
                    // console.log('Error occurred. Please try again.')
                    // localStorage.setItem('store',JSON.stringify({ id: null, name: null }) )   
                }
            })
    }, [])

    // on page load get data from data base and store data in state
    useEffect(() => {
        if (user?.id) {
            callAPI(`/admin/users/${user?.id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data?.success === true) {
                        setformValues(data?.record);

                    } else {
                        // console.log('data', data.error)
                    }
                }
                )
        }
    }, [])

    useEffect(() => {

        if (store?.id) {

            var pay_load = {
                "status": "active",
                "current_page": 1,
                "page_size": 'all',
            }
            // console.log("pay_load",pay_load)
            callAPI(`/admin/stores/${store?.id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(pay_load)
                }, navigate, setLoading, setStore)
                .then(data => {
                    // console.log("store list - " + JSON.stringify(data))
                    if (data?.success === true) {
                        setStoreData(data?.record);
                    } else {
                        // console.log('Error occurred. Please try again.')
                    }
                }
                )
        }
    }, [])

    const handleChangeField = (event) => {
        var storeName = event.target[event.target.selectedIndex].getAttribute("data-name")
        //store the store data to context
        var storeId = event.target.value
        if (event.target.value == 0) {
            storeId = null
            storeName = 'Global Admin'
        }
        setStore({ id: storeId, name: storeName })
        //store the store data to localstroage 
        localStorage.setItem('store', JSON.stringify({ id: event.target.value, name: storeName }))
    }

    const User = () => {

        if (showUser === '' || showUser === false || showUser === null || showUser === undefined) {
            setShowUser(true)
        }
        else if (showUser === true) {
            setShowUser(false)
        }
    }

    if (showUser === true && globalHide === true) {
        setShowUser(false)
    }

    const goToMyProfile = () => {
        navigate(`/admin/myprofile/${user?.id}`)
    }

    //navigate to store profile and hide user dropown
    const goToMyStoreProfile = (id) => {
        navigate("/admin/storeprofile")
        setShowUser(false)
    }

    const onClickLogout = () => {
        //remove token from localstorage
        localStorage.setItem('user', null)
        //remove app from local storage
        localStorage.setItem('store', null)
        //remove app from local storage
        localStorage.setItem('Message', null)
        //remove token from context
        setUser(null)
        //remove app from context
        setStore(null)
        //re-direct to login page
        navigate("/")
    }

    var error = localStorage.getItem("Message")
    if (String(error) == "Unexpected error happened. Please try again later") {
        var unexpacted_error = error
    }
    if (String(error) == "") {
        var unexpacted_error = ""
    }

    const updatedStoreList = storeListData?.filter(store => store.store_name !== "Global" );
    var logo = "user_logo"
    const storeFrontUrl = process.env.REACT_APP_STORE_FRONT_URL;
    const navigateToStoreFront = () => {
        if (storeData) { window.open(`http://${storeData?.sub_domain}.${storeFrontUrl}`) }
    }

    return (

        <React.Fragment>

            <header className="ec-main-header navbar" >

                <div className="ec-brand d-flex">
                    <span title="Market Place" role="button" onClick={() => navigate('/admin')}>
                        <p className="brandTitle">
                            <img src="/assets/img/Logo.jpg" alt="Logo" />
                        </p>
                    </span>
                    <div className="m-auto ml-5">
                        <PageTitle />
                    </div>
                </div>

                <div className="progressBarBlock">
                    <ProgressBar />
                </div>


                {unexpacted_error && <p className="unexpacted_error">{unexpacted_error}</p>}


                <div className={`navbar-right ${user?.role != 'GL-ADM' && "ml-auto"} d-flex align-items-center flex-wrap`}>

                    {
                        current_page[1] == 'stores' || current_page[1] == 'store' ? "" :
                            <>
                                {/* {user?.role === 'GL-ADM' && store?.id > 0 && <div className="storeName selected_store_url mr-2" onClick={navigateToStoreFront}>
                                    View Store
                                </div>} */}
                                <div className="storeName mr-2">
                                    {header_labels?.store}
                                </div>
                                {user?.role === 'GL-ADM' &&
                                    <select className="selection form-select" onChange={handleChangeField} value={store?.id}>
                                        <option value="0">Global</option>
                                        {updatedStoreList?.map((store, index) => {
                                            return <option value={store?.store_id} data-name={store?.store_name} key={index}>{store?.store_name}</option>
                                        })}
                                    </select>
                                }
                                {user?.role === 'ST-ADM' &&
                                    <span className="storeAdmin" role="button" onClick={() => navigateToStoreFront()}>{store?.name}</span>
                                }
                            </>
                    }

                    <ul className="nav navbar-nav">
                        {/* <!-- User Account --> */}
                        <li className="dropdown user-menu">
                            <button className="dropdown-toggle"
                                onClick={User} >
                                <Logo name={userName} className={logo} />
                            </button>
                            <ul className={`dropdown-menu dropdown-wrapper ${showUser ? "show" : ""}`}>
                                {/* <!-- User image --> */}
                                <li className="dropdown-header">
                                    <Logo name={userName} className={logo} />
                                    <div className="user_info">
                                        <p>{userName}</p>
                                        <p className="email">{formValues?.email}</p>
                                    </div>
                                </li>
                                <li onClick={goToMyProfile}>
                                    <span className="link">
                                        <i className="mdi mdi-account"></i> {header_labels?.my_profile}
                                    </span>
                                </li>
                                <li onClick={goToMyStoreProfile}>
                                    <span className="link d-flex align-items-center">
                                        {/* icon for storeprofile */}
                                        <i className="material-symbols-outlined">storefront</i>
                                        <span>{header_labels?.store_profile}</span>
                                    </span>
                                </li>
                                <li onClick={onClickLogout}>
                                    <span className="link"> <i className="mdi mdi-logout"></i> {header_labels?.log_out} </span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </header>

        </React.Fragment>
    )
}