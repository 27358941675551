import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { store_page_message } from "../../../Constants/Messages";
import { store_page_labels } from "../../../Constants/Labels";
import { callAPI, useStore, useUser } from "../../../Utils/utils";
import { scrollToTop } from "../../Global/Scroll"
import Message from "../../Message/Message";
import ImageSelector from "../../ImageSelector/ImageSelector";
import ImagesPreview from "../../ImagesPreview/ImagesPreview";
import ErrorMessage from "../../Global/ErrorMessage";
import './StoreEditFormById.css';

export default function StoreEditFormById({ onUpdateStore }) {

	var { id } = useParams();
	const navigate = useNavigate();
	const { user, setUser } = useUser();
	const [errors, setErrors] = useState();
	const [config, setConfig] = useState();
	const { store, setStore } = useStore(id)
	const [loading, setLoading] = useState(false);
	const [formValues, setformValues] = useState({});
	const [modifiedFormValues, setModifiedFormValues] = useState();
	const [countryList, SetCountryList] = useState();
	const [savedImages, setSavedImages] = useState([])
	const [favicon, setFavIcon] = useState([]);
	const [savedFavicon, setSavedFavicon] = useState([])
	const [selectedImages, setSelectedImages] = useState([])
	const [showErrorMessage, setShowErrorMessage] = useState(false)


	// page onload get data from database and based on store id
	useEffect(() => {
		if (id) {
			callAPI(`/admin/stores/${id}`,
				{
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'token': user?.auth_token
					},

				}, navigate, setLoading)

				.then(res => {
					// console.log("api response " + JSON.stringify(res))
					if (res.success === true) {
						setformValues(res?.record);

						if (res?.record?.logo_image != undefined) {
							// console.log("image_id", res?.record?.logo_image?.image_id)
							setSavedImages([
								{
									"image_id": res?.record?.logo_image?.image_id,
									"path": res?.record?.logo_image?.path,
									"name": res?.record?.logo_image?.name
								}
							])
						}
						if (res?.record?.fav_icon_image != undefined) {
							// console.log("image_id", res?.record?.logo_image?.image_id)
							setSavedFavicon([
								{
									"image_id": res?.record?.fav_icon_image?.image_id,
									"path": res?.record?.fav_icon_image?.path,
									"name": res?.record?.fav_icon_image?.name
								}
							])
						}
					} else {
						// console.log('Error occurred. Please try again.')

					}
				})
		}
	}, [])

	useEffect(() => {
		callAPI(`/admin/address/list`,
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'token': user?.auth_token
				},
			}, navigate, setLoading)
			.then(data => {
				// console.log("api response " + JSON.stringify(data))
				if (data?.success === true) {
					SetCountryList(data?.records);
				} else {
					// console.log('Error occurred. Please try again.')

				}
			})
	}, [id])

	const onChangeField = (event) => {
		const { name, value } = event.target;

		setformValues({ ...formValues, [name]: value });
		setModifiedFormValues({ ...modifiedFormValues, [name]: value });
		setShowErrorMessage(false);
		const formattedValue = value.toLowerCase()
			.replace(/\s+/g, '-')
			.replace(/-+/g, '-')
			.replace(/[^a-zA-Z0-9-./_]/g, '')
			?.trim();
		if (name === 'sub_domain') {
			setformValues({ ...formValues, [name]: formattedValue });
		}

		if (name === 'store_name') {
			setformValues({ ...formValues, ["sub_domain"]: formattedValue, ["store_name"]: value });
		}
	};

	// onclick to  validate check require fields and post Storedata database
	const saveStore = () => {

		onUpdateStore()
		scrollToTop()

		var pay_load = {
			"store_id": id,
			"store_name": modifiedFormValues?.store_name?.trim() ,
			"email": modifiedFormValues?.email?.toLowerCase()?.trim() ,
			'phone': modifiedFormValues?.phone,
			"sub_domain": modifiedFormValues?.sub_domain,
			"private_host": modifiedFormValues?.private_host || null,
			"status": modifiedFormValues?.status,
		}

		if (selectedImages == null) {
			pay_load.logo = null
		}
		else if (selectedImages?.image_id) {
			pay_load.logo = selectedImages?.image_id
		}

		if (favicon == null) {
			pay_load.fav_icon = null
		}
		else if (favicon?.image_id) {
			pay_load.fav_icon = favicon?.image_id
		}

		// Check if any address values are present in formValues
		if (modifiedFormValues?.line_1 || modifiedFormValues?.line_1 == "" ||
			modifiedFormValues?.line_2 || modifiedFormValues?.line_2 == "" ||
			modifiedFormValues?.city || modifiedFormValues?.city == "" ||
			modifiedFormValues?.state || modifiedFormValues?.state == "" ||
			modifiedFormValues?.zip || modifiedFormValues?.zip == "" ||
			modifiedFormValues?.country || modifiedFormValues?.country == ""
		) {
			pay_load.address = {
				"address_id": formValues?.address_id,
				"line_1": modifiedFormValues?.line_1,
				"line_2": modifiedFormValues?.line_2,
				"city": modifiedFormValues?.city,
				"state": modifiedFormValues?.state,
				"zip": modifiedFormValues?.zip,
				"country": modifiedFormValues?.country
			};
		}

		// console.log("pay_load", pay_load)
		callAPI('/admin/stores/update',
			{
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'token': user?.auth_token
				},
				body: JSON.stringify(pay_load)
			}, navigate, setLoading, setStore)

			.then(data => {
				// console.log("api response " + JSON.stringify(data))
				if (data?.success == true) {
					onUpdateStore("success")
				} else {
					setErrors(data?.errors)
					setShowErrorMessage(true)
					onUpdateStore("error")
					// console.log('data', data?.errors)
				}
			}
			)
	}

	const onOpenLogoImageSelector = (config) => {
		config.logo = true
		setConfig(config)
	}

	const onOpenFaviconImageSelector = (config) => {
		config.favicon = true
		setConfig(config)
	}

	const onClose = () => {
		setConfig(false)
	}

	const onSelectImages = (images) => {

		if (config.logo) {
			setSelectedImages(images)
		}

		if (config.favicon) {
			setFavIcon(images)
		}
	}
	const onRemoveImageIds = () => {
		setSelectedImages(null)
	}
	const onRemoveFavIconImageIds = () => {
		setFavIcon(null)
	}

	const status = Object.keys(store_page_labels?.store_add_and_edit_page_status)?.map((name) => {
		const value = store_page_labels?.store_add_and_edit_page_status[name];
		return `${name},${value}`;
	});

	const domain = process.env.REACT_APP_STORE_FRONT_URL;

	return (
		<React.Fragment>

			<button className="btn btn-primary saveButton" onClick={saveStore}>{store_page_labels?.store_edit_page_labels?.save_btn_label}</button>

			<div className="row">
				<div className="col-lg-8">
					<div className="card card-default">
						<div className="card-header card-header-border-bottom">
							<h2>{store_page_labels?.store_edit_page_labels?.title}</h2>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.store_name}</label>
										<input type="text" className="form-control" name='store_name' value={formValues['store_name'] || ""} maxLength="100" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'store_name', errors })]} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>

								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.email}</label>
										<input type="email" className="form-control" name='email' value={formValues['email'] || ""} maxLength="100" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'email', errors }) || ErrorMessage({ fieldName: 'email', errors })]} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>

								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.giftinggood_url}</label>
										<div className="domain_wrapper">
											<div className="sub_domain_and_error_wrapper">
												<input type="text" className="form-control" name='sub_domain' value={formValues['sub_domain'] || ""} maxLength="25" onChange={onChangeField} />
											</div>
											<span className="ml-1">.{domain}</span>
										</div>
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'sub_domain', errors })] || ErrorMessage({ fieldName: 'sub_domain', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>

								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label">{store_page_labels?.store_add_and_edit_page_labels?.private_url}</label>
										<input type="text" className="form-control" name='private_host' value={formValues['private_host'] || ""} maxLength="50" onChange={onChangeField} />
									</div>
								</div>

								<div className="col-lg-6">
									<div className="form-group">
										<label>{store_page_labels?.store_add_and_edit_page_labels?.phone}</label>
										<input type="text" className="form-control" name='phone' value={formValues['phone'] || ""} maxLength="20" onChange={onChangeField} />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.line_1}</label>
										<input type="text" className="form-control" name='line_1' value={formValues['line_1'] || ""} maxLength="200" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label">{store_page_labels?.store_add_and_edit_page_labels?.line_2}</label>
										<input type="text" className="form-control" name='line_2' value={formValues['line_2'] || ""} maxLength="200" onChange={onChangeField} />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.city}</label>
										<input type="text" className="form-control" name='city' value={formValues['city'] || ""} maxLength="30" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'city', errors }) || ErrorMessage({ fieldName: 'city', errors })]} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.state}</label>
										<input type="text" className="form-control" name='state' value={formValues['state'] || ""} maxLength="30" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'state', errors }) || ErrorMessage({ fieldName: 'state', errors })]} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.zip}</label>
										<input type="text" className="form-control" name='zip' value={formValues['zip'] || ""} maxLength="20" onChange={onChangeField} />
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-label mandatory_field">{store_page_labels?.store_add_and_edit_page_labels?.country}</label>
										<select className="form-control overflow-auto" name='country' value={formValues['country'] || ""} onChange={onChangeField}>
											<option hidden>{store_page_labels?.store_add_and_edit_page_labels?.select_country}</option>
											{countryList?.map((country, index) => {
												return <option value={country.country_code} key={index}> {country.name}</option>
											})}
										</select>
										<div className="errorMessage">
											<Message text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="card card-default">
						<div className="card-body">
							<div className="form-group ">
								<label className="form-label">{store_page_labels?.store_add_and_edit_page_labels?.status}</label>
								<select className="form-control" name='status' value={formValues['status'] || ""} onChange={onChangeField} >
									{status &&
										status?.map((optionString) => {
											const [name, value] = optionString.split(',');
											return <option value={name} key={name}>{value}</option>;
										})
									}
								</select>
							</div>
						</div>
					</div>

					<div className="card card-default mt-5">
						<div className="card-body">
							<div className="form-group position-relative">
								<label className="store_logo m-0">{store_page_labels?.store_add_and_edit_page_labels?.store_logo}</label>
								<ImagesPreview acceptMultipleFiles={false} featuredImage={false} style={{ border: "none" }} savedImages={savedImages} config={{ "image": "logo" }} onOpen={onOpenLogoImageSelector} selectedImageInfo={selectedImages} onRemoveImageIds={onRemoveImageIds} deleteDisable={true} userProfile={formValues['store_name']} />
							</div>
							<div className="form-group position-relative">
								<label className="store_logo m-0">{store_page_labels?.store_add_and_edit_page_labels?.store_favicon}</label>
								<ImagesPreview acceptMultipleFiles={false} featuredImage={false} style={{ border: "none" }} savedImages={savedFavicon} config={{ "image": "favicon" }} onOpen={onOpenFaviconImageSelector} selectedImageInfo={favicon} onRemoveImageIds={onRemoveFavIconImageIds} deleteDisable={true} userProfile={formValues['store_name']} />
								<span className="favi_icon">{store_page_message?.favicon}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="text-right">
				<button className="btn btn-primary" onClick={saveStore}>{store_page_labels?.store_edit_page_labels?.save_btn_label}</button>
			</div>

			<ImageSelector Config={config} onClose={onClose} onSelectImageInfo={onSelectImages} />
		</React.Fragment >
	)
} 