export const user_page_labels = {

    "user_page_pagination_label": "users",
    "user_page_search_placeholder_label": "Search by User Name or Email",

    "user_page_tab_labels": {
        "all": "All",
        "active": "Active",
        "inactive": "Inactive"
    },

    "user_page_table_head_labels": {
        "name": "Name",
        "email": "Email",
        "role": "Role",
        "status": "Status",
        "enrolled_date": "Enrolled Date",
        "action": "Action"
    },

    "user_add_and_edit_page_status": {
        "active": "Active",
        "inactive": "Inactive",
    },

    "user_add_and_edit_page_labels": {
        "first_name": "First Name",
        "last_name": "Last Name",
        "email": "Email",
        "role": "Role",
        "status": "Status",
        "global_admin": "Global Admin",
        "store_admin": "Store Admin"
    },


    "user_add_page_labels": {
        "title": "Add User",
        "btn_label": "Create",
    },
    "user_edit_page_labels": {
        "title": "Edit User",
        "reset_password_btn_label": "Reset Password",
        "save_btn_label": "Save",
        "delect_btn_label": "Delete",
    }
}

export const store_page_labels = {

    "store_page_pagination_label": "stores",
    "store_page_search_placeholder_label": "Search by Store Name",
    "store_profile": "Store Profile",
    "address_details": "Address Details",
    "edit_btn": "Edit",
    "save_btn": "Save",

    "store_page_tab_labels": {
        "all": "All",
        "active": "Active",
        "inactive": "Inactive"
    },

    "store_page_table_head_labels": {
        "store_name": "Name",
        "email": "Email",
        "phone": "Phone",
        "status": "Status",
        "enrolled_date": "Enrolled Date",
        "action": "Action"
    },

    "store_add_and_edit_page_status": {
        "inactive": "Inactive",
        "active": "Active"
    },

    "store_add_and_edit_page_labels": {
        "store_name": "Name",
        "store_logo": "Logo",
        "store_favicon": "Favicon",
        "giftinggood_url": "Giftinggood URL",
        "private_url": "Private URL",
        "email": "Email",
        "phone": "Phone",
        "line_1": "Address",
        "line_2": "Apartment, suite, etc.",
        "city": "City",
        "state": "State",
        "zip": "Zip",
        "country": "Country",
        "select_country": "United States",
        "status": "Status"
    },

    "store_add_page_labels": {
        "title": "Add Store",
        "btn_label": "Create",
    },
    "store_edit_page_labels": {
        "title": "Edit Store",
        "save_btn_label": "Save",
    }
}

export const product_page_labels = {

    "product_page_pagination_label": "products",
    "product_page_search_placeholder_label": "Search by Product Name",

    "product_type": {
        "PERSONALIZED-CRD": "Personalized Card",
        "BLANK-CRD": "Blank Card",
        "NON-ANIMATED-BUSINESS-CRD": "Non Animated Business Card",
        "NON-ANIMATED-PERSONAL-CRD": "Non Animated Personal Card",
        "ANIMATED-CRD": "Animated Card",
        "BOX-SET-CRD": "Box Set Cards"
    },

    "product_page_tab_labels": {
        "all": "All",
        "active": "Active",
        "inactive": "Inactive"
    },

    "product_page_table_head_labels": {
        "name": "Name",
        "type": "Type",
        "price": "Price",
        "status": "Status",
        "created_at": "Created At",
        "action": "Action"
    },

    "product_add_and_edit_page_status": {
        "inactive": "Inactive",
        "active": "Active"
    },

    "product_add_and_edit_page_labels": {
        "name": "Name",
        "short_description": "Short description (max : 500 characters)",
        "description": "How It Works",
        "slug": "Slug",
        "price": "Price",
        "stock": "Stock",
        "sku": "SKU",
        "product_type": "Type",
        "status": "Status",
        "featured_image": "Featured Image",
        "content_for_printing":"Contents For Print",
        "categories": "Categories",
        "category_placeholder": "Search by category name",
        "category_btn_add": "Add",
        "category_btn_cancel": "Cancel",
        "category_btn_close": "Close",
        "seo": "SEO",
        "meta_title": "Meta Title",
        "meta_description": "Meta Description",
        "meta_image": "Meta Image"

    },


    "product_add_page_labels": {
        "title": "Add Product",
        "btn_label": "Create",
    },
    "product_edit_page_labels": {
        "title": "Edit Product",
        "save_btn_label": "Save",
        "delete_btn_label": "Delete",
    }
}

export const customer_page_labels = {

    "customer_page_pagination_label": "customers",
    "customer_page_search_placeholder_label": "Search by Customer Email",

    "customer_page_tab_labels": {
        "all": "All",
        "active": "Active",
        "inactive": "Inactive"
    },

    "customer_page_table_head_labels": {
        "name": "Name",
        "email": "Email",
        "phone": "Phone",
        "status": "Status",
        "enrolled_date": "Enrolled Date",
        "action": "Action"
    },

    "customer_add_and_edit_page_status": {
        "active": "Active",
        "inactive": "Inactive"
    },

    "customer_add_and_edit_page_labels": {
        "first_name": "First Name",
        "last_name": "Last Name",
        "email": "Email",
        "phone": "Phone",
        "address": "Address (optional)",
        "line_1": "Address",
        "line_2": "Apartment, suite, etc.",
        "city": "City",
        "state": "State",
        "zip": "Zip",
        "country": "Country",
        "select_country": "United States",
        "status": "Status"
    },


    "customer_add_page_labels": {
        "title": "Add Customer",
        "btn_label": "Create",
    },
    "customer_edit_page_labels": {
        "title": "Edit Product",
        "save_btn_label": "Save",
        "general": "General",
        "recipients": "Recipients",
        "recipients_btn_label": "Add Recipients",
        "upload_btn": "Upload Recipients",
        "orders_btn_label": "Add Orders",
        "orders": "Orders"
    }
}

export const recipients_address = {
    "add_title": "Add Recipients",
    "add_btn_label": "Create",
    "edit_title": "Edit Recipients",
    "edit_btn_label": "Save",
    "salutation": "Honorific",
    "name": "Name",
    "first_name": "First Name",
    "last_name": "Last Name",
    "company": "Company",
    "email": "Email",
    "phone": "Phone",
    "line_1": "Address",
    "line_2": "Apartment, suite, etc.",
    "city": "City",
    "state": "State",
    "zip": "Zip",
    "country": "Country",
    "select_country": "United States",
    "select": "Select",
    "personalization": "Add a personal message to this recipient (max : 80 characters)",
    "personalisation_message": "Personal Message",
    "action": "Action",
    "recipients_label": "recipients",
    "sender_name_on_card":"Your name as it appears on the inside of the card",
    "addressee_name": "Recipients name as it appears on the front of the envelope."
}

export const category_page_labels = {

    "category_page_pagination_label": "categories",
    "category_page_search_placeholder_label": "Search by Category Name",


    "category_page_tab_labels": {
        "all": "All",
        "active": "Active",
        "inactive": "Inactive"
    },

    "category_page_table_head_labels": {
        "title": "Name",
        "status": "Status",
        "action": "Action"
    },

    "category_add_and_edit_page_status": {
        "inactive": "Inactive",
        "active": "Active"
    },

    "category_add_and_edit_page_labels": {
        "name": "Name",
        "description": "Description",
        "status": "Status",
        "category_image_block_title": "Category Image",
        "products_block_title": "Products",
        "products_block_search_placholder": "Search by Product Name",
        "products_block_btn_add": "Add",
        "products_block_btn_cancel": "Cancel",
        "products_block_btn_close": "Close"
    },


    "category_add_page_labels": {
        "title": "Add Category",
        "btn_label": "Create",
    },
    "category_edit_page_labels": {
        "title": "Edit Category",
        "save_btn_label": "Save",
        "delete_btn_label": "Delete",
    }
}

export const sales_and_fees_page_labels = {

    "global_sales_and_fees_page_pagination_label": "Sales And Fees Global",
    "store_sales_and_fees_page_pagination_label": "Sales And Fees",
    "global_sales_and_fees_page_search_placeholder_label": "Search by Store Name",

    "category_page_tab_labels": {
        "all": "All",
        "active": "Active",
        "inactive": "Inactive"
    },

    "sales_and_fees_page_container_labels": {
        "orders": "Orders",
        "net_sales": "Net Sales",
        "fees": "Fees",
        "net": "Net",
    },

    "store_sales_and_fees_page_table_head": {
        "transaction_date": "Transaction Date",
        "net_sales": "Net Sales",
        "fees": "Fees",
        "net": "Net",
        "action": "Action"
    },

    "global_sales_and_fees_page_table_head": {
        "store": "Store",
        "orders": "Orders",
        "net_sales": "Net Sales",
        "fees": "Fees",
        "net": "Net",
        "action": "Action"
    },

    "sales_and_fees_page_custom": {
        "from_date": "From date",
        "to_date": "To date",
        "btn_label": "Apply",
    },

    "order_by_date_table_labels": {
        "order_id": "Order Id",
        "product": "Product",
        "price": "Price",
        "quantity": "Quantity",
        "total_price": "Total Price",
        "fees": "Fees",
        "net": "Net"
    },

    "sales_and_fees_options": [
        { "value": "today", "label": "Today" },
        { "value": "yesterday", "label": "Yesterday" },
        { "value": "this-week", "label": "This Week" },
        { "value": "last-7-days", "label": "Last 7 days" },
        { "value": "this-month", "label": "This Month" },
        { "value": "last-30-days", "label": "Last 30 days" },
        { "value": "last-month", "label": "Last Month" },
        { "value": "custom", "label": "Custom" }
    ]
}

export const fees_page_labels = {

    "fees_page_button_label": "Save",
    "fee_type": "Fee Type",
    "fee_rate": "Fee Rate",
    "valid_from": "Valid From",

    "fee_page_table": {
        "title": "Fee History",
        "fee_type": "Fee Type",
        "status": "Status",
        "fee_rate": "Fee Rate",
        "valid_from": "Valid From",
    },

    "fees_drop_down": {
        "FEE-TYP-PERCENTAGE": "%",
        "FEE-TYP-FIXED": "FIXED"
    }
}

export const order_page_labels = {

    "orders": {
        "export_btn": "Export Orders",
        "export_line_item_btn": "Export Line Items",
        "filter_btn": "Filter",
        "fulfillment":{
            'title':"Export For Fulfillment",
            "fulfillment_mode": ["Export File", "Export File and  Card PDFs (for printing)"]
        },
        "upload_shipping_status": "Upload Shipping Status",
        "add_order": "Add Order",
        "tabs_labels": {
            "all": "All",
            "pending": "Pending",
            "confirmed": "Confirmed",
            "in-process": "In Process",
            "shipped": "Shipped",
            "partially_shipped": "Partially Shipped",
        },
        "status": {
            "all": "all",
            "pending": "ORD-STS-PENDING",
            "confirmed": "ORD-STS-CNFRMD",
            "in-process": "ORD-STS-INPRCS",
            "shipped": "ORD-STS-SHIPPED",
            "partially_shipped": "ORD-STS-PARTIALLY-SHIPPED",
        },
        "table_head": {
            "order_id": "Order Id",
            "order_date": "Order Date",
            "customer": "Customer",
            "items": "Items",
            "amount": "Amount",
            "status": "Status",
            "action": "Action"

        },
        "placeholder": "Search by Order Id",
        "pagination_label": "order",
        "orders_status": {
            "ORD-STS-INPRCS": "In Process",
            "ORD-STS-PENDING": "Pending",
            "ORD-STS-CNFRMD": "Confirmed",
            "ORD-STS-SHIPPED": "Shipped",
            "ORD-STS-PARTIALLY-SHIPPED": "Partially Shipped",
        },
        "filter": {
            "title": "Filter Orders",
            "filter_field": "Filter Field",
            "filter_type": "Filter Type",
            "filter_value": "Filter Value",
            "add_filter_btn": "Add Filter",
            "done_btn": "Done",
            "order_status": "Order Status",
            "from_date": "Form Date",
            "to_date": "To Date",

            "filter_operation_mode": {
                "o.status": "Status",
                "o.order_date": "Date",
                "o.order_id": "Order Id",
                // "op.reference": "Reference #"
            },

            "filter_operation_type": {
                "equals": "Equals",
                "between": "Between",
                "in":"In"
            },

            "filter_values": {
                "ORD-STS-PENDING": "Pending",
                "ORD-STS-CNFRMD": "Confirmed",
                "ORD-STS-INPRCS": "In Process",
                "ORD-STS-SHIPPED": "Shipped",
                "ORD-STS-PARTIALLY-SHIPPED": "Partially Shipped",
            }
        }
    },

    "add_order_and_edit_order": {

        "add_order_pay_stripe_btn": "Create and Pay With Credit Card",
        "add_order_pay_paypal_btn": "Create and Pay With Paypal",
        "edit_order_pay_btn": "Save and Pay With stripe",
        "edit_order_pay_stripe_btn": "Save and Pay With Credit Card",
        "edit_order_pay_paypal_btn": "Save and Pay With Paypal",
        "create_btn": "Create",
        "save_btn": "Save",
        "delete_order": "Delete",
        "customer_block": {
            "title": "Customer",
            "placeholder": "Search by Customer Name",
        },
        "product_block": {
            "title": "Product",
            "placeholder": "Search by Product Name",
        },
        "lineItems_tableHeaders": {
            "title": "Item List",
            "image": 'Image',
            "product": 'Product',
            "recipient": 'Details',
            "personalization": 'Personalization',
            "price": 'Price',
            "quantity": 'Quantity',
            "stock": "Stock",
            "total": 'Total',
            "shipped_date": "Shipped Date",
            "action": 'Action'
        },
        "add_recipient_btn": "Add Recipient",
        "upload_btn": "Upload Recipients",
        "delete_btn": "Delete Recipients",
        "recipients_block": {
            "title": "Recipients",
            "stock": "Stock",
            "placeholder": "Search by Recipient",
            "table_header": {
                "name": 'Name',
                "company":"Company",
                "address": 'Address',
                "city": 'City',
                "state": 'State',
                "zip": 'Zip',
                "country": 'Country',
                "personalization": 'Personalization',
                "action": 'Action'
            },
            "selected_recipients": "#Selected Recipients",
            "done_btn": "Done",
        },
        "sub_total_block": {
            "subtotal": "Subtotal",
            "donation": "Donation",
            "processing_fees": "Processing Fees",
            "tax": "Shipping",
            "total": "Total"
        },
        "return_address": {
            "title": "Return / Shipping Address",
            "line_1": "Address",
            "line_2": "Apartment, suite, etc.",
            "city": "City",
            "state": "State",
            "zip": "Zip",
            "country": "Country",
            "select_country": "United States",
            "status": "Status",
            "btn": "Save",
            "add_btn": "Add Return / Shipping Address"
        },
        "payment_block": {
            "title": "Payment",
            "payment_mode": "Payment Mode",
            "payment_mode_option": {
                "PMNT-MD-STRIPE": "Credit Card",
                // "PMNT-MD-PAYPAL": "PayPal",
                "PMNT-MD-CHECK": "Check",
                "PMNT-MD-CMPL": "Complimentary",
                "PMNT-MD-ACT-TRANSFER": "Bank Account Transfer"
            },
            "payment_status": "Payment Status",
            "default_payment_status": "Unpaid",
            "check_no": "Check #",
            "reference": "Reference #",
            "transaction_date": "Transaction Date"
        },
        "billing_address": {
            "title": "Billing Address",
            "line_1": "Address",
            "line_2": "Apartment, suite, etc.",
            "city": "City",
            "state": "State",
            "zip": "Zip",
            "country": "Country",
            "select_country": "United States",
            "status": "Status",
            "btn": "Save",
            "add_btn": "Add Billing Address"
        },

        "edit_order_tabs": {
            "orders": "Orders",
            "order_logs": "Order Logs",
            "payment_history": "Payment History"
        },
        "status_block_title": "Status",
        "order_status_pending": "Pending",
        "order_status": {
            "ORD-STS-INPRCS": "In Process",
            "ORD-STS-CNFRMD": "Confirmed",
        }
    }
}

export const dashboard_page_labels = {
    "global": {
        "total_store": "Total Stores",
        "orders": "Orders",
        "sales": "Sales",
        "average_order_value": "Average Order Value",
        "customers": "Customers",
        "fees": "Fees",
        "stores_table": {
            "title": "Top 5 Stores By Sales",
            "store": "Store",
            "sales": "Sales"
        }
    },

    "store": {
        "container_block": {
            "total_orders": "Total Orders",
            "confirmed": "Confirmed",
            "in_process": "In Process",
            "Shipped": "Shipped",
        },
        "orders": "Orders",
        "sales": "Sales",
        "donation": "Donation",
        "average_order_value": "Average Order Value",
        "customers": "Customers",
        "fees": "Fees",
        "categories": "Top 5 categories",
        "products_table": {
            "title": "Top 5 Products",
            "product_image": "Product Image	",
            "product": "Product",
            "count": "Count"
        }
    },

    "global_and_store_custom": {
        "from_date": "From date",
        "to_date": "To date",
        "btn_label": "Apply",
    },

    "global_and_store_options": [
        { "value": "today", "label": "Today" },
        { "value": "yesterday", "label": "Yesterday" },
        { "value": "this-week", "label": "This Week" },
        { "value": "last-7-days", "label": "Last 7 days" },
        { "value": "this-month", "label": "This Month" },
        { "value": "last-30-days", "label": "Last 30 days" },
        { "value": "last-month", "label": "Last Month" },
        { "value": "custom", "label": "Custom" }
    ]
}

export const profile_page_labels = {
    "my_profile": "My Profile",
    "first_name": "First Name",
    "last_name": "Last Name",
    "email": "Email",
    "reset_password": "Reset Password",
    "edit": "Edit",
    "save": "Save"
}

export const login_page_labels = {
    "title": "Sign In",
    "email": "Email",
    "password": "Password",
    "login_btn": "Login",
    "forgot_password": "Forgot Password ?"
}

export const forgotPassword_page_labels = {
    "title": "Forgot Password",
    "email": "Email",
    "btn": "Submit",
}

export const password_generation_labels = {
    "title": "Password",
    "new_password": "New Password",
    "confirm_password": "Confirm Password",
    "btn": "Submit",
}

export const header_labels = {
    "store": "Store :",
    "my_profile": "My Profile",
    "store_profile": "Store Profile",
    "log_out": "Log Out",
}

export const side_nav_labels = {

    "dashboard": "Dashboard",
    "orders": {
        "orders": "Orders",
        "add_order": "Add Order"
    },
    "users": {
        "users": "Users",
        "add_users": "Add Users"
    },
    "stores": {
        "stores": "Stores",
        "add_stores": "Add Stores"
    },
    "products": {
        "products": "Products",
        "add_products": "Add Products"
    },
    "customers": {
        "customers": "Customers",
        "add_customers": "Add Customers"
    },
    "categories": {
        "categories": "Categories",
        "add_categories": "Add Categories"
    },
    "sales_and_fees": "Sales And Fees",
    "settings": {
        "title": "Settings",
        "fees": "Fees",
        "page": "Page",
        "tax": "Tax",
        "shipping": "Shipping",
        "payment_gateway":"Payment Gateway"
    }
}

export const page_labels = {
    "btn": "Save",
    "banner_block": {
        "title": "Banner",
        "left_content": {
            "title": "Left Content",
            "heading": "Heading",
            "sub_heading": "Sub Heading",
            "description": "Description",
            "button_label": "Button Label",
            "button_url": "Button URL"
        },
        "right_image": {
            "title": "Right Image"
        }
    },
    "catalog_block": {
        "title": "Catalog",
        "heading": "Heading",
        "collection": {
            "title": "Collection",
            "select_collection": "Select Collection"
        },
        "limit": {
            "title": "Limit",
            "select_limit": "Select Limit",
            "btn": "Add",
            "limits": {
                "2": "2",
                "4": "4",
                "6": "6",
                "8": "8",
                "10": "10"
            }
        }
    },
    "about_block": {
        "title": "Title",
        "left_content": {
            "title": "Left Content",
            "heading": "Heading",
            "sub_heading": "Sub Heading",
            "description": "Description",
            "button_label": "Button Label",
            "button_url": "Button URL"
        },
        "right_content": {
            "title": "Right Content",
            "heading": "Heading",
            "button_label": "Button Label",
            "button_url": "Button URL",
            "logo": "Logo",
            "background_image": "Background Image"
        }
    },
    "text_and_image_block": {

        "title": "Title",
        "title_1": "Individual Text And Image",
        "title_2": "Animated Text And Image",
        "left_content": {
            "title": "Left Content",
            "heading": "Heading",
            "sub_heading": "Sub Heading",
            "description": "Description",
            "button_label": "Button Label",
            "button_url": "Button URL"
        },
        "right_image": {
            "title": "Right Image"
        }
    },

    "non_animated_card_block": {
        "title": "Non Animated Card",
        "left_image": {
            "title": "Left Image"
        },
        "right_content": {
            "title": "Left Content",
            "heading": "Heading",
            "description": "Description",
            "personal_description": "Personal Description",
            "business_description": "Business Description",
            "button_label": "Button Label",
            "button_url": "Button URL"
        }
    },

    "image_and_text_block": {
        "title": "Box Set Image And Text",
        "left_image": {
            "title": "left Image"
        },
        "right_content": {
            "title": "Right Content",
            "heading": "Heading",
            "sub_heading": "Sub Heading",
            "description": "Description",
            "personal_description": "Personal Description",
            "button_label": "Button Label",
            "button_url": "Button URL"
        }
    },
}

export const Popup_message_labels = {
    "delect_btn": "Delete",
    "cancel_btn": "Cancel",
    "close_btn": "Close"
}
