import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { callAPI, useUser } from "../../Utils/utils";
import { item_required_error_message, alert_message_type, product_page_message } from "../../Constants/Messages";
import { errorMessages } from "../../Constants/Errors";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import EditProduct from '../../Components/Product/EditProduct/EditProduct';
import Alert from "../../Components/Alert/Alert";
import Loader from "../../Components/Global/Loader/Loader";
import Message from "../../Components/Message/Message";


const EditProductPage = () => {
    var { id } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useUser()
    const [alert, setAlert] = useState()
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false)
    const [formValues, setformValues] = useState({})
    const [refreshCount, setRefreshCount] = useState(0)

    useEffect(() => {
        var SavedMessage = localStorage.getItem('Message')
        if (SavedMessage != null) {
            setAlert({ text: SavedMessage, type: alert_message_type?.success })
        }
    }, [])

    useEffect(() => {
        if (id) {
            callAPI(`/admin/products/${id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)
                .then(data => {
                    console.log("api response " + JSON.stringify(data))
                    if (data.success === true) {
                        setformValues(data?.record);
                    } else {
                        setErrors(data?.errors)
                        // console.log('errors',data?.errors)
                    }
                })
        }
    }, [refreshCount, id])

    //refresh page after product is updated
    const onUpdateProduct = (Message) => {
        if (Message == "success") {
            setAlert({ text: product_page_message?.saved_message, type: alert_message_type?.success })
            setRefreshCount(refreshCount + 1)
        }
        else if (Message == "error") {
            setAlert({ text: item_required_error_message, type: alert_message_type.error })
        }
    }

    // Function to get error message by field name
    function getErrorMessage(fieldName) {
        const error = errors?.find(item => item.field_name === fieldName);
        return error ? error.message : "Field not found";
    }

    return (
        <Layout>

            {loading &&
                <Loader className={"page_loader"} />
            }

            {alert?.text == product_page_message?.saved_message ||
                alert?.text == item_required_error_message ||
                alert?.text == product_page_message?.create_message 
                ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}

            {!loading &&
                <>
                    <div className="breadcrumb-wrapper breadcrumb-contacts">
                        <div className="d-flex flex-column">
                            <BreadCrumb value={formValues['name']} />
                        </div>
                    </div>
                    {errors ?
                        <div className="card">
                            <div className="card-body defaultText">
                                <div className="text-center">
                                    <Message text={errorMessages[getErrorMessage("product")]} type={"normal"} showMessage={true} />
                                </div>
                            </div>
                        </div>
                        :
                        < EditProduct onUpdateProduct={onUpdateProduct} />
                    }
                </>
            }
        </Layout >
    )
}
export default EditProductPage;