import React from "react";
import { Popup_message_labels } from "../../../Constants/Labels";
import { Popup_messages } from "../../../Constants/Messages";
import "./PopupMessage.css"

export const PopUpMessage = ({ delectMessage = true, isDelect = true, onDelete, onClose, newMessage, back = "Back",recipientsCount, yes = false, no = false, containerStyle  }) => {

    var message = delectMessage ? Popup_messages?.delect_message : Popup_messages?.category_warning

    return (
        <div className="popup_message">
            <div className="popup_message_content">
                <div className={`${newMessage ? "popup_container_with_increase" : "popup_container"}`} style={containerStyle}>
                    <div className='go_back_recipients' onClick={() => onClose()}>
                        <span class="mdi mdi-arrow-left pr-2"></span>
                        <p>{back}</p> <br/>
                       
                    </div>
                    {recipientsCount && <p className="mt-3 mb-3">#Selected Recipients: {recipientsCount}</p> }
                    <div className="col-lg-12 text-center">
                        <p>{newMessage || message}</p>
                    </div>
                    {isDelect ?
                        <div className="popup_btn_wrapper">
                            <button className="btn popup_delecte_btn" onClick={() => onDelete()}>{yes || Popup_message_labels?.delect_btn}</button>
                            <button className="btn btn-primary" onClick={() => onClose()}>{no || Popup_message_labels?.cancel_btn}</button>
                        </div>
                        :
                        <div>
                            <button className="btn btn-primary" onClick={() => onClose()}>{Popup_message_labels?.close_btn}</button>
                        </div>
                    }
                </div>
            </div>
        </div>

    )
}