import React, { useEffect, useState } from "react";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import ResetPassword from "../../Components/Account/MyProfile/ResetPassword";
import { callAPI, useUser } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";
const ResetPasswordPage = () => {
    const { user, setUser } = useUser();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    useEffect(() => {
        if (user?.id) {
            // setShowMessage(false)
            callAPI(`/admin/users/${user?.id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data?.success === true) {
                        setFormValues(data?.record);
                    } else {
                        // console.log('data', data.error);
                    }
                }
                )
        }
    }, []);
    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <BreadCrumb />
            </div>
            <ResetPassword mailId={formValues.email} />
        </Layout>
    )
}
export default ResetPasswordPage;

