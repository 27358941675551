import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Global/Scroll"
import { errorMessages } from "../../../Constants/Errors";
import { category_page_labels } from "../../../Constants/Labels";
import { callAPI, useStore, usePage, useUser } from "../../../Utils/utils";
import { item_required_error_message, alert_message_type, categories_page_message } from "../../../Constants/Messages";
import Alert from "../../Alert/Alert";
import Message from "../../Message/Message";
import ImageSelector from "../../ImageSelector/ImageSelector";
import ImagesPreview from "../../ImagesPreview/ImagesPreview";
import ErrorMessage from "../../Global/ErrorMessage";
import './CategoryAdd.css';

export default function CategoryAdd() {

    const navigate = useNavigate();
    const { store, setStore } = useStore();
    const { page, setPage } = usePage();
    const { user, setUser } = useUser();
    const [alert, setAlert] = useState()
    const [config, setConfig] = useState();
    const [payLoad, setPayLoad] = useState();
    const [errors, setErrors] = useState();
    const [mode, setMode] = useState('list');
    const [lineItems, setLineItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [storeName, setStoreName] = useState([]);
    const [productList, setProductList] = useState();
    const [formValues, setformValues] = useState({});
    const [selectedImages, setSelectedImages] = useState();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchProductName, setSearchProductName] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        // Check if storeName is empty or undefined
        if (storeName?.length === 0 || storeName === undefined) {
            setStoreName(() => [store?.name]);
        } else {
            // Append store name to the existing storeName array
            setStoreName((existingStoreId) => [...existingStoreId, store?.name]);
        }
    }, [store?.id]);

    useEffect(() => {
        // Check if storeName has a second element
        if (storeName[1] !== undefined) {
            // Check if the first store name is different from the second store name
            if (storeName[0] !== storeName[1]) {
                navigate("/admin");
                setStoreName([]);
            }
        }
    }, [storeName]);

    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    useEffect(() => {
        if (payLoad) {
            var pay_load = payLoad
            pay_load.page_size = 100
            pay_load.current_page = 1
            callAPI('/admin/products/by-store', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading, setStore)
                .then(data => {
                    // console.log("api response for product list in orders" + JSON.stringify(data));
                    if (data.success) {
                        setProductList(data.records);
                    }
                });
        }
    }, [payLoad])

    const createCategory = () => {
        // Set loading state to true to indicate that the saving process has started.
        setLoading(true);

        // This enables smooth scrolling
        scrollToTop()

        // Retrieve an array of product IDs from the selectedProducts array.
        var product_Ids = selectedProducts?.map(product => product?.product_id);

        // Create a pay_load object containing the necessary data for creating a new category.
        var pay_load = {
            "store_id": store?.id,
            "name": formValues?.name?.trim(),
            "description": formValues.description,
            "status": formValues.status ? formValues.status : 'inactive',
            "products": product_Ids,
            "image": selectedImages?.image_id ? selectedImages?.image_id : null
        };
        // console.log("payload", pay_load)

        // Make an API call to create the category.
        callAPI('/admin/category/create', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': user?.auth_token
            },
            body: JSON.stringify(pay_load)
        }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data));
                if (data.success === true) {
                    var id = data?.category_id;
                    navigate(`/admin/category/edit/${id}`);
                    localStorage.setItem('Message', categories_page_message.create_message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setShowErrorMessage(true);
                    setErrors(data?.errors);
                    setAlert({ text: item_required_error_message, type: alert_message_type?.error })
                    // console.log("errors", data?.errors);
                }
            });
    }

    const onChangeField = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value });

        // hide errors
        setShowErrorMessage(false);
        setErrors([])
    }

    const onChangeFields = (event, product) => {
        // Check if the product is not already in the selectedProducts list.
        if (selectedProducts?.length == 0 && !selectedProducts?.some((selectedProduct) => selectedProduct?.product_id == product?.product_id)) {
            setSelectedProducts([product]);
        }
        else if (selectedProducts?.length > 0 && !selectedProducts?.some((selectedProduct) => selectedProduct?.product_id == product?.product_id)) {
            setSelectedProducts([...selectedProducts, product]);
        }
        else {
            // Remove the unselected product from the selectedProducts list.
            setSelectedProducts(selectedProducts?.filter((selectedProduct) => selectedProduct?.product_id != product?.product_id));
        }
    }

    // console.log("selectedProducts", selectedProducts)

    const onClickProductsList = () => {

        // Set the mode to 'open' to indicate that the search results should be displayed.
        setMode('open');

        var pay_load = {
            "store_id": store?.id,
            "status": "all",
        }

        setPayLoad(pay_load)
    }

    const onSearchProductName = (event) => {

        setSearchProductName(event.target.value);

        var updated_pay_load = {
            "store_id": store?.id,
            "status": "all",
        }
        if (event.target.value) {
            updated_pay_load.filters = {
                "conditions": [
                    { "field": "p.name", "operator": "like", "value": event.target.value, "dataType": "string" }
                ]
            }
        }

        setPayLoad(updated_pay_load)
    }

    const onClickProductSearchIcon = () => {
        // Set the mode to 'open' to indicate that the search results should be displayed.
        setMode('open');

        var updated_pay_load = {
            "store_id": store?.id,
            "status": "all",
            "filters": {
                "conditions": [
                    { "field": "p.name", "operator": "like", "value": searchProductName, "dataType": "string" }
                ]
            }
        }
        if (searchProductName) {
            updated_pay_load.filters = {
                "conditions": [
                    { "field": "p.name", "operator": "like", "value": searchProductName, "dataType": "string" }
                ]
            }
        }

        setPayLoad(updated_pay_load)
    }

    const onClickAddLineItems = () => {
        var newLineItems = []; // Array of objects to store line items.

        // Iterate over the selectedProducts and create a line item object for each selected product.
        selectedProducts?.map((selectedProduct) => {
            newLineItems.push({
                product_id: selectedProduct.product_id,
                store_id: store.id,
                name: selectedProduct.name,
                price_per_item: selectedProduct.price,
                quantity: 1
            });
        });

        // Set the lineItems state to the new line items.
        setLineItems(newLineItems);

        // Set the mode to 'close' to indicate that the search results should be closed.
        setMode('close');
    }

    const onClickRemoveProduct = (productIndex) => {
        // Remove the line item from the lineItems by filtering out the item at the specified index.
        setLineItems(lineItems.filter((v, i) => i !== productIndex));

        // Remove the selected product from the selectedProducts list by filtering out the item at the specified index.
        setSelectedProducts(selectedProducts.filter((v, i) => i !== productIndex));
    }

    const onClickCloseDropdown = () => {
        // Set the mode to 'close' to indicate that the dropdown should be closed.
        setMode('close');

        // Clear the selected products by setting the selectedProducts state to an empty value.
        if (lineItems?.lenght == 0) {
            setSelectedProducts([])
        }
        else if (lineItems?.lenght != 0) {
            setSelectedProducts(lineItems)
        }
    }

    const onOpen = (config) => {
        setConfig(config)
    }

    const onClose = () => {
        setConfig(false)
    }

    const onSelectImages = (images) => {
        setSelectedImages(images)
    }

    const onRemoveImageIds = () => {
        setSelectedImages(null)
    }

    const status = Object.keys(category_page_labels?.category_add_and_edit_page_status)?.map((name) => {
        const value = category_page_labels?.category_add_and_edit_page_status[name];
        return `${name},${value}`;
    });

    const onCloseDropdown = () => {
        setMode("close")
        setSearchProductName("")
    }

    return (
        <React.Fragment>

            {alert?.text ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}

            <button className="btn btn-primary saveButton" onClick={() => createCategory()}>{category_page_labels?.category_add_page_labels?.btn_label}</button>

            <div className="row">
                <div className="col-lg-8">
                    {/* Card for adding a category */}
                    <div className="card card-default">
                        <div className="card-header card-header-border-bottom">
                            <h2>{category_page_labels?.category_add_page_labels?.title}</h2>
                        </div>
                        <div className="card-body">
                            <div className="ec-store-uploads">
                                {/* Category name input */}
                                <label className="form-label mandatory_field">{category_page_labels?.category_add_and_edit_page_labels?.name}</label>
                                <input type="text" className="form-control " name="name" maxLength="100" onChange={onChangeField} value={formValues['name'] || ""} />
                                <div className="errorMessage">
                                    {/* Display error messages for the name input */}
                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'name', errors }) || ErrorMessage({ fieldName: 'name', errors })]} type={"errorMsg"} showMessage={showErrorMessage} />
                                </div>
                                {/* Category description textarea */}
                                <label className="form-label">{category_page_labels?.category_add_and_edit_page_labels?.description}</label>
                                <textarea type="text" className="form-control " rows="4" cols="50" name="description" maxLength="800" onChange={onChangeField} value={formValues['description'] || ""} />
                            </div>
                        </div>
                    </div>

                    {/* Card for displaying products */}
                    <div className="card card-default mt-5">
                        <div className="card-header card-header-border-bottom">
                            <h2>{category_page_labels?.category_add_and_edit_page_labels?.products_block_title}</h2>
                        </div>
                        <div className="card-body">
                            <div className="productSearchWrapper">
                                {/* Product search input */}
                                <div className="search-form d-lg-inline-block searchWrapper">
                                    <div className="input-group">
                                        <input type="text" className="form-control searchInput" placeholder={`${category_page_labels?.category_add_and_edit_page_labels?.products_block_search_placholder}`} onChange={onSearchProductName} onClick={() => onClickProductsList()} />
                                        <span type="button" id="search-btn" className="product_search_icon" style={{ backgroundColor: "none" }} onClick={() => onClickProductSearchIcon()}>
                                            <i className="mdi mdi-magnify"></i>
                                        </span>
                                    </div>
                                </div>
                                {/* Display search results and selected products */}
                                {mode == 'open' &&
                                    <div className={`${productList ? "categorieList" : "noCategorieList"}`}>
                                        <ul id="search-results">
                                            {/* Render each product */}
                                            {productList?.map((list, index) => {
                                                var alreadySelectedItem = lineItems?.find((item) => item?.product_id == list?.product_id)
                                                const isProductInSelected = selectedProducts && selectedProducts?.some((selectedProduct) => selectedProduct?.product_id === list?.product_id);
                                                return (
                                                    <li
                                                        key={index}
                                                        className={`${alreadySelectedItem || isProductInSelected ? "hasSelected seletedLineItem" : ""} productDropDown`}
                                                    >
                                                        <div
                                                            className={`${alreadySelectedItem || isProductInSelected ? "hasSelected seletedLineItem" : ""} w-100`}

                                                            onClick={() => onChangeFields(!alreadySelectedItem, list)}>
                                                            <input
                                                                type="checkbox"
                                                                id={list?.product_id}
                                                                name={list?.product_id}
                                                                value={list?.product_id}
                                                                checked={alreadySelectedItem || isProductInSelected}
                                                                onChange={() => { }}
                                                            />
                                                            <span className="checkBoxLabel">
                                                                {list?.name}
                                                            </span>
                                                        </div>

                                                    </li>

                                                )
                                            })}
                                            {/* Display message if no products found */}
                                            {productList == '' && <p className="text-center mt-3 mb-5">{categories_page_message?.product_not_found}</p>}
                                        </ul>
                                        {/* Buttons for adding and canceling */}
                                        {productList != '' && productList?.lenght != 0 &&
                                            <div className="d-flex productlistbutton mt-3 ">
                                                <button className="addButton" onClick={onClickAddLineItems}>{category_page_labels?.category_add_and_edit_page_labels?.products_block_btn_add}</button>
                                                <button className="cancelButton" onClick={onClickCloseDropdown}>{category_page_labels?.category_add_and_edit_page_labels?.products_block_btn_cancel}</button>
                                                <button className="ml-auto addButton" onClick={() => onCloseDropdown()}>{category_page_labels?.category_add_and_edit_page_labels?.products_block_btn_close}</button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            {/* Display selected products */}
                            {lineItems != null &&
                                <>
                                    {lineItems?.map((lineItem, index) => {
                                        return (
                                            <div key={index} className="searchList">
                                                <span>{lineItem?.name}</span>
                                                <span className="removeProduct" onClick={() => onClickRemoveProduct(index)}><span className="mdi mdi-close"></span></span>
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    {/* Card for category status */}
                    <div className="card card-default">
                        <div className="card-body">
                            <div className="form-group">
                                <label className="form-label">{category_page_labels?.category_add_and_edit_page_labels?.status}</label>
                                <select className="form-control" name="status" value={formValues['status'] || ''} onChange={onChangeField}>
                                    {status &&
                                        status?.map((optionString, index) => {
                                            const [name, value] = optionString.split(',');
                                            return <option value={name} key={index}>{value}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Card for category image */}
                    <div className="mt-5">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{category_page_labels?.category_add_and_edit_page_labels?.category_image_block_title}</h2>
                            </div>
                            <div className="card-body">
                                {/* Component for selecting images */}
                                <ImagesPreview acceptMultipleFiles={false} featuredImage={false} userProfile={false} onOpen={onOpen} selectedImageInfo={selectedImages} onRemoveImageIds={onRemoveImageIds} deleteDisable={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ImageSelector Config={config} onClose={onClose} onSelectImageInfo={onSelectImages} />
            <div className="text-right mt-3">
                <button className="btn btn-primary" onClick={() => createCategory()}>{category_page_labels?.category_add_page_labels?.btn_label}</button>
            </div>
        </React.Fragment >
    )
} 