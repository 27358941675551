import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI, useUser } from "../../../../src/Utils/utils";
import { errorMessages } from "../../../Constants/Errors";
import ErrorMessage from "../ErrorMessage";
import { password_success, Invalid_password_link } from "../../../Constants/Messages";
import SecondaryLoader from "../../Global/SecondaryLoader/SecondaryLoader";
import { password_generation_labels } from "../../../Constants/Labels"
import Alert from "../../Alert/Alert"
import Message from "../../Message/Message";
import './PasswordGeneration.css';
import '../Login/Login.css';

const PasswordGeneration = () => {

    const { user, setUser } = useUser();
    const [alert, setAlert] = useState()
    const [errors, setErrors] = useState([]);
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formValues, setformValues] = useState({})
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [verifyPasswordLink, setVerifyPassWordLink] = useState(false)
    const queryParameters = new URLSearchParams(window.location.search)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const email = queryParameters?.get("email")
    const navigate = useNavigate();

    const onPasswordChange = (event) => {
        const { name, value } = event.target;

        // Remove spaces from the password value
        const cleanedPassword = value.replace(/\s+/g, '');

        setformValues({ ...formValues, [name]: cleanedPassword });
        setShowErrorMessage(false);
        setErrors([]);
    };


    useEffect(() => {
        const secretCode = queryParameters?.get("code")
        var pay_load = {
            email: email,
            secret_code: secretCode
        }

        // console.log("pay_load",pay_load)
        callAPI(`/admin/users/verify-password-reset-link/${email}`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)

            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    setVerifyPassWordLink(false)
                }
                else {
                    setVerifyPassWordLink(true)
                }
            }
            )
    }, [])

    const passwordGeneration = () => {
        const secretCode = queryParameters?.get("code")
        border = "normal"
        var payload = {
            email: email,
            secret_code: secretCode,
            password: formValues.password,
            confirm_password: formValues.confirm_password
        }

        callAPI('/admin/users/save-password',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(payload)
            }, navigate, setLoading)

            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    localStorage.setItem('Message', password_success)
                    navigate('/')
                }
                else {
                    setErrors(data?.errors)
                    setShowErrorMessage(true)
                    // console.log('data', data?.errors)
                }
            }
            )
    }

    var border
    if (formValues.confirm_password) {
        if (formValues.password == formValues.confirm_password) {
            border = "password_matched"
        }
        else if (formValues.password != formValues.confirm_password) {
            border = "password_not_matched"
        }
    }
    else {
        border = "normal"
    }

    //on enter search the store list
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            passwordGeneration()
        }
    }

    // console.log("errors", errors)

    const hide_eye_icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
    </svg>

    const eye_icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
    </svg>


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>

            {verifyPasswordLink ?
                <div className="password_link_expiry_popup">
                    <div className="card shadow ">
                        <div className="card-body">
                            <p> {Invalid_password_link}</p>
                        </div>
                    </div>
                </div>
                :
                <div style={{ display: loader ? 'none' : 'block' }}>
                    <div className="showLogin">
                        <div className="signinContainer">
                            <div className="form-container">
                                <div className="image-holder"></div>
                                <div className="form card-default">
                                    <div className="formWrapper">
                                        <div className="ec-brand">
                                            <span title="Gifting Good">
                                                <p className="brandTitle">
                                                    <img src="/assets/img/Logo.jpg" alt="Logo" />
                                                </p>
                                            </span>
                                        </div>
                                        <div className="card-header card-header-border-bottom Password_message d-flex">
                                            <h2>{password_generation_labels?.title}</h2>
                                            <span className="position-relative">
                                                {alert?.text ?
                                                    <Alert text={alert?.text} type={alert?.type} setAlert={setAlert} />
                                                    : ''}
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group input-container">
                                                <span className="form-label mandatory_field mb-1">{password_generation_labels?.new_password}</span>
                                                <input type={showPassword ? 'text' : 'password'} className="form-control col-lg-4" name='password' onChange={onPasswordChange} value={formValues['password'] || ""} onKeyDown={handleKeyDown} />
                                                <span className="password-and-confirmPassword-toggle" onClick={togglePasswordVisibility}>
                                                    {showPassword ? <span className="eye_icon">{eye_icon}</span> : <span className="hide_eye_icon">{hide_eye_icon}</span>}
                                                </span>
                                                {errors?.length < 3 && <div className="errorMessage">
                                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'password', errors })] || ErrorMessage({ fieldName: 'password', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                </div>}

                                                {showErrorMessage && errors?.length > 2 &&
                                                    <div className="error_popup">
                                                        <div className="card  shadow ">
                                                            <div className="card-body">
                                                                {errors?.map((error, index) => {
                                                                    // console.log("error",error)
                                                                    return (
                                                                        <span key={index}>
                                                                            <Message text={errorMessages[error?.message]} type={"errorMsg"} showMessage={showErrorMessage} />
                                                                        </span>
                                                                    );
                                                                })}

                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                            <div className={`${errors?.length > 2 ? "hide_confirm_password_and_button" : ""}`}>
                                                <div className="form-group mt-2 input-container">
                                                    <span className="form-label mandatory_field mb-1">{password_generation_labels?.confirm_password}</span>
                                                    <input type={showConfirmPassword ? 'text' : 'password'} className={`form-control col-lg-4 ${border}`} name='confirm_password' onChange={onPasswordChange} value={formValues['confirm_password'] || ""} onKeyDown={handleKeyDown} />
                                                    <span className="password-and-confirmPassword-toggle" onClick={toggleConfirmPasswordVisibility}>
                                                        {showConfirmPassword ? <span className="eye_icon">{eye_icon}</span> : <span className="hide_eye_icon">{hide_eye_icon}</span>}
                                                    </span>
                                                    {errors?.length < 3 && <div className="errorMessage">
                                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'confirm_password', errors })] || ErrorMessage({ fieldName: 'confirm_password', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                                    </div>}
                                                </div>
                                                <div>
                                                    <span className="mandatory_field">Note</span>
                                                    <p className="mt-1">Password should be at least 8 characters in length and must have minimum one uppercase, lowercase and special character.</p>
                                                </div>
                                                <div className="button_and_loader">
                                                    <button className="btn btn-primary " onClick={() => passwordGeneration()}>{password_generation_labels?.btn}</button>
                                                    <span> {loading && <SecondaryLoader className={"login_loader"} />} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }

            <div className="hideLogin">
                <div className="loginContainer">
                    <div className="card card-default col-lg-4 m-5 shadow">
                        <div className="card card-default shadow  ">
                            <div className="card-body non_desktop_mode">
                                <span> This application is supported only on the desktop version </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordGeneration;