import React, { useEffect, useState } from "react";
import "./InternalServerErrorPage.css";
import { callAPI, useUser } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";

const InternalServerErrorPage = () => {

    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        callAPI("/admin/address/list",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
            }, navigate, setLoading)
            .then(data => {
                if (data.success) {
                    navigate("/admin")
                }
            }
            )
    }, [])
    
    return (
        <div className="internal_server_error">
            <p className="five_hundred_error">500</p>
            <span className="five_hundred_error_message">Internal Server Error</span>
            <span className="500_error">sorry,something went wrong :(</span>
        </div>
    )
}
export default InternalServerErrorPage;
