import React from 'react';

// Exporting the scrollToTop function to make it available for use in other components
export const scrollToTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This enables smooth scrolling
    });
  }
};

// Since the Scroll component doesn't render anything visible, it returns null
const Scroll = () => {
  return null;
};

export default Scroll;
