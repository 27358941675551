import React, { useState, useEffect } from 'react';
import { errorMessages } from '../../../Constants/Errors';
import "./CardForm.css";

const CardForm = ({ fields, onSaveCardForm, onSaveOrder, lineitems, lineItemIndex }) => {
    const [formData, setFormData] = useState({});
    const [fromSaved, setFromSaved] = useState(true);
    const [lineItemCardFromData, setLineItemCardFromData] = useState()
    const [errors, setErrors] = useState({}); // Store individual field errors

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
        setErrors([])
    };

    const validateEmail = (email) => {
        // A basic email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        if (onSaveOrder != 0) {
            onSave()
        }
    }, [onSaveOrder])

    useEffect(() => {

        if (lineitems?.[lineItemIndex]) {

            var customizationFields = lineitems?.[lineItemIndex]?.customization?.fields
            if (customizationFields) {
                const cardFields = customizationFields?.[0];

                if (Object?.keys(cardFields)?.length != 0) {
                    setLineItemCardFromData(cardFields)
                    setFormData(cardFields)
                    setFromSaved(false)
                }

            }
        }


    }, [lineitems])


    const onSave = () => {
        // Step 2: Check if all required fields have values
        const namesArray = fields?.filter(field => field.type !== "text")?.map(field => field?.name);
        const errorsCopy = {};

        let allFieldsFilled = true;
        namesArray?.forEach(name => {
            if (!formData[name]) {
                errorsCopy[name] = errorMessages[name];
                allFieldsFilled = false;
            }
        });

        // Step 3: Check email validation if email field is present
        if (formData.email && !validateEmail(formData.email)) {
            errorsCopy.email = "Invalid email address";
            allFieldsFilled = false;
        }

        // Step 4: If all fields are filled and email is valid, proceed
        if (allFieldsFilled) {
            setErrors({});
            var fields = []
            fields.push(formData)
            localStorage.setItem('Message', null)
            onSaveCardForm(fields, lineItemIndex);
            setFromSaved(false);
        } else {
            setErrors(errorsCopy);
            localStorage.setItem('Message', "error")
        }
    };

    const onShowCardForm = () => {
        setFromSaved(true);
        if (lineItemCardFromData) {
            setFormData(lineItemCardFromData)
        }
    };

    // console.log("lineItemCardFromData", lineItemCardFromData)
    // console.log("fromData",formData)
    // console.log("lineitems",lineitems)
    // console.log("lineitemIndex",lineItemIndex)

    return (
        <div className='cardFormContainer'>
            {fromSaved ? (
                <form>
                    {fields.map((field, index) => (
                        <div key={index}>
                            {field.type !== "text" && (
                                <>
                                    <label className={`form-label ${field?.mandatory ? "mandatory_field" : ""}`}>
                                        {field?.label}
                                        {field?.max_char && (
                                            <> (max: {field.max_char})</>
                                        )}
                                    </label>
                                    {field?.max_char && (
                                        <p style={{fontSize:"12px"}}>Characters remaining: {field?.max_char - (formData[field?.name] || '')?.length}</p>
                                    )}
                                    {field.type != "input-textarea" ? (
                                        <input
                                            type={field?.type == 'email' ? 'email' : 'text'}
                                            id={field?.name}
                                            name={field?.name}
                                            maxLength={field?.max_char}
                                            className={`form-control ${errors[field?.name] ? "displayerror" : ""}`}
                                            value={formData[field?.name] || ''}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <textarea
                                            id={field?.name}
                                            name={field?.name}
                                            maxLength={field?.max_char}
                                            className={`form-control ${errors[field?.name] ? "displayerror" : ""}`}
                                            value={formData[field?.name] || ''}
                                            onChange={handleChange}
                                        />
                                    )}
                                    {errors[field?.name] && <span className="card-from-error-message">{errors[field?.name]}</span>}
                                </>
                            )}
                            {field?.note && <p>{field?.note}</p>}
                        </div>
                    ))}
                    <button className="btn btn-primary saveCardFrom" type="button" onClick={() => onSave()}>Save</button>
                </form>
            ) : (
                <div className='cardFormDetails'>
                    <span className={`${lineItemCardFromData?.name_personalization ? "display_value" : ""}`}>{lineItemCardFromData?.name_personalization || lineItemCardFromData?.name}</span>
                    <span className={`${lineItemCardFromData?.email ? "display_value" : ""}`}>{lineItemCardFromData?.email}</span>
                    <span className={`${lineItemCardFromData?.message ? "display_value" : ""}`}>{lineItemCardFromData?.message}</span>
                    <span className="mdi mdi-account-edit icon" onClick={() => onShowCardForm()}></span>
                </div>
            )}
        </div>
    );
};

export default CardForm;
