import React from 'react';
import './Logo.css'; // Import the CSS file for the logo styles

/* * Logo component displays the first letter of a name as a logo.
 * @param {string} name - The name to display.
*/

const Logo = ({ name ,className}) => {
    const firstLetter = name?.charAt(0);
    return (
        <div className={`${className ? className : "logo-container"}`}>
            {firstLetter}
        </div>
    );
};

export default Logo;
