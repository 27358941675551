import React, { useState, useEffect } from "react";
import Layout from "../../../Components/Global/Layout";
import BreadCrumb from '../../../Components/Global/BreadCrumb/BreadCrumb';
import "./PaymentGateway.css"
import { useUser, useStore } from "../../../Utils/utils"
import { payment_gateway_page_message } from "../../../Constants/Messages";
import Alert from "../../../Components/Alert/Alert";
import Loader from "../../../Components/Global/Loader/Loader";

const PaymentGatewayPage = () => {
    const [formValues, setformValues] = useState()
    const { user, setUser } = useUser();
    const { store, setStore } = useStore();
    const [alert, setAlert] = useState()
    const [gateway_list, setGatewayList] = useState([])
    const [loader, setLoader] = useState(true)

    const baseUrl = process.env.REACT_APP_DEV_BASE_URL
    useEffect(() => {
        if (store && store.id) {
            getPaymentGateway()
        }
    }, [store?.id])

    async function getPaymentGateway() {
        setLoader(true)
        var pay_load = { store_id: store.id }
        var responseJson = await fetch(`${baseUrl}/admin/payment/get/paymentgateway`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': user?.auth_token
            },
            body: JSON.stringify(pay_load)
        })
            .then((response) => {
                return response.json()
            })
        if (responseJson.success) {
            console.log("responseJson", responseJson?.records[0]?.credentials?.secret_code);
            //   setformValues(...formValues,...{credentials:{secret_code:responseJson.records[0]?.credentials?.secret_code}})
            setGatewayList(responseJson.records)
        }
        setLoader(false)
    }

    // store onchanged values in state
    const onChangeField = (event) => {
        setformValues({ ...formValues, ...{ gateway_code: event.target.name, credentials: { 'secret_code': event.target.value } } })
    }

    async function saveData() {
        setLoader(true)
        var pay_load = { ...formValues, ...{ store_id: store.id } }
        var responseJson = await fetch(`${baseUrl}/admin/payment/update/paymentgateway`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': user?.auth_token
            },
            body: JSON.stringify(pay_load)
        })
            .then((response) => {
                return response.json()
            })
        setLoader(false)
        if (responseJson.success) {
            setAlert({ text: payment_gateway_page_message.saved_message, type: "success" })
        }

    }


    useEffect(() => {
        console.log(JSON.stringify(formValues))
    }, [formValues])
    console.log(alert, "alert");

    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <BreadCrumb />
            </div>
            {loader ? <Loader className={"page_loader"} /> : <>
                {(alert && alert.text && alert.text != "") &&
                    <div className="text-center">
                        <Alert text={alert.text} type={alert.type} setAlert={setAlert} style={{}} />
                    </div>}
                <div className="card card-body">
                    <div className="justify_space_between" >
                        <div className="w-25">
                            <label className="form-label"> Payment Gateway</label>
                            <input disabled={true} className="form-control" name='type' value={"Stripe"} readonly={true} >
                            </input>
                        </div>
                        <div className="w-25">
                            <label className="form-label mandatory_field">Secret Code</label>
                            <input className="form-control" name='PMNT-MD-STRIPE' id="secret_code" value={formValues?.credentials?.secret_code ?? gateway_list[0]?.credentials?.secret_code ?? ''} onChange={onChangeField} />
                        </div>
                        <div className="fees_btn_wrapper">
                            <button className="btn btn-primary" onClick={saveData}>Save</button>
                        </div>
                    </div>
                </div>
            </>
            }
        </Layout>
    )
}
export default PaymentGatewayPage;