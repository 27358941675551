import React, { useEffect } from "react"
import { useUser } from "../Utils/utils";
import LoginFrom from "../Components/Global/Login/Login";
import MyProfilePage from "../Pages/Profile Page/MyProfilePage";
import EditMyProfilePage from "../Pages/Profile Page/EditMyProfilePage";
import DashboardPage from '../Pages/DashboardPage/DashboardPage';
import StoresPage from '../Pages/StorePage/StoresPage';
import StoreAddPage from '../Pages/StorePage/StoreAddPage';
import StoreEditPage from '../Pages/StorePage/StoreEditPage';
import StoreProfilePage from "../Pages/StorePage/StoreProfile/StoreProfilePage";
import EditStoreProfilePage from "../Pages/StorePage/StoreProfile/EditStoreProfilePage";
import UsersPage from '../Pages/UsersPage/UsersPage';
import UserAddPage from '../Pages/UsersPage/AddUserPage';
import UserEditPage from '../Pages/UsersPage/EditUserPage';
import ProductPage from '../Pages/ProductPage/ProductPage';
import AddProductPage from '../Pages/ProductPage/AddProductPage'
import EditProductPage from '../Pages/ProductPage/EditProductPage'
import CustomerPage from '../Pages/CustomerPage/CustomerPage';
import AddCustomerPage from '../Pages/CustomerPage/AddCustomerPage'
import EditCustomerPage from '../Pages/CustomerPage/EditCustomerPage';
import CustomerAddAddressPage from '../Pages/CustomerPage/CustomerAddress/CustomerAddAddressPage/CustomerAddAddressPage';
import CustomerEditAddressPage from '../Pages/CustomerPage/CustomerAddress/CustomerEditAddressPage/CustomerEditAddressPage';
import CategoriesPage from '../Pages/CategoryPage/CategoriesPage';
import CategoryAddPage from '../Pages/CategoryPage/CategoryAddPage';
import CategoryEditPage from '../Pages/CategoryPage/CategoryEditPage';
import SalesAndFeesPage from '../Pages/SalesAndFeesPage/SalesAndFeesPage';
import FeesPage from '../Pages/SettingsPage/FessPage/FeesPage';
import ShippingPage from '../Pages/SettingsPage/ShippingPage';
import TaxPage from '../Pages/SettingsPage/TaxPage';
import OrdersPageForMultipleAddress from "../Pages/OrdersPageForMultipleAddress/OrdersPage";
import AddOrderPageForMultipleAddress from '../Pages/OrdersPageForMultipleAddress/AddOrderPage';
import OrderConfirmationPage from "../Pages/OrdersPageForMultipleAddress/OrderConfirmationPage"
import SalesAndFeesByOrderPage from "../Components/SalesAndFees/SalesandFeesByOrders/SalesandFeesByOrders";
import EditOrdersPage from '../Pages/OrdersPageForMultipleAddress/EditOrderPage'
import PaymentFailed from "../Pages/OrdersPageForMultipleAddress/PaymentFailed";
import PageTable from "../Components/Page/PageTable/PageTable";
import HomePage from "../Components/Page/HomePage/HomePage";
import PayPalPage from "../Pages/PayPalPage/PayPalPage"
import ResetPasswordPage from "../Pages/Profile Page/ResetPasswordPage"
import PaymentGateWayPage from "../Pages/SettingsPage/PaymentGatewayPage/PaymentGatewayPage"

const Components = {
    MyProfilePage: MyProfilePage,
    EditMyProfilePage: EditMyProfilePage,
    DashboardPage: DashboardPage,
    StoresPage: StoresPage,
    StoreAddPage: StoreAddPage,
    StoreEditPage: StoreEditPage,
    StoreProfilePage: StoreProfilePage,
    EditStoreProfilePage: EditStoreProfilePage,
    UsersPage: UsersPage,
    UserAddPage: UserAddPage,
    UserEditPage: UserEditPage,
    ProductPage: ProductPage,
    AddProductPage: AddProductPage,
    EditProduct: EditProductPage,
    CustomerPage: CustomerPage,
    AddCustomerPage: AddCustomerPage,
    EditCustomerPage: EditCustomerPage,
    CustomerAddAddressPage: CustomerAddAddressPage,
    CustomerEditAddressPage: CustomerEditAddressPage,
    CategoriesPage: CategoriesPage,
    CategoryAddPage: CategoryAddPage,
    CategoryEditPage: CategoryEditPage,
    SalesAndFeesPage: SalesAndFeesPage,
    SalesAndFeesByOrderPage: SalesAndFeesByOrderPage,
    FeesPage: FeesPage,
    ShippingPage: ShippingPage,
    TaxPage: TaxPage,
    OrdersPageForMultipleAddress: OrdersPageForMultipleAddress,
    AddOrderPageForMultipleAddress: AddOrderPageForMultipleAddress,
    EditOrdersPage: EditOrdersPage,
    OrderConfirmationPage: OrderConfirmationPage,
    PaymentFailed: PaymentFailed,
    PageTable:PageTable,
    PaymentGatewayPage:PaymentGateWayPage,
    HomePage:HomePage,
    PayPalPage:PayPalPage,
    ResetPasswordPage:ResetPasswordPage,
}


const LoggedInLayout = ({ name }) => {
    const { user, setUser } = useUser()

    useEffect(() => {
        // console.log("user in  - ",user)
    }, [])

    return user && user != 'null' ?
        <>
            {React.createElement(Components[name])}
        </>
        :
        React.createElement(LoginFrom)
}

export default LoggedInLayout;