import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Global/Layout";
import PayPal from "../../Components/PayPal/PayPal";
import "./PayPalPage.css"
const PayPalPage = () => {

    const navigate = useNavigate();

    return (
        <Layout>
            <div className="paypal-wrapper">
                <PayPal />
            </div>
        </Layout>
    )
}
export default PayPalPage;