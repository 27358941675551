import React, { useEffect } from "react";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import StoreCreateForm from '../../Components/Store/AddStore/StoreCreateForm'
import { useUser } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";

const StoreAddPage = () => {

  const navigate = useNavigate();
  const { user, setUser } = useUser(0);

  useEffect(() => {
    if (user?.role != "GL-ADM") {
      navigate("*")
    }
  }, [user])

  return (
    <Layout>
      <div className="breadcrumb-wrapper breadcrumb-contacts">
        <div className="d-flex flex-column">
          <BreadCrumb />
        </div>
      </div>
      {/* Create store Useing StoreCreateForm */}
      <StoreCreateForm />
    </Layout >
  )
}
export default StoreAddPage;