import React, { useEffect, useState } from "react";
import { callAPI, useStore, usePage, useUser } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import Button from '../../Components/Global/Button';
import CustomerTable from '../../Components/Customer/CustomerTable/CustomerTable'
import Pagination from "../../Components/Global/Pagination/Pagination";
import Loader from "../../Components/Global/Loader/Loader";
import { select_store_message } from "../../Constants/Messages";
import { customer_page_labels } from "../../Constants/Labels";

const CustomerPage = () => {

    const navigate = useNavigate();
    const { page, setPage } = usePage()
    const { user, setUser } = useUser();
    const { store, setStore } = useStore()
    const [mode, setMode] = useState('all')
    const [payLoad, setPayLoad] = useState()
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState()
    const [searchResult, setSearchResult] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [resultInfo, setResultInfo] = useState({
        total_rows: "",
        fetched_rows: "",
        total_pages: "",
        page_size: 0,
        current_page: "",
        success: true,
        errors: ""
    })

    // get store id from context and stor in store_id variable
    var storeId = store?.id

    const onPageSelect = (currentPage) => {
        setCurrentPage(currentPage)
        onClickSearchBtn(currentPage)
    }

    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    useEffect(() => {

        if (storeId) {
            var initial_pay_load = {
                "store_id": storeId,
                "status": mode,
                "current_page": currentPage
            }

            var pay_load = payLoad || initial_pay_load
            if(pay_load.status != mode){
                pay_load.status = mode
                pay_load.current_page = 1
            }
            callAPI('/admin/customers/by-store',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(pay_load)
                }, navigate, setLoading, setStore)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data.success) {
                        setSearchResult(data.records)
                        setResultInfo({
                            total_rows: data.total_rows,
                            fetched_rows: data.fetched_rows,
                            total_pages: data.total_pages,
                            page_size: data.page_size,
                            current_page: data.current_page,
                            success: data.success,
                            errors: data.errors
                        })
                    } else {
                        // console.log('Error occurred. Please try again.')
                    }
                }
                )
        }
        localStorage.setItem('Message', null);
    }, [mode, storeId, payLoad]);

    // set onchage values in state
    const onChangeSearchValue = (event) => {
        setSearchValue(event.target.value)
        var pay_load = {
            "store_id": storeId,
            "status": mode,
            "current_page": 1
        }

        if (event.target.value) {
            pay_load.filters = {
                "conditions": [
                    { "field": "first_name", "operator": "like", "value": event.target.value, "dataType": "string" },
                    { "field": "email", "operator": "like", "value": event.target.value, "dataType": "string" },
                ],
                "logic": "1-or-2"
            }
        }
        setPayLoad(pay_load)
    }

    const getSearchResult = (currentPage) => {
        var pay_load = {
            "store_id": storeId,
            "status": mode,
            "current_page": currentPage
        }

        if (searchValue) {
            pay_load.filters = {
                "conditions": [
                    { "field": "first_name", "operator": "like", "value": searchValue, "dataType": "string" },
                    { "field": "email", "operator": "like", "value": searchValue, "dataType": "string" },
                ],
                "logic": "1-or-2"
            }
        }
        setPayLoad(pay_load)

    }

    //during on search icon clicked get the result of store
    const onClickSearchBtn = (currentPage) => {
        getSearchResult(currentPage)
    }

    //on enter search the store list
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getSearchResult()
        }
    }


    let select_store = store?.id == null || store?.id == undefined || store?.id == 0 || store?.name == "Global Admin" ? true : false

    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <div>
                    <BreadCrumb />
                </div>
                {select_store ? '' : <Button className={'btn btn-primary'} onClick={() => navigate('/admin/customer/new')} value={customer_page_labels?.customer_add_page_labels?.title} />}
            </div>
            {select_store ?
                <div className="card card-default">
                    <div className="card-body  select_store">
                        <p className="text-center">{select_store_message?.select_store}</p>
                    </div>
                </div>
                :
                <div className="cardWrapper card-default">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="dataTables_wrapper">
                                <div className="filterTab mb-3">
                                    <div className="navAndSearchWrapper">
                                        <ul className="nav nav-tabs nav-style-border w-75">
                                            <li className="nav-item" onClick={() => setMode('all')}>
                                                <span className={`nav-link ${mode == 'all' ? 'active' : ''}`}>{customer_page_labels?.customer_page_tab_labels?.all}</span>
                                            </li>
                                            <li className="nav-item" onClick={() => setMode('active')}>
                                                <span className={`nav-link ${mode == 'active' ? 'active' : ''}`}>{customer_page_labels?.customer_page_tab_labels?.active}</span>
                                            </li>
                                            <li className="nav-item" onClick={() => setMode('inactive')}>
                                                <span className={`nav-link ${mode == 'inactive' ? 'active' : ''}`}>{customer_page_labels?.customer_page_tab_labels?.inactive}</span>
                                            </li>
                                        </ul>

                                        <div className="search-form" style={{width:"450px"}}>
                                            <div className="input-group">
                                                <input type="text" className="form-control"
                                                    placeholder="Search by Customer firstname or email" onChange={onChangeSearchValue} onKeyDown={handleKeyDown} />
                                                <span className="search_icon">
                                                    <i className="mdi mdi-magnify"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {loading &&
                                    <Loader className={"table_loader"} />
                                }

                                {/* list Customer Based on store  */}
                                <CustomerTable mode={mode} searchResult={searchResult} loader={loading} />
                                <div className="paginationWrapper">
                                    <Pagination totalRows={resultInfo.total_rows} fetchedRows={resultInfo.fetched_rows} totalPages={resultInfo.total_pages} pageSize={resultInfo.page_size} currentPage={resultInfo.current_page} onPageSelect={onPageSelect} label='customers' loader={loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Layout>
    )
}
export default CustomerPage;