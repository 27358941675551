import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import "./PayPal.css"
import { useUser, usePage } from "../../Utils/utils";
import { useParams } from "react-router-dom";
import { PopUpMessage } from "../Global/PopupMessage/PopupMessage";
import SecondaryLoader from '../Global/SecondaryLoader/SecondaryLoader';
import Loader from '../Global/Loader/Loader';

function PayPal() {
  var { id } = useParams()
  const navigate = useNavigate();
  const { page, setPage } = usePage()
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState()
  const [openDelectPopup, setOpenDelectPopup] = useState();
  const prevPageLoading = useRef();


  // useEffect is used to perform side effects in a functional component
  useEffect(() => {
    var order_id = localStorage.getItem('order_id')
    if (order_id) {
      order_id = order_id == id ? id : order_id
    }
    // Replace the "test" client-id value with your client-id
    const clientId = 'AfLqSkM1Lm2UrpCX25iUYwWOEiN_MVxJOvtf2n3poPnH1MKzZuCO4xqkgOFiZJyz8gf8u44TfdrL_QL3';

    // Replace the "YOUR_PRODUCT_ID" and "YOUR_PRODUCT_QUANTITY" with your actual product information
    const cartItem = {
      id: 'YOUR_PRODUCT_ID',
      quantity: 'YOUR_PRODUCT_QUANTITY',
    };

    // Function to handle result message
    function resultMessage(message) {
      const container = document.querySelector('#result-message');
      container.innerHTML = message;
    }

    // Load PayPal SDK
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD`;
    script.async = true;

    setPageLoading(true);
    // Add an event listener for the script's load event
    script.addEventListener('load', () => {
      // The external script has loaded successfully, so set pageLoading to false
      setPageLoading(false);
    });

    script.onload = () => {
      // PayPal SDK has loaded

      // You can use window.paypal to interact with the SDK

      // Create PayPal buttons for payment

      window.paypal
        ?.Buttons({
          async createOrder() {
            onLoading()
            try {
              // Send a POST request to your server's '/api/orders' endpoint to create an order
              var baseUrl = process.env.REACT_APP_DEV_BASE_URL
              const response = await fetch(baseUrl + '/admin/payment/create-paypal-order', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'token': user?.auth_token
                },
                body: JSON.stringify({
                  cart: [cartItem],
                  "order_id": id,
                }),
              });

              const orderData = await response.json();

              if (orderData.id) {
                onStopLoading()
                // Return the order ID if the order creation was successful
                return orderData.id;
              } else {
                onStopLoading()
                // Handle errors if order creation fails
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);

                throw new Error(errorMessage);
              }
            } catch (error) {
              console.error(error);
              onStopLoading()
              resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
            }
          },
          async onApprove(data, actions) {
            onLoading()
            try {
              // Send a POST request to capture the payment after approval
              var baseUrl = process.env.REACT_APP_DEV_BASE_URL
              const response = await fetch(baseUrl + `/admin/payment/capture-paypal-order/${data.orderID}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'token': user?.auth_token
                },
              });

              const orderData = await response.json();
              const errorDetail = orderData?.details?.[0];
              if (orderData?.success) {
                onPaymentSucess()
              }
              if (!orderData?.success) {
                onPaymentFailed()
              }
              if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                onStopLoading()
                return actions.restart();
              } else if (errorDetail) {
                onStopLoading()
                throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
              } else if (!orderData.purchase_units) {
                onStopLoading()
                throw new Error(JSON.stringify(orderData));
              } else {
                onStopLoading()
                // Handle successful payment capture
                const transaction =
                  orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                  orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
                resultMessage(
                  `Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`,
                );
                // console.log(
                //   'Capture result',
                //   orderData,
                //   JSON.stringify(orderData, null, 2),
                // );
              }
            } catch (error) {
              console.error(error);
              onStopLoading()
              resultMessage(
                `Sorry, your transaction could not be processed...<br><br>${error}`,
              );
            }
          },
        })

        .render('#paypal-button-container'); // Render PayPal buttons in the 'paypal-button-container' element
    };

    document.body.appendChild(script);

    // Cleanup: Remove the PayPal SDK script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this effect runs once after initial render

  const onOpenMessagePopUp = () => {
    setOpenDelectPopup(true)
  }

  const onPaymentFailed = () => {
    navigate(`/payment-failed`)
  }

  const onPaymentSucess = () => {
    navigate(`/order-confirmation`)
  }

  const onCloseMessagePopUp = () => {
    setOpenDelectPopup(false)
  }

  const onLoading = () => {
    setLoading(true)
  }

  const onStopLoading = () => {
    setLoading(false)
  }

  useEffect(() => {
    setPage({ isLoading: loading })
  }, [loading])

  return (

    <>
      {pageLoading && <Loader className={"pay_pal_page_loader"} />}

      <div className='go_back' onClick={() => onOpenMessagePopUp()} role='button'>
        <span className="mdi mdi-arrow-left p-2"></span>
        <p>Back</p>
      </div>
      {openDelectPopup && <PopUpMessage delectMessage={true} isDelect={true} onDelete={onPaymentFailed} onClose={onCloseMessagePopUp}  newMessage={"Are you sure you want to cancel the transition?"} yes={"Yes"} no={"No"} containerStyle={{width:"520px"}}/>}
      <div className='paypal-container'>
        <div id="paypal-button-container"></div>
        <p id="result-message"></p>
      </div>

      {loading &&
        <div className='secondary-loader-paypal'>
          <SecondaryLoader />
        </div>
      }

    </>
  );
}

export default PayPal;
