import React from "react";

const Offline = () => {
    return (
        <div className="internal_server_error">
            <p className="five_hundred_error">Offline</p>
            <span className="five_hundred_error_message">Please check your internet connection</span>
        </div>
    );
};

export default Offline;
