import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI, useStore, useUser } from "../../../../Utils/utils";
import { order_page_message } from "../../../../Constants/Messages"
import { useParams } from "react-router-dom";

const OrderLogs = () => {

    var { id } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const { store, setStore } = useStore()
    const [loading, setLoading] = useState(false)
    const [ordersLoges, setOrdersLogs] = useState()

    useEffect(() => {
        callAPI("/admin/orders/history",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify({ "order_id": id })
            }, navigate, setLoading, setStore)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    setOrdersLogs(data?.record)
                } else {
                    // console.log("data?.errors", data?.errors)
                }
            })

        var SavedMessage = localStorage.getItem('Message')
        if (SavedMessage == null || SavedMessage == "null" || SavedMessage == "Recipients" && SavedMessage != "Order created successfully") {
            localStorage.setItem('Message', "order")
        }
    }, [])

    const statusMapping = {
        "ORD-STS-PENDING": "Pending",
        "ORD-STS-INPRCS": "In Process",
        "ORD-STS-DSPTCHD": "Dispatched",
        "ORD-STS-SHIPPED": "Shipped",
        "ORD-STS-CNCLD": "Canceled",
        "ORD-STS-CNFRMD": "Confirmed"
    };

    const logs = ordersLoges?.logs?.map((log, index) => {
        const lineNumber = index + 1;
        const date = new Date(log.time);
        const formattedDate = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
        const formattedTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
        let event = log?.event;

        // Check if the event is "Return address has been updated" and update it to "Return address has been updated to"
        if (event === "Return address has been updated") {
            event = "Return address has been updated to";
        }

        // Check if the event is "Billing address has been updated" and update it to "Billing address has been updated to"
        if (event === "Billing address has been updated") {
            event = "Billing address has been updated to";
        }

        // Check if the event is present in the mapping, and update the event message accordingly
        Object.entries(statusMapping)?.forEach(([status, statusValue]) => {
            if (event.includes(status)) {
                event = event.replace(status, statusValue);
            }
        });

        return (
            <div className="col-lg-12 mb-3" key={index}>
                <div className="card card-default">
                    <div className="card-body">
                        {lineNumber} : {event} on {formattedDate} {formattedTime}
                    </div>
                </div>
            </div>

        );
    });

    return (
        <>
            {ordersLoges ? logs :
                < div className="col-lg-12 mb-3">
                    <div className="card card-default">
                        <div className="card-body">
                            {order_page_message?.logs_not_found}
                        </div>
                    </div>
                </div >
            }
        </>
    )
}

export default OrderLogs