import React, { useState, useEffect } from "react";
import { order_page_labels } from "../../Constants/Labels";
import moment from "moment";
import { available_send_dates } from "../../Constants/constants";

const ExportForFulfillment = ({ storeId, user, status, setOnOrderExportLoading, setErrors, setPopup }) => {
    const [mode, setMode] = useState(order_page_labels.orders.fulfillment.fulfillment_mode[0])
    const [selectedDate, setSelectedDate] = useState('custom date')
    const [filter, setFilter] = useState({})
    const [requiredError,setRequiredError]=useState(false)
    const cardType = [
        {
            name: "Personalized card",
            value: "PERSONALIZED-CRD"
        },
        {
            name: "Blank card",
            value: "BLANK-CRD"
        }]
    const [selectedCardTypes,setSelectedCardTypes]=useState(['PERSONALIZED-CRD',"BLANK-CRD"])

    function selectDate(value) {
        if (selectedDate == 'custom date') {
            setSelectedDate(available_send_dates[0].send_date)
        }
        setMode(value)
    }

    const onChangeFilterMode = (event) => {
        setFilter({ ...filter, [event.target.name]: event.target.value })
    }

    const AddOrRemoveTypes=(value)=>{
        if(selectedCardTypes.includes(value)&&selectedCardTypes.length>1){
            setSelectedCardTypes(selectedCardTypes.filter(a=>a!==value))
        }
        else if(!selectedCardTypes.includes(value)){
            setSelectedCardTypes([...selectedCardTypes,value])
        }
    }

    useEffect(()=>{
        setRequiredError(false)
    },[mode])
    console.log("error",requiredError,filter);
    

    useEffect(()=>{
        if(!selectedCardTypes.includes('PERSONALIZED-CRD')){
            setMode(order_page_labels.orders.fulfillment.fulfillment_mode[0])
        }
    },[selectedCardTypes])

    const exportData = () => {
        let error=false
        if(selectedDate === 'custom date'&& (!filter?.from_date || !filter?.to_date)){
            error=true
        }
        setOnOrderExportLoading(true)
        var pay_load = {
            "store_id": parseInt(storeId),
            "status": order_page_labels?.orders?.status[status],
            "export_format": "Fulfillment"
        }

        var max_date = moment(available_send_dates.find(a => a.send_date === selectedDate)?.drop_date, 'DD-MMM-yyyy').format('YYYY-MM-DD')
        var min_date = moment(available_send_dates[available_send_dates.findIndex(a => a.send_date === selectedDate) - 1]?.drop_date, 'DD-MMM-yyyy').format('YYYY-MM-DD')
        if (mode === order_page_labels.orders.fulfillment.fulfillment_mode[0]) {
            var to_date
            if (available_send_dates.findIndex(a => a.send_date === selectedDate) > 0) {
                to_date = min_date
            } else {
                var date = new Date(max_date).setDate(new Date(max_date).getDate() - 60)
                to_date = moment(new Date(date)).format("YYYY-MM-DD")
            }
            if (selectedDate !== 'custom date') {
                pay_load.drop_date = moment(selectedDate, 'DD-MMM-YYYY').format('YYYY-MM-DD')
            }
            pay_load.card_types=selectedCardTypes
            pay_load.filters = {
                "conditions": [
                    {
                        'dataType': 'date',
                        'field': 'o.order_date',
                        'from_date': selectedDate === 'custom date' ? filter.from_date : max_date,
                        'to_date': selectedDate === 'custom date' ? filter.to_date : to_date,
                        'operator': 'between',
                    }
                ]
            }
        } else {
            pay_load.send_date = {
                requested_send_date: moment(selectedDate, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
                max_date: max_date
            }
            if (available_send_dates.findIndex(a => a.send_date == selectedDate) > 0) {
                pay_load.send_date.min_date = min_date
            } else {
                var date = new Date(max_date).setDate(new Date(max_date).getDate() - 60)
                pay_load.send_date.min_date = moment(new Date(date)).format("YYYY-MM-DD")
            }
        }

        var export_file_name = "fulfillment_export.xlsx"

        // console.log("pay_load" + JSON.stringify(pay_load))
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL
        if(!error){
        fetch(baseUrl + '/admin/data/export-orders',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            })
            .then(data => {
                setPopup('')
                if (data.constructor == Object) {
                    setOnOrderExportLoading(false)
                } else {
                    data.blob().then(blob => {
                        // blob.text().then( text => console.log(text))
                        setOnOrderExportLoading(false)
                        const fileSize = blob.size
                        if (fileSize > 500) {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = export_file_name
                            alink.click();
                        } else {
                            setErrors(["Error encountered while processing file"])
                        }
                    })
                }
            })
        }else{
            setRequiredError(error)
        }
    }

    return (
        <div className="w-100">
            <div className="fulfillment_wrapper">
                <div>
                    <div>
                        <h5 className=" m-0">Product Type</h5>
                        <div className="d-flex">
                        {cardType.map((type, index) => {
                            return (
                                <div key={index} className="d-flex">
                                    <input type="radio" checked={selectedCardTypes.includes(type.value)} className="ml-5 m-0" onClick={()=>{AddOrRemoveTypes(type.value)}} />
                                    <p className=" px-3" style={{ paddingTop: 7 }}>{type.name}</p>
                                </div>
                            )
                        })
                        }
                        </div>
                        <span className="mandatory_field"></span><span className="p-2 noteContent ">Note : Minimum one type should be selected</span>
                    </div>
                    <div>
                        <h5 className=" m-0">Generate</h5>
                        {order_page_labels.orders.fulfillment.fulfillment_mode.map((fulfillment, index) => {
                            return (
                                <div key={index} className="d-flex">
                                    <input type="radio" checked={mode == fulfillment} disabled={!selectedCardTypes.includes('PERSONALIZED-CRD')&&fulfillment.includes('PDF')} className="ml-5 m-0" onChange={() => { index == 1 ? selectDate(fulfillment) : setMode(fulfillment) }} />
                                    <p className="px-3" style={{ paddingTop: 7 }}>{fulfillment}</p>
                                </div>
                            )
                        })
                        }
                    </div>
                    <div>
                        <h5 className="mt-3">Filter</h5>
                        <div className="d-flex">
                            <h6 className=" mt-3 mx-5 text-nowrap ">Date Range</h6>
                            <div className="">
                                <select className='form-control' style={{ width: "250px" }} disabled={available_send_dates.length == 0} value={selectedDate ? moment(selectedDate).format("DD-MMM-YYYY") : ""} onChange={(e) => setSelectedDate(e.target.value)} >
                                    {order_page_labels.orders.fulfillment.fulfillment_mode[0] == mode && <option value={'custom date'}>Custom Date Range </option>}
                                    {available_send_dates.map((date, index) => {
                                        return (
                                            <option key={index} value={date.send_date}>
                                                For Drop Date {date.send_date}
                                            </option>
                                        )
                                    })
                                    }
                                </select>
                                {selectedDate == 'custom date' &&
                                    <div className="mt-4">
                                        <div className=" d-flex justify-items-center ">
                                            <p className="m-2 text-nowrap mandatory_field">{order_page_labels?.orders?.filter?.from_date}</p>
                                            <input type="date" className="form-control fillter_to_date ml-1" name="from_date" value={filter?.["from_date"] || ""} max={filter?.["to_date"]} onChange={onChangeFilterMode} />
                                            {requiredError&&!filter?.["from_date"]&&<span className="mandatory_field  px-5" style={{color:"red"}}>required</span>}
                                        </div>
                                        <div className=" d-flex justify-items-center justify-content-end">
                                            <p className="m-2 mr-3 text-nowrap mandatory_field">{order_page_labels?.orders?.filter?.to_date}</p>
                                            <input type="date" className="form-control fillter_to_date ml-3" name="to_date" value={filter?.["to_date"] || ""} min={filter?.["from_date"]} onChange={onChangeFilterMode} />
                                            {requiredError&&!filter?.["to_date"]&&<span className="mandatory_field  px-5" style={{color:"red"}}>required</span>}
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <button onClick={exportData} className="btn btn-primary ">Generate</button>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default ExportForFulfillment;