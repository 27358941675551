import React, { useEffect } from "react";
import "./Alert.css";

const Alert = ({ text, type, setAlert, style, className }) => {
  useEffect(() => {
    // Execute code when the 'text' prop changes
    if (text) {
      setTimeout(() => {
        // Clear the alert after 5 seconds
        setAlert({ text: "", type: "" })
        localStorage.setItem('Message', null);
        localStorage.setItem('Payment', null);
      }, 5000);
    }
  }, [text]);

  var alertForOrder = className?.order;
  var error = localStorage.getItem("Message")
  if (String(error) == "Unexpected error happened. Please try again later") {
    var unexpacted_error = error
  }
  else {
    var unexpacted_error = ""
  }
  return (
    <div className={`messageWrapper ${alertForOrder}`}>
      {unexpacted_error == "" && <p className={`h6 ${type}`} style={style}>
        {text}
      </p>}
    </div>
  );
};

export default Alert;
