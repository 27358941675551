export const countries = {
    IND: 'India',
    MEX: 'Mexico',
    USA: 'United States',
    CAN: 'Canada',
    AUS: 'Australia',
    BRA: 'Brazil',
    GBR: 'The United Kingdom',
    VGB: 'The British Isles',
    THA: 'Thailand',
    CHE: 'Switzerland',
    ESP: 'Spain',
    ZAF: 'South Africa',
    PRT: 'Portugal',
    PHL: 'Philippines',
    NOR: 'Norway',
    NLD: 'The Netherlands',
    MLT: 'Malta',
    IRL: 'Ireland',
    AUT: 'Austria',
    BEL: 'Belgium',
    CYP: 'Cyprus',
    DNK: 'Denmark',
    FIN: 'Finland',
    FRA: 'France',
    DEU: 'Germany',
    GRC: 'Greece',
    HKG: 'Hong Kong',
    ISR: 'Israel',
    ITA: 'Italy',
    JPN: 'Japan'
}

