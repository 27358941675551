import React, { useState, useEffect } from "react";
import { callAPI, usePage, useStore, useUser } from "../../../Utils/utils";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createdMessage } from "../../../Constants/Messages";
import { item_required_error_message, alert_message_type } from "../../../Constants/Messages";
import { recipients_address } from "../../../Constants/Labels";
import { errorMessages } from "../../../Constants/Errors";
import ErrorMessage from "../../Global/ErrorMessage"
import Alert from "../../Alert/Alert";
import Message from "../../Message/Message";

export default function AddAddress({ title, size, setMode, customerId, onCompletion, alertStyle }) {

    var { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { store, setStore } = useStore();
    const { user, setUser } = useUser();
    const { page, setPage } = usePage();
    const [alert, setAlert] = useState()
    const [formValues, setformValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [countryList, SetCountryList] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [storeName, setStoreName] = useState([]);
    const [errors, setErrors] = useState();

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    //set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    // onchage the values store data in to state
    const onChangeField = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value })
        setShowErrorMessage(false)
        setErrors([])
    }


    // onclick validate check required and post the data to database 
    const createAddress = () => {

        location?.state?.from != 'orders' &&
            window?.scrollTo({
                top: 0,
                behavior: 'smooth' // This enables smooth scrolling
            });

        var pay_load = {
            "customer_id": id ? id : customerId,
            "salutation": formValues?.salutation || "",
            "name": formValues?.name?.trim(),
            "line_1": formValues?.line_1 || "",
            "line_2": formValues?.line_2 || "",
            "line_3": formValues?.line_3 || "",
            "city": formValues?.city || "",
            "state": formValues?.state || "",
            "zip": formValues?.zip || "",
            "country": formValues?.country || "USA",
            "personalisation": formValues?.personalisation || "",
            "sender_name_on_card":formValues?.sender_name_on_card,
            "addressee_name": formValues?.addressee_name
        }
        // console.log("pay_load - ", pay_load)
        callAPI("/admin/address/create",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(
                    pay_load
                )
            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    if (location?.state?.from == 'orders') {
                        setMode('openRecipientList')
                        var alert = { text: "Recipient has been Created", type: "success" }
                        onCompletion(alert, data.address_id)
                    }
                    else {
                        var addressId = data.address_id
                        navigate(`/admin/customer/edit/${id}/address/edit/${addressId}`)
                        localStorage.setItem('Message', 'Address' + " " + createdMessage)
                    }
                }
                else {
                    setErrors(data?.errors)
                    setShowErrorMessage(true)
                    setAlert({ text: item_required_error_message, type: alert_message_type?.error })
                    // console.log('data', data?.errors)
                }
            }
        )
    }

    // page onload get the data from database list the country names
    useEffect(() => {
        callAPI("/admin/address/list",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
            }, navigate, setLoading)
            .then(res => {
                // console.log("api response " + JSON.stringify(data))
                if (res.success === true) {
                    SetCountryList(res.records);
                } else {
                    // console.log('errors occurred. Please try again.')

                }
            })
    }, [store?.id])

    // console.log("fromValues", formValues)

    return (
        <React.Fragment>
            {alert?.text ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}

            <div className={`addaddress ${size}`}>
                {location?.state?.from != 'orders' &&
                    <div>
                        <button className="btn btn-primary saveButton" onClick={createAddress}>{recipients_address?.add_btn_label}</button>
                    </div>
                }

                <div className={`card card-default ${location?.state?.from == 'orders' && "ordersCard"}`}>
                    <div className="card-header card-header-border-bottom ">
                        {location?.state?.from == 'orders' ?
                            <div className="recipient_header_wrapper">
                                <div className="go_back" onClick={() => setMode('openRecipientList')}>
                                    <span className="mdi mdi-arrow-left pr-2" onClick={() => setMode('openRecipientList')}></span>
                                    <h2>Back</h2>
                                </div>
                                <h2 className="text-center">{`${title ? title : recipients_address?.add_title}`}</h2>
                                <button className="btn btn-primary " onClick={createAddress}>{recipients_address?.add_btn_label}</button>
                            </div>
                            :
                            <h2>{title ? title : recipients_address?.add_title}</h2>
                        }

                    </div>
                    <div className="card-body">
                        <div className="row">
                            
                            <div className="col-lg-2">
                                <div className="form-group mb-4">
                                    <label className="form-label">{recipients_address?.salutation}</label>
                                    <input type="text" className="form-control" name='salutation' value={formValues['salutation'] || ""} maxLength="10" onChange={onChangeField} />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group mb-4">
                                    <label className="form-label mandatory_field">{recipients_address?.name}</label>
                                    <input type="text" className="form-control" name='name' value={formValues['name'] || ""} maxLength="100" onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'name', errors })] || ErrorMessage({ fieldName: 'name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">{recipients_address?.company}</label>
                                    <input type="text" className="form-control" name='line_3' value={formValues['line_3'] || ""} maxLength="150" onChange={onChangeField} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-4">
                                    <label className="form-label mandatory_field">{recipients_address?.line_1}</label>
                                    <input type="text" className="form-control" name='line_1' value={formValues['line_1'] || ""} maxLength="200" onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'line_1', errors })] || ErrorMessage({ fieldName: 'line_1', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">{recipients_address?.line_2}</label>
                                    <input type="text" className="form-control" name='line_2' value={formValues['line_2'] || ""} maxLength="200" onChange={onChangeField} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-4">
                                    <label className="form-label mandatory_field">{recipients_address?.city}</label>
                                    <input type="text" className="form-control" name='city' value={formValues['city'] || ""} maxLength="30" onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'city', errors })] || ErrorMessage({ fieldName: 'city', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-4">
                                    <label className="form-label mandatory_field">{recipients_address?.state}</label>
                                    <input type="text" className="form-control" name='state' value={formValues['state'] || ""} maxLength="30" onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'state', errors })] || ErrorMessage({ fieldName: 'state', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-4">
                                    <label className="form-label mandatory_field">{recipients_address?.zip}</label>
                                    <input type="text" className="form-control" name='zip' value={formValues['zip'] || ""} maxLength="20" onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'zip', errors })] || ErrorMessage({ fieldName: 'zip', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-4">
                                    <label className="form-label mandatory_field">{recipients_address?.country}</label>
                                    <select className="form-control" name='country' value={formValues['country'] || ""} onChange={onChangeField}>
                                        <option hidden>{recipients_address?.select_country}</option>
                                        {countryList?.map((country, index) => {
                                            return <option value={country?.country_code} key={index}> {country?.name}</option>
                                        })}
                                    </select>
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'country', errors })] || ErrorMessage({ fieldName: 'country', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="form-group mb-4">
                                                <label>{recipients_address?.sender_name_on_card}</label>
                                                {/* <p style={{fontSize:"12px"}}>Characters remaining: {80 - (formValues['sender_name_on_card']  || '')?.length}</p> */}
                                                <input type="text" className="form-control" name='sender_name_on_card' maxLength="80" value={formValues['sender_name_on_card'] == "NULL" ? "" : formValues['sender_name_on_card'] || ""} onChange={onChangeField} />
                                            </div>
                                <div className="form-group mb-4">
                                    <label className="form-label">{recipients_address?.personalization}</label>
                                        <p style={{fontSize:"12px"}}>Characters remaining: {80 - (formValues['personalisation']  || '')?.length}</p>
                                    <input type="text" className="form-control" name='personalisation' maxLength="80" value={formValues['personalisation'] || ""} onChange={onChangeField} />
                                </div>
                                <div className="form-group mb-4">
                                                <label>{recipients_address?.["addressee_name"]}</label>
                                                {/* <p style={{fontSize:"12px"}}>Characters remaining: {80 - (formValues['addressee_name']  || '')?.length}</p> */}
                                                <input type="text" className="form-control" name='addressee_name' maxLength="80" value={formValues['addressee_name'] == "NULL" ? "" : formValues['addressee_name'] || ""} onChange={onChangeField} />
                                            </div>
                            </div>
                            {/* {location?.state?.from == 'orders' &&
                                <div className="col-lg-6">
                                    <div className="saveBtn mt-5">
                                        <button className="btn btn-primary ml-5" onClick={createAddress}>{recipients_address?.add_btn_label}</button>
                                    </div>
                                </div>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            {location?.state?.from != 'orders' &&
                <div className="text-right mt-3">
                    <button className="btn btn-primary" onClick={createAddress}>{recipients_address?.add_btn_label}</button>
                </div>
            }
        </React.Fragment >
    )
} 