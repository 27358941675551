import React from "react";
import Layout from "../../Global/Layout";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../Utils/utils";
import { PageConfig } from "../../../Config/PageConfig";
import BreadCrumb from "../../Global/BreadCrumb/BreadCrumb";
import { select_store_message } from "../../../Constants/Messages"

const PageTable = () => {

    const navigate = useNavigate()
    const { store, setStore } = useStore();
    let select_store = store?.id == null || store?.id == undefined || store?.id == 0 || store?.name == "Global Admin" ? true : false

    return (
        <Layout>

            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <BreadCrumb />
            </div>
            {
                select_store ?
                    <div className="card card-default">
                        <div className={`card-body select_store`}>
                            <p className="text-center">{select_store_message?.select_store}</p>
                        </div>
                    </div>
                    :
                    <div className=" cardWrapper card-default mt-5">
                        <div className="card-body">
                            <div className="table-responsive tableOutline" >
                                <table className="table" >
                                    <thead>
                                        <tr>
                                            <th>{PageConfig?.thead?.s_no}</th>
                                            <th>{PageConfig?.thead?.name}</th>
                                            <th>{PageConfig?.thead?.status}</th>
                                            <th>{PageConfig?.thead?.action}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{PageConfig?.tbody?.tr?.s_no}</td>
                                            <td>{PageConfig?.tbody?.tr?.name}</td>
                                            <td>{PageConfig?.tbody?.tr?.status}</td>
                                            <td>
                                                <span className="mdi mdi-account-edit icon" onClick={() => navigate(`/admin/settings/pages/edit/1`)}></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            }
        </Layout>
    )
}

export default PageTable