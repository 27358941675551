import React from "react";
import Layout from "../../Components/Global/Layout";
import SalesAndFeesGlobal from "../../Components/SalesAndFees/SalesAndFeesGlobal/SalesAndFeesGlobal";
import SalesAndFeesStore from "../../Components/SalesAndFees/SalesAndFeesStore/SalesAndFeesStore";
import { useStore } from "../../Utils/utils";
import "./SalesAndFeesPage.css";

const SalesAndFeesPage = () => {

    const { store, setStore } = useStore();

    var store_id = store?.id
    return (
        <Layout>

            {
                store_id == undefined || store_id == null || store_id == 0 || store?.name == "Global Admin" ?

                    <SalesAndFeesGlobal />
                    :

                    <SalesAndFeesStore />

            }
        </Layout>
    )
}
export default SalesAndFeesPage;