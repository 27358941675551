import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { user_page_labels } from "../../../Constants/Labels";
import { PopUpMessage } from "../../../Components/Global/PopupMessage/PopupMessage"
import { alert_message_type, password_reset } from "../../../Constants/Messages";
import { callAPI, useStore, usePage, useUser } from "../../../Utils/utils";
import { user_page_message } from "../../../Constants/Messages";
import Message from "../../Message/Message";
import ErrorMessage from "../../Global/ErrorMessage";
import "./EditUser.css";

export default function EditUser({ onUpdateUser }) {

    var { id } = useParams();
    const { user, setUser } = useUser();
    const { store, setStore } = useStore();
    const { page, setPage } = usePage();
    const [formValues, setformValues] = useState({});
    const [updatedFormValues, setUpdatedFormValues] = useState();
    const [role, setRole] = useState();
    const [loading, setLoading] = useState(false);
    const [forgotPassword, setForgotPassword] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [storeName, setStoreName] = useState([]);
    const [errors, setErrors] = useState();
    const [openDelectPopup, setOpenDelectPopup] = useState();
    const [showMessage, setShowMessage] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    //set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    //on-page, load get user data from database-based store id and  user-id
    useEffect(() => {
        if (id) {
            callAPI(`/admin/users/${id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)

                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data?.success == true) {
                        setformValues(data?.record);
                        // if role Global admin ("GL-ADM") Store role is Global Admin
                        if (data?.record?.role_cd == "GL-ADM") {
                            setRole("Global Admin")
                        }// if role Store Admin ("ST-ADM") Store role isStore Admin
                        else if (data?.record?.role_cd == "ST-ADM") {
                            setRole("Store Admin")
                        }
                    } else {
                        // console.log('data', data?.errors)
                    }
                }
                )
        }
    }, [])

    const onChangeField = (event) => {
        const updatedName = event.target.name?.trim()
        const UpdateValue = event.target.value?.trim()
        setformValues({ ...formValues, [updatedName]:UpdateValue })
        setUpdatedFormValues({ ...updatedFormValues, [updatedName]: UpdateValue })
        setShowErrorMessage(false)
        setErrors([])
    }

    //onClick save user validate required field and send data database through -- /admin/users/update -- API
    const SaveUsers = () => {

        var pay_load = {
            "user_id": id,
            "store_id": store?.id,
            "role_cd": role,
            "first_name": updatedFormValues?.first_name?.trim() ,
            "last_name": updatedFormValues?.last_name?.trim() ,
            "email": updatedFormValues?.email?.trim() ,
            "status": updatedFormValues?.status,

        }
        // console.log("pay_load", pay_load)
        if (pay_load) {
            callAPI("/admin/users/update",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(pay_load)
                }, navigate, setLoading)
                .then(data => {
                    // console.log("admin users update api response " + JSON.stringify(data));
                    if (data.success) {
                        onUpdateUser(alert_message_type?.success)
                    } else {
                        setErrors(data?.errors)
                        setShowErrorMessage(true)
                        onUpdateUser(alert_message_type?.error)
                        // console.log('admin users update api errors', data?.errors)
                    }
                }
                )
        }
    }

    // click the reset password button, send the reset password link, and show the email sent message
    const sendResetPasswordEmail = () => {

        if (formValues?.email) {
            callAPI("/admin/users/forgot_password",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify({
                        "email": formValues?.email,
                    })
                }, navigate, setLoading)
                .then(data => {
                    // console.log("forgot password api response " + JSON.stringify(data));
                    if (data?.success) {
                        setForgotPassword(data?.url);
                    } else {
                        // console.log('forgot password api errors', data?.errors);
                    }
                }
                )
        }

        if (showMessage) {
            setShowMessage(false)
        } else {
            setShowMessage(true)
        }
    };

    const onOpenResetPasswordPage = () => {
        window.open(forgotPassword, '_self')
        // console.log("forgotPassword",forgotPassword)
    }

    const status = Object.keys(user_page_labels?.user_add_and_edit_page_status)?.map((name) => {
        const value = user_page_labels?.user_add_and_edit_page_status[name];
        return `${name},${value}`;
    });

    const onOpen = () => {
        setOpenDelectPopup(true)
    }

    const onDelete = () => {

        callAPI(`/admin/users/${id}/delete`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
            }, navigate, setLoading)
            .then(data => {
                // console.log("admin users update api response " + JSON.stringify(data));
                if (data.success) {
                    navigate("/admin/users")
                    localStorage.setItem('Message', user_page_message?.deleted_message)
                } else {
                    // console.log("errors", data.error)
                }
            }
        )
    }

    const onClose = () => {
        setOpenDelectPopup(false)
    }

    return (

        <React.Fragment>

            <button className="btn btn-primary saveButton" onClick={SaveUsers}>{user_page_labels?.user_edit_page_labels?.save_btn_label}</button>
            <button className="btn delectButton" onClick={() => onOpen()}>{user_page_labels?.user_edit_page_labels?.delect_btn_label}</button>
            {openDelectPopup && <PopUpMessage delectMessage={true} isDelect={true} onDelete={onDelete} onClose={onClose} />}
            <div className="row">
                <div className="col-lg-8">
                    <div className="card card-default">
                        <div className="card-header card-header-border-bottom">
                            <h2>{user_page_labels?.user_edit_page_labels?.title}</h2>
                        </div>
                        <div className="card-body m-auto">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label mandatory_field">{user_page_labels?.user_add_and_edit_page_labels?.first_name}</label>
                                        <input type="text" className="form-control" name="first_name" maxLength="30" onChange={onChangeField} value={formValues['first_name'] || ""} />
                                        <div className="errorMessage">
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'first_name', errors })] || ErrorMessage({ fieldName: 'first_name', errors })} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label mandatory_field">{user_page_labels?.user_add_and_edit_page_labels?.last_name}</label>
                                        <input type="text" className="form-control" name="last_name" maxLength="30" onChange={onChangeField} value={formValues['last_name'] || ""} />
                                        <div className="errorMessage">
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'last_name', errors })] || ErrorMessage({ fieldName: 'last_name', errors })} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label mandatory_field">{user_page_labels?.user_add_and_edit_page_labels?.email}</label>
                                        <input type="email" className="form-control email_input" disabled maxLength="50" name="email" value={formValues['email'] || ""} />
                                        <div className="errorMessage">
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'email', errors })] || ErrorMessage({ fieldName: 'email', errors })} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{user_page_labels?.user_add_and_edit_page_labels?.role}</label>
                                        <input type="text" disabled className="form-control" name="role_cd" value={role || ""} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-end ml-auto">
                                <button className="btn btn-primary" onClick={sendResetPasswordEmail}>{user_page_labels?.user_edit_page_labels?.reset_password_btn_label}</button>
                            </div> */}
                            <div className="d-flex  mt-3 col-lg-12">
                                {showMessage && <div className="rest_email_message">
                                    {password_reset?.password_reset_message}
                                    <span> {formValues?.email} </span>
                                    {password_reset?.instructions}
                                    <span className="resetLink ml-1" onClick={() => onOpenResetPasswordPage()}>
                                        {password_reset?.link}
                                    </span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card card-default">
                        <div className="card-body">
                            <div className="form-group ">
                                <label className="form-label">{user_page_labels?.user_add_and_edit_page_labels?.status}</label>
                                <select className="form-control" name="status" onChange={onChangeField} value={formValues['status'] || ""}>
                                    {status &&
                                        status?.map((optionString) => {
                                            const [name, value] = optionString.split(',');
                                            return <option value={name} key={name}>{value}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
} 