import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI, useUser } from "../../../Utils/utils";
import { profile_page_labels } from "../../../Constants/Labels";
import { password_reset } from "../../../Constants/Messages";
import Loader from "../../Global/Loader/Loader";

export default function MyProfile() {

    const { user, setUser } = useUser();
    const [formValues, setFormValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [forgotPassword, setForgotPassword] = useState();
    const navigate = useNavigate();

    // on page load, get data from the database and store it in state
    useEffect(() => {
        if (user?.id) {
            setShowMessage(false)
            callAPI(`/admin/users/${user?.id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data?.success === true) {
                        setFormValues(data?.record);
                    } else {
                        // console.log('data', data.error);
                    }
                }
                )
        }
    }, []);

    // click the reset password button, send the reset password link, and show the email sent message
    const sendResetPasswordEmail = () => {
        setShowMessage(true)
        if (formValues?.email) {
            callAPI("/admin/users/forgot_password",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify({
                        "email": formValues?.email,
                    })
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data));
                    if (data?.success == true) {
                        setForgotPassword(data?.url);
                    } else {
                        // console.log('data', data?.errors);
                    }
                }
                )
        }
        navigate(`/admin/myprofile/${user?.id}/reset-password`)
    };

    // on click of the edit button, navigate to the profile edit page
    const openEditMyProfilePage = () => {
        navigate(`/admin/myprofile/edit/${user?.id}`);
    };

    // on update user data increase the refreshCount    
    const openResetPasswordLink = () => {
        window.open(forgotPassword, '_self')
    }

    return (
        <React.Fragment>

            {loading && // Display a loader if loading is true
                <Loader className={"page_loader"} />
            }

            {!loading && // Render the profile details if loading is false
                <div className="col-lg-8">
                    <div className="card card-default">
                        <div className="card-header card-header-border-bottom">
                            <h2>{profile_page_labels?.my_profile}</h2>
                        </div>
                        <div className="card-body card-header-border-bottom">
                            <div className="form-group row mb-3">
                                <div className="col-9 col-form-label">
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark profileTitle">{profile_page_labels?.first_name}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{formValues?.first_name}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark profileTitle">{profile_page_labels?.last_name}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{formValues?.last_name}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4 text-dark profileTitle">{profile_page_labels?.email}</div>
                                        <div className="col-1 text-dark">:</div>
                                        <div className="col-7">{formValues?.email}</div>
                                    </div>
                                </div>
                                <div className=" d-flex col-12 justify-content-end">
                                    {/* <button className="btn btn-primary" onClick={sendResetPasswordEmail}>{profile_page_labels?.reset_password} </button> */}
                                    <button className="btn btn-primary ml-2" onClick={openEditMyProfilePage}>{profile_page_labels?.edit}</button>
                                </div>
                                {/* <div className="d-flex  mt-3 col-lg-12">
                                    {showMessage && <div className="rest_email_message">
                                        {password_reset?.password_reset_message}
                                        <span> {formValues?.email} </span>
                                        {password_reset?.instructions}
                                        <span className="resetLink ml-1" onClick={() => openResetPasswordLink()}>
                                            {password_reset?.link}
                                        </span>
                                    </div>}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            }


        </React.Fragment >

    )
}

