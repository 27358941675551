import React, { useState, useEffect } from "react";
import './Loader.css';

const Loader = ({ className, imageSelector = false }) => {

    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        setPageLoading(true)
        loader()
    }, [])

    const loader = () => {
        setTimeout(() => {
            setPageLoading(false);
        }, 1000);
    };

    return (
        <>
            {pageLoading ?
                <div className={`${className ? className : "defaultloader"}`}>
                    <img className={`${imageSelector ? "loader_image_inside_pop" : "loader_image"}`} src="/assets/img/loader.gif" alt="loader" />
                </div>
                :
                <div className={`${className ? className : "defaultloader"}`}>
                    <img className={`${imageSelector ? "loader_image_inside_pop" : "loader_image"}`} src="/assets/img/loader.gif" alt="loader" />
                </div>
            }
        </>
    )
}

export default Loader;