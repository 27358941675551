import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Utils/utils";
import { side_nav_labels } from "../../Constants/Labels";
import "./SideNav.css";

export default function SideNav() {
    const location = useLocation()
    var currentPath = [location.pathname.split("/")]
    var checklocation = currentPath[0]
    var dashboard = checklocation[1]
    var uselocation = checklocation[2]

    const navigate = useNavigate()
    const { user, setUser } = useUser()


    return (
        <React.Fragment>

            <div className="ec-left-sidebar ec-bg-sidebar">
                <div id="sidebar" className="sidebar ec-sidebar-footer " >
                    {/* <!-- begin sidebar scrollbar --> */}
                    <div className="ec-navigation">
                        <div className="simplebar-mask" role="button">
                            <div className="simplebar-offset">
                                <div className="simplebar-ec-content-wrapper">
                                    <div className="simplebar-content">
                                        {/* <!-- sidebar menu --> */}
                                        <ul className="nav sidebar-inner m-0">
                                            {/* <!-- Stores --> */}
                                            {user?.role == 'GL-ADM' &&
                                                <li className={` has-sub ${uselocation == 'stores' || uselocation == 'store' ? "expand" : ""} heading `} >
                                                    <div className={`${uselocation == 'stores' || uselocation == 'store' ? "select" : "not_select"}`}> </div>
                                                    <span className="sidenav-item-link" onClick={() => navigate('/admin/stores')} >
                                                        <i className={`mdi ${uselocation == 'stores' || uselocation == 'store' ? "icon_select" : "icon_not_select"} `}>
                                                            <span className="material-symbols-outlined">storefront</span></i>
                                                        <p className="nav-text ">{side_nav_labels?.stores?.stores}</p>
                                                        <b className="caret"></b>
                                                    </span>
                                                    <div className="collapse" style={{ display: uselocation == 'stores' || uselocation == 'store' ? 'block' : 'none' }}>
                                                        <ul className="sub-menu" id="users" >
                                                            <li className="sub_heading">
                                                                <span className="sidenav-item-link " onClick={() => navigate('/admin/stores')}>
                                                                    <p className={`nav-text  ${checklocation[2] == 'stores' ? 'text-dark' : 'text-muted'}`}>{side_nav_labels?.stores?.stores}</p>
                                                                </span>
                                                            </li>
                                                            <li className="sub_heading">
                                                                <span className="sidenav-item-link" onClick={() => navigate('/admin/store/new')}>
                                                                    <p className={`nav-text ${checklocation[3] == 'new' ? 'text-dark' : 'text-muted'} `}>{side_nav_labels?.stores?.add_stores}</p>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            }
                                            {/* <!-- Dashboard --> */}
                                            <li className="active heading" onClick={() => navigate('/admin')}>
                                                <div className={`${dashboard == 'admin' && uselocation == undefined ? "select" : "not_select"}`}> </div>
                                                <span className="sidenav-item-link">
                                                    <i className={`mdi mdi-view-dashboard-outline ${dashboard == 'admin' && uselocation == undefined ? "icon_select" : "icon_not_select"} `}></i>
                                                    <p className="nav-text">{side_nav_labels?.dashboard}</p>
                                                </span>

                                            </li>

                                            {/* <!-- Orders --> */}
                                            <li className={` has-sub ${uselocation == 'orders' || uselocation == 'order' ? "expand" : ""} heading `} >
                                                <div className={`${uselocation == 'orders' || uselocation == 'order' ? "select" : "not_select"}`}> </div>
                                                <span className="sidenav-item-link" onClick={() => navigate('/admin/orders')} >
                                                    <i className={`mdi ${uselocation == 'orders' || uselocation == 'order' ? "icon_select" : "icon_not_select"} `}>
                                                        <span className="material-symbols-outlined">receipt_long</span></i>
                                                    <p className="nav-text">{side_nav_labels?.orders?.orders}</p>
                                                    <b className="caret"></b>
                                                </span>
                                                <div className="collapse" style={{ display: uselocation == 'orders' || uselocation == 'order' ? 'block' : 'none' }}>
                                                    <ul className="sub-menu" id="users" >
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/orders')}>
                                                                <p className={`nav-text  ${checklocation[2] == 'orders' ? 'text-dark' : 'text-muted'}`}>{side_nav_labels?.orders?.orders}</p>
                                                            </span>
                                                        </li>
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/order/new')}>
                                                                <p className={`nav-text ${checklocation[3] == 'new' ? 'text-dark' : 'text-muted'}`}>{side_nav_labels?.orders?.add_order}</p>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>

                                            {/* <!-- Users --> */}
                                            <li className={` has-sub ${uselocation == 'users' || uselocation == 'user' ? "expand" : ""} heading `} >
                                                <div className={`${uselocation == 'users' || uselocation == 'user' ? "select" : "not_select"}`}> </div>
                                                <span className="sidenav-item-link" onClick={() => navigate('/admin/users')} >
                                                    <i className={`mdi ${uselocation == 'users' || uselocation == 'user' ? "icon_select" : "icon_not_select"} `}>
                                                        <span className="material-symbols-outlined">group_add</span></i>
                                                    <p className="nav-text">{side_nav_labels?.users?.users}</p>
                                                    <b className="caret"></b>
                                                </span>
                                                <div className="collapse" style={{ display: uselocation == 'users' || uselocation == 'user' ? 'block' : 'none' }}>
                                                    <ul className="sub-menu" id="users" >
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/users')}>
                                                                <p className={`nav-text  ${checklocation[2] == 'users' ? 'text-dark' : 'text-muted'}`}>{side_nav_labels?.users?.users}</p>
                                                            </span>
                                                        </li>
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/user/new')}>
                                                                <p className={`nav-text ${checklocation[3] == 'new' ? 'text-dark' : 'text-muted'}`}>{side_nav_labels?.users?.add_users}</p>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>


                                            {/* <!-- Products --> */}
                                            <li className={` has-sub ${uselocation == 'product' || uselocation == 'products' ? "expand" : ""} heading `} >
                                                <div className={`${uselocation == 'product' || uselocation == 'products' ? "select" : "not_select"}`}> </div>
                                                <span className="sidenav-item-link" onClick={() => navigate(`/admin/products`)}>
                                                    <i className={`mdi ${uselocation == 'product' || uselocation == 'products' ? "icon_select" : "icon_not_select"}`}>
                                                        <span className="material-symbols-outlined">sell</span></i>
                                                    <p className="nav-text">{side_nav_labels?.products?.products}</p>
                                                    <b className="caret"></b>
                                                </span>
                                                <div className="collapse" style={{ display: uselocation == 'product' || uselocation == 'products' ? 'block' : 'none' }}>
                                                    <ul className="sub-menu" id="users" >
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/products')}>
                                                                <p className={`nav-text  ${uselocation == 'products' ? 'text-dark' : 'text-muted'}`}>{side_nav_labels?.products?.products}</p>
                                                            </span>
                                                        </li>
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/product/new')}>
                                                                <p className={`nav-text ${checklocation[3] == 'new' ? 'text-dark' : 'text-muted'} `}>{side_nav_labels?.products?.add_products}</p>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>

                                            {/* <!-- Customers --> */}
                                            <li className={` has-sub ${uselocation == 'customer' || uselocation == 'customers' ? "expand" : ""} heading`} >
                                                <div className={`${uselocation == 'customer' || uselocation == 'customers' ? "select" : "not_select"}`}> </div>
                                                <span className="sidenav-item-link" onClick={() => navigate('/admin/customers')}>
                                                    <i className={`mdi ${uselocation == 'customers' || uselocation == 'customer' ? "icon_select" : "icon_not_select"}`}>
                                                        <span className="material-symbols-outlined">groups</span></i>
                                                    <p className="nav-text">{side_nav_labels?.customers?.customers}</p>
                                                    <b className="caret"></b>
                                                </span>
                                                <div className="collapse" style={{ display: uselocation == 'customer' || uselocation == 'customers' ? 'block' : 'none' }}>
                                                    <ul className="sub-menu" id="users" >
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/customers')}>
                                                                <p className={`nav-text  ${checklocation[2] == 'customers' ? 'text-dark' : 'text-muted'}`}>{side_nav_labels?.customers?.customers}</p>
                                                            </span>
                                                        </li>
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/customer/new')}>
                                                                <p className={`nav-text ${checklocation[3] == 'new' ? 'text-dark' : 'text-muted'} `}>{side_nav_labels?.customers?.add_customers}</p>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>

                                            {/* <!-- category --> */}
                                            <li className={` has-sub ${uselocation == 'categories' || uselocation == 'category' ? "expand" : ""} heading`} >
                                                <div className={`${uselocation == 'categories' || uselocation == 'category' ? "select" : "not_select"}`}> </div>
                                                <span className="sidenav-item-link" onClick={() => navigate('/admin/categories')} >
                                                    <i className={`mdi ${uselocation == 'categories' || uselocation == 'category' ? "icon_select" : "icon_not_select"} `}>
                                                        <span className="material-symbols-outlined">category</span></i>
                                                    <p className="nav-text ">{side_nav_labels?.categories?.categories}</p>
                                                    <b className="caret"></b>
                                                </span>
                                                <div className="collapse" style={{ display: uselocation == 'categories' || uselocation == 'category' ? 'block' : 'none' }}>
                                                    <ul className="sub-menu" id="users" >
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/categories')}>
                                                                <p className={`nav-text  ${checklocation[2] == 'categories' ? 'text-dark' : 'text-muted'}`}>{side_nav_labels?.categories?.categories}</p>
                                                            </span>
                                                        </li>
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/category/new')}>
                                                                <p className={`nav-text ${checklocation[3] == 'new' ? 'text-dark' : 'text-muted'} `}>{side_nav_labels?.categories?.add_categories}</p>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>


                                            {/* <!-- SalesAndFees --> */}
                                            {/* <li className="has-sub heading">
                                                <div className={`${checklocation[2] == "salesandfees" ? "select" : "not_select"}`}> </div>
                                                <span className="sidenav-item-link " onClick={() => navigate('/admin/salesandfees')} >
                                                    <i className={`mdi mdi-cash-multiple ${checklocation[2] == 'salesandfees' ? "icon_select" : "icon_not_select"}`}></i>
                                                    <p className={`nav-text ${checklocation[2] == 'salesandfees' ? "icon_select" : "icon_not_select"}`}>{side_nav_labels?.sales_and_fees}</p>
                                                </span>
                                            </li> */}

                                            {/* <!-- Settings --> */}
                                            <li className={` has-sub ${checklocation[2] == 'settings' ? "expand" : ""} heading `} >
                                                <div className={`${checklocation[2] == 'settings' ? "select" : "not_select"}`}> </div>
                                                <span className="sidenav-item-link" onClick={() => navigate('/admin/settings/fees')}>
                                                    <i className={`mdi mdi-settings ${checklocation[2] == 'settings' ? "icon_select" : "icon_not_select"}`}>
                                                        <span className="material-symbols-outlined">settings</span>
                                                    </i>
                                                    <p className="nav-text">{side_nav_labels?.settings?.title}</p>
                                                    <b className="caret"></b>
                                                </span>
                                                <div className="collapse" style={{ display: checklocation[2] == 'settings' ? 'block' : 'none' }}>
                                                    <ul className="sub-menu" id="users" >
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/settings/fees')}>
                                                                <p className={`nav-text  ${checklocation[3] == 'fees' ? 'text-dark' : 'text-muted'}`}>
                                                                    {side_nav_labels?.settings?.fees}
                                                                </p>
                                                            </span>
                                                        </li>
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/settings/pages')}>
                                                                <p className={`nav-text  ${checklocation[3] == 'pages' ? 'text-dark' : 'text-muted'}`}>
                                                                    {side_nav_labels?.settings?.page}
                                                                </p>
                                                            </span>
                                                        </li>
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link">
                                                                <p className={`nav-text ${checklocation[3] == 'tax' ? 'text-dark' : 'text-muted'} `}>{side_nav_labels?.settings?.tax}</p>
                                                            </span>
                                                        </li>
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link">
                                                                <p className={`nav-text ${checklocation[3] == 'shipping' ? 'text-dark' : 'text-muted'} `}>{side_nav_labels?.settings?.shipping}</p>
                                                            </span>
                                                        </li>
                                                        <li className="sub_heading">
                                                            <span className="sidenav-item-link" onClick={() => navigate('/admin/settings/payment_gateway')}>
                                                                <p className={`nav-text ${checklocation[3] == 'payment_gateway' ? 'text-dark' : 'text-muted'} `}>{side_nav_labels?.settings?.payment_gateway}</p>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}