export const Orientations={
    portrait: "CARD_ORIENTATION_PORTRAIT",
    landscape:"CARD_ORIENTATION_LANDSCAPE"
}

export const available_send_dates = [
    // {
    //     "send_date":"30-Oct-2024",
    //     "drop_date":"27-Oct-2024"
    // },
    {
        "send_date":"06-Nov-2024",
        "drop_date":"03-Nov-2024"
    },
    {
        "send_date":"27-Nov-2024",
        "drop_date":"24-Nov-2024"
    },
    {
        "send_date":"04-Dec-2024",
        "drop_date":"01-Dec-2024"
    },
    {
        "send_date":"11-Dec-2024",
        "drop_date":"08-Dec-2024"
    },
    {
        "send_date":"16-Dec-2024",
        "drop_date":"11-Dec-2024"
    },
    // {
    //     "send_date":"18-Dec-2024",
    //     "drop_date":"15-Dec-2024"
    // },
    {
        "send_date":"23-Dec-2024",
        "drop_date":"18-Dec-2024"
    },
    {
        "send_date":"09-Jan-2025",
        "drop_date":"06-Jan-2025"
    }
]