import React from "react";

const Button = ({ value, onClick, className }) => {
    return (
        <React.Fragment>
            <button type="button" onClick={onClick} className={className} >{value}</button>
        </React.Fragment>
    )
}


export default Button;