import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { callAPI, useUser } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../Constants/Errors";
import Alert from "../../Components/Alert/Alert";
import Layout from "../../Components/Global/Layout";
import Message from "../../Components/Message/Message";
import Loader from "../../Components/Global/Loader/Loader";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import CategoryEdit from '../../Components/Category/CategoryEdit/CategoryEdit';
import ErrorMessage from "../../Components/Global/ErrorMessage";
import { item_required_error_message, alert_message_type, categories_page_message } from "../../Constants/Messages";

const CategoryEditPage = () => {

    var { id } = useParams()
    const navigate = useNavigate()
    const { user, setUser } = useUser()
    const [alert, setAlert] = useState()
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false)
    const [formValues, setformValues] = useState({})
    const [refreshCount, setRefreshCount] = useState(0)

    useEffect(() => {
        var SavedMessage = localStorage.getItem('Message')
        if (SavedMessage != null || SavedMessage != "null") {
            setAlert({ text: SavedMessage, type: "success" })
        }
    }, [])

    useEffect(() => {
        if (id) {
            callAPI(`/admin/category/${id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data?.success === true) {
                        setformValues(data?.record);
                    } else {
                        setErrors(data?.errors)
                        // console.log('errors', data?.errors)
                    }
                }
                )
        }
    }, [refreshCount, id])

    const onUpdateCategory = (Message) => {

        if (Message == alert_message_type?.success) {
            setAlert({ text: categories_page_message?.saved_message, type: alert_message_type?.success })
            setRefreshCount(refreshCount + 1)
        }
        else if (Message == alert_message_type?.error) {
            setAlert({ text: item_required_error_message, type: alert_message_type?.error })
        }
    }

    return (
        <Layout>
            {loading &&
                <Loader className={"page_loader"} />
            }

            {!loading &&
                <>
                    <div className="breadcrumb-wrapper breadcrumb-contacts">
                        <div className="d-flex flex-column">
                            <BreadCrumb value={formValues['name']} />
                        </div>
                    </div>

                    {alert?.text == categories_page_message?.saved_message || alert?.text == item_required_error_message || alert?.text == categories_page_message?.create_message ?
                        <div className="text-center">
                            <Alert text={alert?.text} type={alert?.type} setAlert={setAlert} />
                        </div>
                        : ''}

                    {errors ?

                        <div className="card">
                            <div className="card-body defaultText">
                                <p className="text-center">
                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'category', errors })]} type={"normal"} showMessage={true} />
                                </p>
                            </div>
                        </div>

                        :

                        <CategoryEdit onUpdateCategory={onUpdateCategory} />
                    }
                </>
            }
        </Layout >
    )
}
export default CategoryEditPage;