import React, { useState, useEffect } from "react";
import Layout from "../../../../Components/Global/Layout";
import BreadCrumb from '../../../../Components/Global/BreadCrumb/BreadCrumb';
import AddAddress from '../../../../Components/Customer/AddAddress/AddAddress';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { callAPI, useUser } from "../../../../Utils/utils";
import Loader from "../../../../Components/Global/Loader/Loader";

const CustomerAddAddressPage = () => {

    var { id } = useParams();
    const navigate = useNavigate();
    const [formValues, setformValues] = useState({})
    const [loading, setLoading] = useState(false)
    const { user, setUser } = useUser();

    useEffect(() => {
        callAPI(`/admin/customers/${id}`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token':user?.auth_token
                },
            }, navigate, setLoading)
            .then(res => {
                // console.log("api response " + JSON.stringify(res))
                if (res.success === true) {
                    setformValues(res?.record);
                } else {
                    // console.log('Error occurred. Please try again.')
                }
            })
    }, [])

    useEffect(() => {
        localStorage.setItem('Message', "Recipients")
    }, [])

    var page_loader = "page_loader"

    var customerName = "";

	if (formValues) {
		if (formValues.first_name !== undefined && formValues.first_name !== "NULL" && formValues.first_name !== null && formValues.first_name !== "null" && formValues.first_name !== 0 && formValues.first_name !== "0") {
			customerName = formValues.first_name;
		}
		if (formValues.last_name !== undefined && formValues.last_name !== "NULL" && formValues.last_name !== null && formValues.last_name !== "null" && formValues.last_name !== 0 && formValues.last_name !== "0") {
			customerName += (customerName ? " " : "") + formValues.last_name;
		}
	}

	// If both first_name and last_name were undefined or met the exclusion conditions, customerName will remain an empty string.

	// If you want to trim the resulting customerName, you can use .trim():
	customerName = customerName.trim();

    return (
        <Layout>
            {loading &&
                <Loader className={page_loader} />
            }
            {loading == false &&
                <>
                    <div className="breadcrumb-wrapper breadcrumb-contacts">
                        <div className="d-flex flex-column">
                            <BreadCrumb value={customerName} />
                        </div>
                    </div>
                    {/* Add Address From  */}
                    <AddAddress />
                </>
            }
        </Layout>
    )
}
export default CustomerAddAddressPage;