import React from "react";
import { useNavigate } from "react-router-dom";
import { product_page_labels } from "../../../Constants/Labels";
import { product_page_message } from "../../../Constants/Messages";

export default function ProductList({ mode, searchResult, loader }) {

    const navigate = useNavigate();
    const editProduct = (id) => {
        navigate(`/admin/product/edit/${id}`)
    }

    return (
        <React.Fragment>
            <div className={`${loader ? 'loderHide' : 'loderShow'}`}>
                {searchResult == '' || searchResult == null || searchResult == undefined || searchResult.length == 0 ?
                    <div className="card">
                        <div className="card-body defaultText">
                            <p className="text-center">{product_page_message[mode]}</p>
                        </div>
                    </div>
                    :
                    <div className="tableOutline">
                        {mode &&
                            searchResult !== '' &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="col-lg-4">{product_page_labels?.product_page_table_head_labels?.name}</th>
                                        <th className="col-lg-3">{product_page_labels?.product_page_table_head_labels?.type}</th>
                                        <th className="col-lg-1">{product_page_labels?.product_page_table_head_labels?.price}</th>
                                        <th className="col-lg-1">{product_page_labels?.product_page_table_head_labels?.status}</th>
                                        <th className="col-lg-2">{product_page_labels?.product_page_table_head_labels?.created_at}</th>
                                        <th className="col-lg-2">{product_page_labels?.product_page_table_head_labels?.action}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {searchResult?.map((product, index) => {
                                        var getEnrolledDate = product.created_at
                                        let enrolledDate = getEnrolledDate.substring(0, 10);
                                        const status = product.status === 'active' ? 'Active' : product.status === 'inactive' ? 'Inactive' : '';
                                        return (
                                            <tr key={index} >
                                                <td role='button' onClick={() => editProduct(product.product_id)}>{product.name}</td>
                                                <td>{product_page_labels?.product_type?.[product.product_type]}</td>
                                                <td>{product.price}</td>
                                                <td>{status}</td>
                                                <td>{enrolledDate}</td>
                                                <td>
                                                    <span className="mdi mdi-account-edit icon" onClick={() => editProduct(product.product_id)} ></span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }

                    </div>
                }
            </div>
        </React.Fragment>
    )
}
