import React from "react";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import MyProfile from "../../Components/Account/MyProfile/MyProfile";
const MyProfilePage = () => {
    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <BreadCrumb />
            </div>
            <MyProfile />
        </Layout>
    )
}
export default MyProfilePage;

