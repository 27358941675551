import React from "react";

const Footer = () =>  {
    return (
        <React.Fragment>
            {/* <!-- Footer --> */}
            <footer className="footer mt-auto">
                <div className="copyright bg-white">
                    <p>
                        Copyright &copy; <span> </span> <a className="text-primary" href="#" >Gifting Good</a>. All Rights Reserved.
                    </p>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer;