import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "./Footer";
import SideNav from '../SideNav/SideNav';
import Offline from "../Offline/Offline";
import { useEffect } from "react";

const Layout = ({ children }) => {

    const [globalHide, setGlobalHide] = useState(true)
    const [isOnline, setIsOnline] = useState(navigator.onLine)
    const navigate = useNavigate();
    
    // Toggle the value of `globalHide` between `true` and `false`
    const close = () => {
        if (globalHide === true) {
            setGlobalHide(false); // If `globalHide` is `true`, set it to `false`
        } else if (globalHide === false) {
            setGlobalHide(true); // If `globalHide` is `false`, set it to `true`
        }
    }
    
    // Effect to handle online/offline network status changes
    useEffect(() => {
        function handleNetworkChange() {
            setIsOnline(navigator.onLine); // Update `isOnline` state based on network status
        }
    
        // Add event listeners for 'online' and 'offline' events
        window.addEventListener('online', handleNetworkChange);
        window.addEventListener('offline', handleNetworkChange);
    
        // Clean up by removing event listeners when component unmounts
        return () => {
            window.removeEventListener('online', handleNetworkChange);
            window.removeEventListener('offline', handleNetworkChange);
        };
    }, []);
    
    // Effect to handle changes in the 'storage' event
    useEffect(() => {
        const handleStorageChange = () => {
            window.location.reload(); // Reload the page when 'storage' event occurs
        };
    
        // Add event listener for 'storage' event
        window.addEventListener('storage', handleStorageChange);
    
        // Clean up by removing event listener when component unmounts
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

     // Add an event listener to track window resize
     window.addEventListener('resize', function () {
        // Check the current window width
        if (window.innerWidth < 1199) {
            // Redirect to the login page
            // window.location.href = 'login.html';
            navigate('/')
            localStorage.setItem('user', null)
            localStorage.setItem('store', null)
            localStorage.setItem('Message', null)
        }
    });

    // window.addEventListener('contextmenu', function (e) {
    //     e.preventDefault(); // Prevent the default context menu from appearing
    // });
    return (
        <React.Fragment>
            {isOnline ?
                <div className="wrapper position-relative" onClick={close}>

                    <div className="ec-page-wrapper">
                        {/* show storelist and profile */}
                        <Header globalHide={globalHide} />
                        <div className="ec-content-wrapper">
                            <div className="content">
                                {/* <PageTitle/> */}
                                {children}
                            </div>
                        </div>
                        {/* show the menu bar */}
                        <SideNav />
                        {/* show the page details end of the page */}
                        <Footer />
                    </div>

                </div>
                :
                <Offline />
            }
        </React.Fragment>
    );
}

export default Layout
