import React, { useState, useEffect } from "react";
import { callAPI, useUser } from "../../../Utils/utils";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { sales_and_fees_page_message } from "../../../Constants/Messages"
import { sales_and_fees_page_labels } from "../../../Constants/Labels";
import Pagination from "../../Global/Pagination/Pagination";
import BreadCrumb from '../../../Components/Global/BreadCrumb/BreadCrumb';
import Loader from "../../Global/Loader/Loader";
import moment from "moment";
import Message from "../../Message/Message";
import ErrorMessage from "../../Global/ErrorMessage"

const SalesAndFeesGlobal = () => {

    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const [errors, setErrors] = useState();
    const [payLoad, setPayLoad] = useState();
    const [loading, setLoading] = useState(false);
    const [formValues, setformValues] = useState({});
    const [totalSales, setTotalSales] = useState()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [searchValue, setSearchValue] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [resultInfo, setResultInfo] = useState({
        total_rows: "",
        fetched_rows: "",
        total_pages: "",
        page_size: 0,
        current_page: "",
        success: true,
        errors: ""
    })

    // console.log("resultInfo",resultInfo)
    useEffect(() => {
        if (payLoad) {
            callAPI("/admin/fees/all-stores",
                {
                    method: 'Post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    }, body: JSON.stringify(payLoad)

                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data.success == true) {

                        const SalesDetails = data?.records?.filter(store => store.store_name !== "Global");
                        setTotalSales(SalesDetails)
                        setResultInfo({
                            total_rows: data.total_rows,
                            fetched_rows: data.fetched_rows,
                            total_pages: data.total_pages,
                            page_size: data.page_size,
                            current_page: data.current_page,
                            success: data.success,
                            errors: data.errors
                        })
                    } else {
                        setErrors(data.errors)
                        // console.log('res.error', data.errors)
                    }
                }
                )
        }
    }, [payLoad])

    useEffect(() => {
        if (formValues?.time_window != "custom") {
            setPayLoad({
                "time_window": {
                    "window_type": formValues?.time_window || "this-week"
                },
                "current_page": currentPage
            })
        }
    }, [formValues])

    // store onchage value in state
    const onChangeField = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value })
        setShowErrorMessage(false)
        setErrors([])
    }

    const onSelectedDate = () => {

        setShowErrorMessage(true)
        const time_window = {
            "window_type": "custom",
            "from_date": formValues["from_date"] ? moment(formValues["from_date"]).format("YYYY-MM-DD") : "",
            "to_date": formValues["to_date"] ? moment(formValues["to_date"]).format("YYYY-MM-DD") : ""
        };

        setPayLoad({
            "time_window": time_window
        });
    }

    const onPageSelect = (currentPage) => {
        setCurrentPage(currentPage)
        onClickSearchBtn(currentPage)
    }

    const onChangeSearchValue = (event) => {
        // console.log("on search value - ", event.target.value)
        setSearchValue(event.target.value)
        var pay_load = {
            "time_window": payLoad?.time_window,
            "page_size": 10,
            "current_page": 1
        }

        if (event.target.value)
            pay_load.filters = {
                "conditions": [
                    { "field": "store_name", "operator": "like", "value": event.target.value, "dataType": "string" }
                ]
            }
        setPayLoad(pay_load)
    }

    //during on search icon clicked get the result of store
    const onClickSearchBtn = (currentPage) => {
        getSearchResult(currentPage)
    }

    //on enter search the store list
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getSearchResult()
        }
    }

    const getSearchResult = (currentPage) => {

        var pay_load = {
            "time_window": payLoad?.time_window,
            "page_size": 10,
            "current_page": currentPage
        }

        if (searchValue)
            pay_load.filters = {
                "conditions": [
                    { "field": "store_name", "operator": "like", "value": searchValue, "dataType": "string" }
                ]
            }
        setPayLoad(pay_load)

    }

    var order_count = totalSales?.total_fees?.order_count || 0
    var sales_count = totalSales?.total_fees?.total_sales || 0
    var fees_amount = totalSales?.total_fees?.total_fees || 0
    var net_amount = totalSales?.total_fees?.net_sales || 0

    const navigateToFeeEditPage = (id) => {
        navigate(`/admin/settings/fees/edit/${id}`)
    }

    return (
        <>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <div className="d-flex flex-column">
                    <BreadCrumb />
                </div>
                <div className="date_and_dropdown_wrapper">
                    {formValues?.time_window == "custom" &&
                        <div className=" custome_address_wrapper">
                            <div className="custome_address">
                                <div className="from_date">
                                    <label className="form-label mandatory_field">{sales_and_fees_page_labels?.sales_and_fees_page_custom?.from_date}</label>
                                    <input type="date" placeholder="from" className="form-control" name='from_date' max={formValues['to_date']} value={formValues['from_date'] || ''} onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'from_date', errors }) || ErrorMessage({ fieldName: 'from_date', errors })]} type={"errorMsg"} style={{ marginTop: "17px" }} showMessage={showErrorMessage} />
                                    </div>
                                </div>

                                <div className="to_date">
                                    <label className="form-label mandatory_field">{sales_and_fees_page_labels?.sales_and_fees_page_custom?.to_date}</label>
                                    <input type="date" className="form-control" name='to_date' placeholder="To date" min={formValues['from_date']} value={formValues['to_date'] || ''} onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'to_date', errors })] || ErrorMessage({ fieldName: 'to_date', errors })} type={"errorMsg"} style={{ marginTop: "17px" }} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                                <div className="apply_btn">
                                    <button className="btn btn-primary" onClick={onSelectedDate}>{sales_and_fees_page_labels?.sales_and_fees_page_custom?.btn_label}</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="drop_down">
                        <select className="form-control" name='time_window' onChange={onChangeField} value={formValues['time_window'] || "this-week"} >
                            {sales_and_fees_page_labels?.sales_and_fees_options?.map((option, index) => (
                                <option key={index} value={option?.value}>{option?.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="col-12 d-flex flex-wrap salesOverviewCard">
                <div className="col-lg-2 card card-body">
                    <span>{sales_and_fees_page_labels?.sales_and_fees_page_container_labels?.orders} : {order_count}</span>
                </div>
                <div className="col-lg-2 card card-body">
                    <span>{sales_and_fees_page_labels?.sales_and_fees_page_container_labels?.net_sales} : {sales_count}</span>
                </div>
                <div className="col-lg-2 card card-body">
                    <span> {sales_and_fees_page_labels?.sales_and_fees_page_container_labels?.fees} : {fees_amount}</span>
                </div>
                <div className="col-lg-2 card card-body">
                    <span> {sales_and_fees_page_labels?.sales_and_fees_page_container_labels?.net} : {net_amount}</span>
                </div>
            </div>

            <div className="cardWrapper card-default mt-3">
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <div className="sales_and_fee_filterTab">
                                <div className="sales_and_fee_search_form">
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            placeholder={`${sales_and_fees_page_labels?.global_sales_and_fees_page_search_placeholder_label}`} onChange={onChangeSearchValue} onKeyDown={handleKeyDown} />
                                        <span className="search_icon">
                                            <i className="mdi mdi-magnify"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {loading &&
                                <Loader className={"table_loader"} />
                            }

                            {!loading &&
                                <>
                                    {totalSales?.records?.length == 0 ?
                                        <div className="card">
                                            <div className="card-body defaultText">
                                                <p className="text-center">{sales_and_fees_page_message?.stores_not_found}</p>
                                            </div>
                                        </div> :
                                        <>
                                            <div className="table-responsive tableOutline" >
                                                <table className="table" >
                                                    <thead>
                                                        <tr>
                                                            <th className="col-lg-3">{sales_and_fees_page_labels?.global_sales_and_fees_page_table_head?.store}</th>
                                                            <th className="col-lg-2">{sales_and_fees_page_labels?.global_sales_and_fees_page_table_head?.orders}</th>
                                                            <th className="col-lg-2">{sales_and_fees_page_labels?.global_sales_and_fees_page_table_head?.net_sales}</th>
                                                            <th className="col-lg-2">{sales_and_fees_page_labels?.global_sales_and_fees_page_table_head?.fees}</th>
                                                            <th className="col-lg-2">{sales_and_fees_page_labels?.global_sales_and_fees_page_table_head?.net}</th>
                                                            <th className="col-lg-2">{sales_and_fees_page_labels?.global_sales_and_fees_page_table_head?.action}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {totalSales && totalSales?.map((fees, index) => {
                                                            return (
                                                                <tr key={index} >
                                                                    <td role="button" onClick={() => navigateToFeeEditPage(fees?.store_id)}>{fees?.store_name == null ? "test" : fees?.store_name}</td>
                                                                    <td>{fees?.orders}</td>
                                                                    <td>{fees?.sales}</td>
                                                                    <td> {fees?.fees}</td>
                                                                    <td> {fees?.net}</td>
                                                                    <td>
                                                                        <span className="mdi mdi-account-edit icon" onClick={() => navigateToFeeEditPage(fees?.store_id)}></span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="paginationWrapper">
                                                <Pagination totalRows={resultInfo?.total_rows - 1} fetchedRows={resultInfo?.fetched_rows - 1} totalPages={resultInfo?.total_pages} pageSize={resultInfo?.page_size} currentPage={resultInfo?.current_page} onPageSelect={onPageSelect} label='stores' loader={loading} />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SalesAndFeesGlobal;