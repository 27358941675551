import React, { useEffect, useState, useRef } from "react";
import { callAPI } from "../../../../src/Utils/utils";
import { useNavigate } from "react-router-dom";
import { password_generation_labels } from "../../../Constants/Labels"
import { password_success} from "../../../Constants/Messages";
import Message from "../../Message/Message";
import ErrorMessage from "../../Global/ErrorMessage";
import { errorMessages } from "../../../Constants/Errors";
// import "../../Global/Login/Login.css"
// import "../../Global/Password Generation/PasswordGeneration.css"
const ResetPassword = ({ mailId }) => {
    const [one_time_password, setOneTimePassword] = useState(new Array(6).fill(''));
    const inputRefs = useRef([]);
    const [new_password_show, setNewPasswordShow] = useState(false)
    const [confirm_password_show, setConfirmPasswordShow] = useState(false)
    const [formValues, setformValues] = useState()
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [loading,setLoading]=useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const hide_eye_icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
    </svg>

    const eye_icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
    </svg>

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, 6);
        // Focus on the first input when component mounts
        if (inputRefs.current.length > 0) {
            inputRefs.current[0].focus();
        }
    }, [])

    const handleChange = (index, event) => {
        const value = event.target.value;
        if (!isNaN(value) || value == "") {
            const otpCopy = [...one_time_password];
            otpCopy[index] = value[value.length - 1] ?? "";
            setOneTimePassword(otpCopy);
            if (value !== '' && inputRefs.current.length - 1 > index) {
                inputRefs.current[index + 1].focus();
            } else if (value === "" && index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pasteData = event.clipboardData.getData('text/plain').trim();
        const pasteValues = pasteData.split('').slice(0, 6);

        // Update OTP state based on paste content
        const otpCopy = [...one_time_password];
        for (let i = 0; i < 6; i++) {
            otpCopy[i] = pasteValues[i] || '';
        }
        setOneTimePassword(otpCopy);

        // Move focus to next input field if possible
        if (pasteValues.length > 0) {
            inputRefs.current[pasteValues.length - 1].focus();
        }
    };


    const changeValues = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value });
    }

    function savePassword(){
        var secret_code=""
        if(one_time_password){
            one_time_password.forEach((password)=>{
            secret_code+=password
            })
        }
        var payload = {
            email: mailId,
            secret_code: secret_code,
            password: formValues.password,
            confirm_password: formValues.confirm_password
        }
        callAPI('/admin/users/save-password',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            }, navigate,setLoading)

            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    localStorage.setItem('Message', password_success)
                    navigate('/')
                }
                else {
                    setErrors(data?.errors)
                    setShowErrorMessage(true)
                    // console.log('data', data?.errors)
                }
            }
            )

    }

    function ResendOTP(){

        callAPI("/admin/users/forgot_password",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "email": mailId,
            })
        }, navigate, setLoading)
    }

    return (
        <>
            <h6>A one time password has been sent to {mailId} </h6>
            <div className="mt-3">
                <lable className="form-label mandatory_field"> One Time Password</lable>
                <div className="d-flex">
                    {one_time_password.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            value={digit}
                            onChange={(e) => handleChange(index, e)}
                            onPaste={handlePaste}
                            ref={(el) => (inputRefs.current[index] = el)}
                            className="otp-input form-control"
                        />
                    ))}

                </div>
            </div>
            {errors?.find((er)=>er.field_name=="secret_code") && <div className="">
                <Message text={errorMessages[ErrorMessage({ fieldName: 'secret_code', errors })] || ErrorMessage({ fieldName: 'secret_code', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
            </div>}
            <lable className="form-label mandatory_field mb-1"> {password_generation_labels?.new_password}</lable>
            <div className="input-password " style={{ position: "relative" }}>
                <input className="form-control" type={new_password_show ? "text" : "password"} value={formValues?.password} name="password" onChange={changeValues} />
                <span className="password-toggle" onClick={() => { setNewPasswordShow(!new_password_show) }}>
                    {new_password_show ? <span className="eye_icon">{eye_icon}</span> : <span className="hide_eye_icon">{hide_eye_icon}</span>}
                </span>
            </div>
            {errors?.find((er)=>er.field_name=="password") && <div className="errorMessage">
                <Message text={errorMessages[ErrorMessage({ fieldName: 'password', errors })] || ErrorMessage({ fieldName: 'password', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
            </div>}
            <lable className="form-label mandatory_field mb-1">{password_generation_labels?.confirm_password}</lable>
            <div className="input-password">
                <input className="form-control" type={confirm_password_show ? "text" : "password"} value={formValues?.confirm_password} name="confirm_password" onChange={changeValues} />
                <span className="password-toggle" onClick={() => { setConfirmPasswordShow(!confirm_password_show) }}>
                    {confirm_password_show ? <span className="eye_icon">{eye_icon}</span> : <span className="hide_eye_icon">{hide_eye_icon}</span>}
                </span>
            </div>
            {errors?.find((er)=>er.field_name=="confirm_password") && <div className="errorMessage">
                <Message text={errorMessages[ErrorMessage({ fieldName: 'confirm_password', errors })] || ErrorMessage({ fieldName: 'confirm_pasword', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
            </div>}
            
            <div className="otp-buttons">
                <button onClick={savePassword} disabled={(one_time_password.includes("")) ? true : false} className="btn btn-primary ml-2" >Submit</button>
                <button onClick={ResendOTP} className="btn-link">Resend One Time Password?</button>
            </div>
        </>
    )
}
export default ResetPassword;