import React from "react";
import Layout from "../../../Components/Global/Layout";
import BreadCrumb from '../../../Components/Global/BreadCrumb/BreadCrumb';
import StoreProfile from '../../../Components/Account/StoreProfile/StoreProfile/StoreProfile';
import { useStore } from "../../../Utils/utils"
import { select_store_message } from "../../../Constants/Messages"

const StoreProfilePage = () => {
    const { store, setStore } = useStore()
    var storeId = store?.id
    let defaultText = storeId == null || storeId == undefined || storeId == '0' || store?.name == "Global Admin" ? 'defaultText' : ''

    return (
        <Layout>

            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <BreadCrumb />
            </div>
            {/* create order using add order */}
            {defaultText ?
                <div className="card">
                    <div className={`card-body ${defaultText}`}>
                        <p className="text-center">{select_store_message?.select_store}</p>
                    </div>
                </div>
                :
                <StoreProfile />
            }
        </Layout>
    )
}
export default StoreProfilePage;

