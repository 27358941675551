import React, { useEffect } from "react";
import './SecondaryLoader.css';

const SecondaryLoader = ({ className, setLoading, isLoading }) => {
    useEffect(() => {
        // Execute code when the 'text' prop changes
        if (isLoading) {
            setTimeout(() => {
                // Clear the alert after 3 seconds
                setLoading({ isLoading: false })
            }, 1000);
        }
    }, [isLoading]);
    return (
        
        <div>
            <img className={`${className ? className : "secondaryLoader"}`} src="/assets/img/secondaryLoader.gif" alt="loader" />
        </div>
    )
}

export default SecondaryLoader;