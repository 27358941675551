export const available_send_dates = [
    // "30-Oct-2024",
    "06-Nov-2024",
    "27-Nov-2024",
    "04-Dec-2024",
    "11-Dec-2024",
    "16-Dec-2024",
    // "18-Dec-2024",
    "23-Dec-2024",
    "09-Jan-2025",
]