import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fees_page_labels } from "../../../Constants/Labels";
import { callAPI, useStore, useUser } from "../../../Utils/utils";
import { errorMessages } from "../../../Constants/Errors";
import { useParams } from "react-router-dom";
import { fees_page_message, select_store_message, alert_message_type } from "../../../Constants/Messages"
import Layout from "../../../Components/Global/Layout";
import BreadCrumb from '../../../Components/Global/BreadCrumb/BreadCrumb';
import Message from "../../../Components/Message/Message";
import Alert from "../../../Components/Alert/Alert";
import Loader from "../../../Components/Global/Loader/Loader";
import ErrorMessage from "../../../Components/Global/ErrorMessage";
import "./FeesPage.css";

const FeesPage = () => {

    const navigate = useNavigate()
    const [alert, setAlert] = useState()
    const { user, setUser } = useUser();
    const [errors, setErrors] = useState();
    const { store, setStore } = useStore();
    const [loading, setLoading] = useState(false)
    const [storeName, setStoreName] = useState([]);
    const [feesHistory, setFeesHistory] = useState()
    const [formValues, setformValues] = useState();
    const [refreshCount, setRefreshCount] = useState(0)
    const [showErrorMessage, setShowErrorMessage] = useState()
    var { id } = useParams();
    var storeId = store?.id
    

    useEffect(() => {
        if (store?.name != "Global Admin") {
            if (storeName?.length == 0 || storeName == undefined) {
                setStoreName(() => [store?.name])
            }
            else {
                setStoreName((existingStoreId) => [...existingStoreId, store?.name])
            }
        }
    }, [store?.id])

    useEffect(() => {
        const store_name = storeName?.filter((value, index, self) => self.indexOf(value) === index);
        if (store_name[1] != undefined) {
            if (store_name[0] != store_name[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    // store onchanged values in state
    const onChangeField = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value })
        setShowErrorMessage(false)
        setErrors([])
    }

    useEffect(() => {

        if (storeId || id) {
            var payload = { "store_id": storeId > 0 ? storeId : id}

            callAPI("/admin/settings/fees-history/by-store",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(payload)
                }, navigate, setLoading)

                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data.success) {
                        setFeesHistory(data?.records)
                    } else {
                        // console.log('Error occurred. Please try again - ', data.errors)
                    }
                })
        }
    }, [store?.id, refreshCount,id])

    const saveFee = () => {
        const pay_load = {
            "store_id": storeId > 0 ? storeId : id,
            "type": formValues?.['type'] ? formValues['type'] : "",
            "rate": formValues?.['rate'] ? formValues['rate'] : "",
            "valid_from": formValues?.['valid_from'] ? formValues['valid_from'] : ""
        }
        // console.log("pay_load",pay_load)
        // callapi is do fetch API opertaion
        callAPI("/admin/settings/fees/update",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    setAlert({ text: fees_page_message?.saved_message, type: alert_message_type?.success })
                    setRefreshCount(refreshCount + 1)
                    setformValues("")
                } else {
                    setErrors(data?.errors)
                    setShowErrorMessage(true)
                    // console.log('res', data?.errors)
                }
            }
            )
    }

    let select_store = store?.id == null || store?.id == undefined || store?.id == 0 || store?.name == "Global Admin" ? true : false
    var today = new Date().toISOString().split('T')[0];


    const fees = Object.keys(fees_page_labels?.fees_drop_down)?.map((name) => {
        const value = fees_page_labels?.fees_drop_down[name];
        return `${name},${value}`;
    });

    return (
        <Layout>
            {alert?.text == fees_page_message?.saved_message ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}
            {loading &&
                <Loader className={"page_loader"} />
            }
            {!loading &&
                <>
                    <div className="breadcrumb-wrapper breadcrumb-contacts">
                        <BreadCrumb />
                    </div>
                    <>
                        {select_store && !id ?
                            <div className="card card-default">
                                <div className={`card-body select_store`}>
                                    <p className="text-center">{select_store_message?.select_store}</p>
                                </div>
                            </div>
                            :
                            <>
                                <div className="card card-default">
                                    <div className="card-body over_all_fees_wrapper">
                                        <div className="fees_wrapper">
                                            <label className="form-label mandatory_field">{fees_page_labels?.fee_type}</label>
                                            <select className="form-control" name='type' value={formValues?.["type"] || ""} onChange={onChangeField}>
                                                <option hidden>Select</option>
                                                {fees &&
                                                    fees?.map((optionString, index) => {
                                                        const [name, value] = optionString.split(',');
                                                        return <option value={name} key={index}>{value}</option>;
                                                    })
                                                }
                                            </select>
                                            <div className="errorMessage mt-3">
                                                <Message text={errorMessages[ErrorMessage({ fieldName: 'type', errors })] || ErrorMessage({ fieldName: 'type', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                            </div>
                                        </div>
                                        <div className="fees_wrapper">
                                            <label className="form-label mandatory_field">{fees_page_labels?.fee_rate}</label>
                                            <input type="text" className="form-control" name="rate" value={formValues?.["rate"] || ""} onChange={onChangeField} />
                                            <div className="errorMessage">
                                                <Message text={errorMessages[ErrorMessage({ fieldName: 'rate', errors })] || ErrorMessage({ fieldName: 'rate', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                            </div>
                                        </div>
                                        <div className="fees_wrapper">
                                            <label className="form-label mandatory_field">{fees_page_labels?.valid_from}</label>
                                            <input type="date" className="form-control" name="valid_from" id="dateInput" min={today} value={formValues?.["valid_from"] || ""} onChange={onChangeField} role="button" />
                                            <div className="errorMessage">
                                                <Message text={errorMessages[ErrorMessage({ fieldName: 'valid_from', errors })] || ErrorMessage({ fieldName: 'valid_from', errors }) }  type={"errorMsg"} showMessage={showErrorMessage} />
                                            </div>
                                        </div>
                                        <div className="fees_btn_wrapper">
                                            <button className="btn btn-primary" onClick={saveFee}>{fees_page_labels?.fees_page_button_label}</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-default mt-5">
                                    <div className="card-header card-header-border-bottom">
                                        <h2>{fees_page_labels?.fee_page_table?.title}</h2>
                                    </div>
                                    {feesHistory?.length == 0 ?

                                        <div className="card-body">
                                            <p className="text-center">{fees_page_message?.no_fees}</p>
                                        </div>

                                        :
                                        <div className="card-body over_all_fees_wrapper">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="col-lg-3">{fees_page_labels?.fee_page_table?.fee_type}</th>
                                                        <th className="col-lg-3">{fees_page_labels?.fee_page_table?.fee_rate}</th>
                                                        <th className="col-lg-3">{fees_page_labels?.fee_page_table?.status}</th>
                                                        <th className="col-lg-3">{fees_page_labels?.fee_page_table?.valid_from}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {feesHistory?.map((fee, index) => {
                                                        return (
                                                            < tr key={index} >
                                                                <td>{fee?.type == "FEE-TYP-PERCENTAGE" ? "%" : "FIXED"}</td>
                                                                <td>{fee?.rate}</td>
                                                                <td className={`status_${fee?.is_active?.toLowerCase()}`}>{fee?.is_active}</td>
                                                                <td>{fee?.valid_from}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </>
                </>
            }
        </Layout>
    )
}
export default FeesPage;