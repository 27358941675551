import React, { useState } from "react";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import EditMyProfile from "../../Components/Account/MyProfile/EditMyProfile";
import { useUser } from "../../Utils/utils";
import { item_required_error_message } from "../../Constants/Messages";
import Alert from "../../Components/Alert/Alert";

const EditMyProfilePage = () => {
    const { user, setUser } = useUser()
    const [alert, setAlert] = useState()

    // on update user data increase the refreshCount 
    const onUpdateUser = (Message) => {
        if (Message == "success") {
            setAlert({ text: "Profile saved successfully", type: "success" })
        }
        else if (Message == "error") {
            setAlert({ text: item_required_error_message, type: "error" })
        }
    }

    return (
        <Layout>
             <div className="breadcrumb-wrapper breadcrumb-contacts">
             <BreadCrumb value={user?.first_name+" "+user?.last_name}/>
            </div>
             {/*Edit User Details By Id*/}
             {alert?.text == 'Profile saved successfully' ||  alert?.text == item_required_error_message  ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}
            <EditMyProfile onUpdateUser={onUpdateUser}/>
        </Layout>
    )
}
export default EditMyProfilePage;

