import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.css';

export default function Pagination({ totalRows, fetchedRows, totalPages, pageSize, currentPage, onPageSelect, label, loader }) {
  // Here we use item offsets; we could also use page offsets for showing page range
  const itemOffset = 1, pageOffset = (currentPage - 1) * pageSize;
  const nextItemOffset = pageOffset + itemOffset
  const endOffset = currentPage * pageSize;
  const lastEndOffset = pageOffset + fetchedRows
  const startEntryCount = (currentPage == itemOffset ? itemOffset : nextItemOffset);
  const endEntryCount = (fetchedRows == pageSize ? endOffset : lastEndOffset);


  if (currentPage == 1) {
    var forcePage = 0
  }
  else {
    var forcePage = currentPage - 1
  }
  // Invoke when user click to request another page.
  const onPageClick = (event) => {
    const newOffset = event.selected + 1;
    onPageSelect(newOffset);
  };
  if (fetchedRows) {
    return (
      <React.Fragment>
        <div className="mt-3" style={{ display: loader ? 'none' : 'block' }}>
          <div className="row justify-content-between bottom-information m-auto">
            <div className="dataTables_info" id="responsive-data-table_info" role="status" aria-live="polite">Showing {startEntryCount} to {endEntryCount} of {totalRows} {label}</div>
            <div className="dataTables_paginate paging_simple_numbers" id="responsive-data-table_paginate">
              <ReactPaginate className='pagination'
                breakLabel="..."
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item responsive-data-table_previous"
                previousLinkClassName="page-link"
                nextClassName="page-item responsive-data-table_next"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
                onPageChange={onPageClick}
                // pageRangeDisplayed={15}
                forcePage={forcePage}
                pageCount={totalPages}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}