import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { user_page_labels } from "../../../Constants/Labels"
import { callAPI, useStore, usePage, useUser } from "../../../Utils/utils";
import { item_required_error_message, user_page_message } from "../../../Constants/Messages";
import Message from "../../Message/Message";
import ErrorMessage from "../../Global/ErrorMessage";
import Alert from "../../Alert/Alert";
import "./AddUser.css";

export default function AddUser() {

    const { store, setStore } = useStore();
    const { user, setUser } = useUser();
    const { page, setPage } = usePage();
    const [alert, setAlert] = useState()
    const [formValues, setformValues] = useState();
    const [loading, setLoading] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errors, setErrors] = useState();
    const [storeName, setStoreName] = useState([]);
    const navigate = useNavigate();
    var store_id = store?.name === "Global Admin" || store?.id === 0 || !store?.id ? 0 : store?.id;

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])


    // check selected role on page load
    useEffect(() => {
        // Check role is global admin
        if (user?.first_name == 'Global' && user?.last_name == 'Admin') {
            // role is global admin setfromvalues GL-ADM
            if (store?.id == null || store?.id == 0) {
                setformValues({ ...formValues, "role_cd": "GL-ADM" })
                // role is global admin setfromvalues ST-ADM
            } else {
                setformValues({ ...formValues, "role_cd": "ST-ADM" })
            }
        }  // the role is store admin setfromvalues ST-ADM
        else {
            setformValues({ ...formValues, "role_cd": "ST-ADM" })
        }
    }, [])

    //onClick save user validate required field and send data database through -- /admin/users/create -- API
    const createUser = () => {
        // store inputs fields in pay_load
        const pay_load = {
            "store_id": store_id,
            "role_cd": formValues['role_cd'] || "",
            "first_name": formValues['first_name']?.trim()  || "",
            "last_name": formValues['last_name']?.trim()  || "",
            "email": formValues['email']?.toLowerCase()?.trim()  || "",
            "phone": formValues['phone'] || "",
            "status": formValues?.status || 'inactive',
        }

        // console.log("pay_load", pay_load)
        callAPI(`/admin/users/create`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    // data status in true data saved successfull in database than page moved to user edit page
                    navigate(`/admin/user/edit/${data?.user_id}`)
                    // responce is success set "user Saved Successfully " message in localStorage
                    localStorage.setItem('Message', user_page_message?.create_message)
                } else {
                    setErrors(data?.errors)
                    setShowErrorMessage(true)
                    setAlert({ text: item_required_error_message, type: "error" })
                }
            }
            )
    }


    //store on change form values in fromValues state
    const onChangeField = (event) => {
        const updatedName = event.target.name?.trim()
        const UpdateValue = event.target.value?.trim()
        setformValues({ ...formValues, [updatedName]: UpdateValue, })
        setShowErrorMessage(false)
        setErrors([])
    }

    return (

        <React.Fragment>

            {alert?.text ? <Alert text={alert.text} type={alert.type} setAlert={setAlert} /> : ''}

            <button className="btn btn-primary saveButton" onClick={createUser}>{user_page_labels?.user_add_page_labels?.btn_label}</button>

            <div className="row">
                <div className="col-lg-8">
                    <div className="card card-default">
                        <div className="card-header card-header-border-bottom">
                            <h2>{user_page_labels?.user_add_page_labels?.title}</h2>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group ">
                                        <label className="form-label mandatory_field">{user_page_labels?.user_add_and_edit_page_labels?.first_name}</label>
                                        <input type="text" className="form-control" name="first_name" value={formValues?.['first_name'] || ""} maxLength="30" onChange={onChangeField} />
                                        <div className="errorMessage">
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'first_name', errors })] || ErrorMessage({ fieldName: 'first_name', errors })} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="form-label mandatory_field">{user_page_labels?.user_add_and_edit_page_labels?.last_name}</label>
                                        <input type="text" className="form-control" name="last_name" value={formValues?.['last_name'] || ""} maxLength="30" onChange={onChangeField} />
                                        <div className="errorMessage">
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'last_name', errors })] || ErrorMessage({ fieldName: 'last_name', errors })} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="form-label mandatory_field">{user_page_labels?.user_add_and_edit_page_labels?.email}</label>
                                        <input type="email" className="form-control email_input" name="email" value={formValues?.['email'] || ""} maxLength="50" onChange={onChangeField} />
                                        <div className="errorMessage">
                                            <Message type="errorMsg" text={errorMessages[ErrorMessage({ fieldName: 'email', errors })] || ErrorMessage({ fieldName: 'email', errors })} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="form-label">{user_page_labels?.user_add_and_edit_page_labels?.role}</label>
                                        <input  className="form-control" disabled name="role_cd" value={store_id == 0 ? user_page_labels?.user_add_and_edit_page_labels?.global_admin : user_page_labels?.user_add_and_edit_page_labels?.store_admin} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card card-default">
                        <div className="card-body">
                            <div className="form-group">
                                <label className="form-label">{user_page_labels?.user_add_and_edit_page_labels?.status}</label>
                                <input type="text" className="form-control" disabled value={user_page_labels?.user_add_and_edit_page_status?.inactive} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
} 