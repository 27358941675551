import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI, useUser, useStore, usePage } from "../../Utils/utils";
import { user_page_labels } from "../../Constants/Labels";
import Layout from "../../Components/Global/Layout";
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb';
import UsersList from '../../Components/Users/UsersList';
import Button from "../../Components/Global/Button";
import Pagination from "../../Components/Global/Pagination/Pagination";
import Loader from "../../Components/Global/Loader/Loader";
import Alert from "../../Components/Alert/Alert";
import { user_page_message } from "../../Constants/Messages";

const UsersPage = () => {

    const navigate = useNavigate()
    const { user, setUser } = useUser()
    const { store, setStore } = useStore()
    const { page, setPage } = usePage()
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState('all')
    const [searchValue, setSearchValue] = useState()
    const [searchResult, setSearchResult] = useState()
    const [globalHide, setGlobalHide] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [payLoad, setPayLoad] = useState()
    const [alert, setAlert] = useState()
    const [resultInfo, setResultInfo] = useState({
        total_rows: "",
        fetched_rows: "",
        total_pages: "",
        page_size: 0,
        current_page: "",
        success: true,
        errors: ""
    })

    var storeId = store?.id

    const onPageSelect = (currentPage) => {
        setCurrentPage(currentPage)
        onClickSearchBtn(currentPage)
    }

    useEffect(() => {

        var usersUrl = "/admin/users/getGlobalUsers"

        var initialPayload = {
            "status": mode,
            "page_size": 10,
            "current_page": currentPage
        }

        //api changes based on presence or absence of storeId
        if (storeId && storeId != 0) {
            usersUrl = "/admin/users/getStoreUsers"
            initialPayload.store_id = storeId
        }

        var pay_load = payLoad || initialPayload

        if(pay_load.status != mode){
            pay_load.status = mode
            pay_load.current_page = 1
        }
        
        // console.log("pay_load",pay_load)
        callAPI(usersUrl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading, setUser)

            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success) {
                    // console.log("store users - ", data.records)
                    setSearchResult(data?.records)
                    setResultInfo({
                        total_rows: data.total_rows,
                        fetched_rows: data.fetched_rows,
                        total_pages: data.total_pages,
                        page_size: data.page_size,
                        current_page: data.current_page,
                        success: data.success,
                        errors: data.errors
                    })
                } else {
                    // console.log('Error occurred. Please try again - ', data.errors)
                }
            })

    }, [mode, globalHide, storeId, payLoad])

    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    // page on load get localStorage message and store in alert useState
    useEffect(() => {
        var SavedMessage = localStorage?.getItem('Message')
        if (SavedMessage != null) {
            setAlert({ text: SavedMessage, type: "success" })
        }
    }, [])


    // onchange values store on the state
    const onChangeSearchValue = (event) => {
        setSearchValue(event.target.value)

        if (event.target.value) {
            var pay_load = {
                "status": mode,
                "filters": {
                    "conditions": [
                        { "field": "first_name", "operator": "like", "value": event.target.value, "dataType": "string" },
                        { "field": "last_name", "operator": "like", "value": event.target.value, "dataType": "string" },
                        { "field": "email", "operator": "like", "value": event.target.value, "dataType": "string" }
                    ],
                    "logic": "(1-or-2)-or-3"
                },
                "page_size": 10,
                "current_page": 1
            }
        }

        else {
            pay_load = {
                "status": mode,
                "page_size": 10,
                "current_page": 1
            }
        }

        //api changes based on presence or absence of storeId
        if (storeId && storeId != 0) {
            if (event.target.value) {
                pay_load = {
                    "status": mode,
                    "store_id": store?.id,
                    "filters": {
                        "conditions": [
                            { "field": "first_name", "operator": "like", "value": event.target.value, "dataType": "string" },
                            { "field": "last_name", "operator": "like", "value": event.target.value, "dataType": "string" },
                            { "field": "email", "operator": "like", "value": event.target.value, "dataType": "string" }
                        ],
                        "logic": "(1-or-2)-or-3"
                    },
                    "page_size": 10,
                    "current_page": 1
                }
            }
            else {
                pay_load = {
                    "status": mode,
                    "store_id": store?.id,
                    "page_size": 10,
                    "current_page": 1
                }
            }
        }

        setPayLoad(pay_load)
    }

    //during on search icon clicked get the result of store
    const onClickSearchBtn = (currentPage) => {
        // console.log("currentPage",currentPage)
        getUsers(currentPage)
    }

    //on enter search the store list
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getUsers()
        }
    }

    // onclick to pass the serach values to the database
    const getUsers = (currentPage) => {
        if (searchValue) {
            var pay_load = {
                "status": mode,
                "filters": {
                    "conditions": [
                        { "field": "first_name", "operator": "like", "value": searchValue, "dataType": "string" },
                        { "field": "last_name", "operator": "like", "value": searchValue, "dataType": "string" },
                        { "field": "email", "operator": "like", "value": searchValue, "dataType": "string" }
                    ],
                    "logic": "(1-or-2)-or-3"
                },
                "page_size": 10,
                "current_page": currentPage
            }
        }
        else {
            var pay_load = {
                "status": mode,
                "page_size": 10,
                "current_page": currentPage
            }
        }
        setPayLoad(pay_load)
    }

    // onclick active and inactive 
    const close = () => {
        setGlobalHide(false)
        if (globalHide === false) {
            setGlobalHide(true)
        }
    }

    return (
        <Layout>
            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <BreadCrumb />
                <Button className={'btn btn-primary'} onClick={() => navigate('/admin/user/new')} value={'Add User'} />
            </div>

            {alert?.text == user_page_message?.deleted_message  ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert}  />
                </div>
                : ''}

            <div className="cardWrapper card-default">
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper ">
                            <div className="filterTab mb-3">
                                <div className="navAndSearchWrapper">
                                    <ul className="nav nav-tabs nav-style-border w-75"  >
                                        <li className="nav-item" onClick={() => setMode('all')}>
                                            <span className={`nav-link ${mode == 'all' ? 'active' : ''}`}>{user_page_labels?.user_page_tab_labels?.all}</span>
                                        </li>
                                        <li className="nav-item" onClick={() => setMode('active')}>
                                            <span className={`nav-link ${mode == 'active' ? 'active' : ''}`}>{user_page_labels?.user_page_tab_labels?.active}</span>
                                        </li>
                                        <li className="nav-item" onClick={() => setMode('inactive')}>
                                            <span className={`nav-link ${mode == 'inactive' ? 'active' : ''}`}>{user_page_labels?.user_page_tab_labels?.inactive}</span>
                                        </li>
                                    </ul>

                                    <div className="search-form">
                                        <div className="input-group">
                                            <input type="text" className="form-control"
                                                placeholder={user_page_labels?.user_page_search_placeholder_label} onChange={onChangeSearchValue} onKeyDown={handleKeyDown} />
                                            <span className="search_icon">
                                                <i className="mdi mdi-magnify"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {loading &&
                                <Loader className={"table_loader"} />
                            }

                            {/* here we add storeTable component */}
                            <UsersList mode={mode} searchResult={searchResult} onClick={close} loader={loading} />
                            <div className="paginationWrapper">
                                <Pagination totalRows={resultInfo.total_rows} fetchedRows={resultInfo.fetched_rows} totalPages={resultInfo.total_pages} pageSize={resultInfo.page_size} currentPage={resultInfo.current_page} onPageSelect={onPageSelect} label={user_page_labels?.user_page_pagination_label} loader={loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default UsersPage;
